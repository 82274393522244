@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.other-quotes-container {
  .other-quotes-button {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0 0 14px #e6eaf2 !important;
    text-transform: capitalize;
    min-width: 163px !important;
    min-height: 50px;
    @include themify($themes) {
      color: themed('selectTextcolor') !important;
    }
    border: 1px solid #fff;

    &:hover {
      border: 1px solid !important;
      @include themify($themes) {
        border-color: themed('buttonHoverBorder') !important;
      }
      background-color: #f4fffa;
    }

    button {
      width: 100%;
      min-width: 163px;
      min-height: 50px;
      text-transform: none;
      margin-right: 0px !important;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }

    .plus-icon {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .other-quote-item {
    color: $hippo-text-color !important;

    &:hover {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
  }
}
