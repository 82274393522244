@import "../stylesheets/theme/theme";
@import "../stylesheets/theme/theme-variables";
.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}
.form-wrapper {
  width: 95%;
  max-width: 850px;
  margin: 0 auto;
}
.DatePickerComponent,
.number-stepper-control {
  position: relative;
  & .Mui-required {
    .MuiInputLabel-asterisk {
      font-size: 20px;
      vertical-align: sub;
      color: red;
      letter-spacing: -2px;
    }
  }
}
.number-stepper-control {
  label {
    font-weight: bold;
  }
}
.m-16 {
  margin: 16px 0px;
}
.classmargin {
  margin: 0 8px !important;
}
.star-error {
  font-size: 20px;
  vertical-align: sub;
  color: red;
}
.small-star-error {
  @extend .star-error;
  vertical-align: baseline;
  font-size: 16px;
}
.home-form-title {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 55px;

  .title-2 {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 100%;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
    font-size: 24px;
  }
}
.applicant-home-form {
  margin-top: 25px;
  padding-bottom: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $hippo-text-color;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::-moz-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::placeholder {
    color: peachpuff;
    font-size: 16px;
  }

  input,
  #select-state {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    padding-left: 3px;

    &:focus {
      background: none;
    }
  }
  .margin-top-50 {
    margin-top: 50px;
  }
  .MuiFormLabel-root {
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Manrope", sans-serif;
    text-transform: capitalize;
    opacity: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  .first_name,
  .middle_name,
  .last_name,
  .date_of_birth,
  .preferred_deductible,
  .street,
  .unit,
  .city,
  .state,
  .zip,
  .phone_number,
  .email,
  .loan_officer_fname,
  .loan_officer_lname,
  .loan_officer_phone,
  .loan_officer_email,
  .loan_number,
  .loan_title {
    label {
      font-family: "Manrope", sans-serif;
      font-weight: bold;
    }
    ::-ms-clear {
      display: none;
    }
  }

  .error {
    color: $errorRed;
    font-size: 12px;
    text-align: left;
    margin-left: 10px;
  }

  .homeTypeError {
    padding-left: 15px;
  }

  .personal-details-wrapper {
    margin: 0px;
    margin-right: 0px;

    .div-display {
      display: inline-block;
    }
    .email {
      div {
        div {
          margin-left: 0px !important;
        }
      }
    }
  }
  .loan-officer-container {
    margin-right: 0px;
    .row {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .loan_title {
      text-align: left;
      padding-left: 23px;
    }
    .div-display {
      text-align: left;
    }
  }
  .address-container {
    margin: 0px;

    .row {
      .state {
        .error {
          margin-left: 10px;
        }
      }
      .zip {
        input {
          height: 1.2em;
        }
      }
    }
  }

  .insurance-type {
    width: 100%;
    margin-left: -5px;
    margin-right: 0px;
    padding-right: 0px;
    .lbl-insu {
      text-align: center;
      font-family: "Manrope", sans-serif;
      font-weight: bold;
      color: #3a4b5e;
      padding: 0;
      font-size: 13px;
      line-height: 1;
      margin-bottom: 17px;
    }

    .lbl-error {
      color: $errorRed;
    }
    .insurence-type-lbl {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
    }
    .insurance-home {
     @extend .insurence-type-lbl;
      vertical-align: top;
      .p-head {
        text-align: left;
        color: $property-type-home-title;
        margin-bottom: 0px;
        font-weight: normal;
        font-size: 1.1rem;
      }
      .p-para {
        color: $gray-700;
        text-align: left;
        font-size: 11px;
      }
    }

    .insurance-condo {
      @extend .insurence-type-lbl;
      .p-head {
        color: $property-type-condo-title;
        font-size: 1.1rem;
      }
    }
    .insurance-ho5 {
      @extend .insurence-type-lbl;
      .p-head {
        color: #8b54df;
        font-size: 1.1rem;
      }
    }
    .insurance-ho4 {
      @extend .insurence-type-lbl;
      .p-head {
        color: #f4749c;
        font-size: 1.1rem;
      }
    }
    .insurance-dp3 {
      @extend .insurence-type-lbl;
      .p-head {
        color: #9E0079;
        font-size: 1.1rem;
      }
    }
    .insurance-dp1 {
      @extend .insurence-type-lbl;
      .p-head {
        color: #007893;
        font-size: 1.1rem;
      }
    }
    .box-shodow-1{
      &:hover,
      &:focus {
        -moz-box-shadow: 0 0 4px $gray-700;
        -webkit-box-shadow: 0 0 4px $gray-600;
        box-shadow: 0 0 4px $gray-600;
        outline: 0;
      }
     }
     .box-shodow-0{
      &:hover,
      &:focus {
        -moz-box-shadow: 0;
        -webkit-box-shadow: 0;
        box-shadow: none;
        outline: 0;
      }
     }
    .insurance-home,
    .insurance-ho4,
    .insurance-ho5,
    .insurance-condo,
    .insurance-dp3,
    .insurance-dp1 {
      border: 1.5px solid;
      border-color: #bdbdbd;
      user-select: none;
      padding: 10px;
      width: 100%;
     
      .width-td {
        width: 20%;
      }
      .p-head {
        text-align: left;
        margin-bottom: 0px;
        float: left;
      }
      .p-para {
        color: #3a4b5e;
        text-align: left;
        margin-bottom: 0px;
        font-family: "Manrope", sans-serif !important;
        font-size: 14px;
        font-weight: 500;
        padding-right: 10px;
        padding-top: 30px;
        letter-spacing: normal;
      }

      .insu-icon-img {
        vertical-align: middle;
        text-align: center;
        padding-right: 0px;
      }

      .card-container {
        vertical-align: initial;
        padding-left: 10px;
        .check-mark {
          background-color: #f2fdf8;
          width: 18px;
          height: 18px;
          float: right;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          position: absolute;
          right: 14px;
          top: 14px;
          position: absolute;
        }
      }
      .property-lbl {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
      }
      .property-home {
        @extend  .property-lbl;
        height: 86px;
        background-image: url("../assets/images/standard-house.svg");
      }

      .property-condo {
        @extend  .property-lbl;
        height: 56px;
        background-image: url("../assets/images/standard-condo.svg");
      }
      .property-ho4 {
        @extend  .property-lbl;
        height: 75px;
        background-image: url("../assets/images/ho4.svg");
      }
      .property-ho5 {
        background-image: url("../assets/images/ho5.png");
        @extend  .property-lbl;
        height: 50px;
      }
      .property-dp3 {
        @extend  .property-lbl;
        height: 75px;
        background-image: url("../assets/images/insurence-dp3.svg");
      }
      .property-dp1 {
        @extend  .property-lbl;
        height: 75px;
        background-image: url("../assets/images/insurence-dp1.svg");
      }
    }
    .active {
      @include themify($themes) {
        border: 1.5px solid themed("buttonBgColor");
      }
      .check-mark {
        background-size: 18px;
        .check-mark {
          background-size: 18px;
          @include themify($themes) {
            @if (themed("buttonBgColor") == $hippo-blue) {
              background-image: url("../assets/images/checked-fa.svg");
            } @else if (themed("buttonBgColor") == $hippo-yellow) {
              background-image: url("../assets/images/checked-better.svg");
            } @else if (themed("buttonBgColor") == $firstConnect-blue) {
              background-image: url("../assets/images/checked-fc.svg");
            } @else {
              background-image: url("../assets/images/checked.svg");
            }
          }
          border: none !important;
        }
        border: none !important;
      }
    }
  }

  .h2-tooltip {
    top: 22px;
    position: absolute;
    width: 20px;
    right: -23px;
  }

  .information-icon {
    position: absolute;
    right: 14%;

    @include themify($themes) {
      color: themed("buttonBgColor") !important;
    }
  }
  .tooltip-icon {
    width: 25px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: 18px;

    @include themify($themes) {
      @if (themed("buttonBgColor") == $hippo-green) {
        background-image: url("../assets/images/tooltip-opendoor.svg");
      } @else if (themed("buttonBgColor") == $hippo-blue) {
        background-image: url("../assets/images/tooltip-firstAmerican.svg");
      } @else {
        background-image: url("../assets/images/tooltip-better.svg");
      }
    }
  }

  .classmargin {
    .state-class {
      text-align: left;
      #select-state {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .form-submit-action {
    margin-top: 3em;
    text-align: center;

    .loader-wrapper,
    .loader-wrapper-quote {
      width: 100%;
      position: relative;
      height: 100%;
      top: 1px;
      left: 0px;
      padding-left: 40%;
    }

    .sk-circle {
      width: 30px;
      height: 30px;
      position: relative;
      float: left;
    }
    .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .sk-circle .sk-child:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 18%;
      height: 18%;
      background-color: white;
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }

    .loader-wrapper-quote {
      .sk-circle .sk-child:before {
        @include themify($themes) {
          background-color: themed("backgroundColor") !important;
        }
      }
    }
    .sk-circle .sk-circle2 {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg);
    }
    .sk-circle .sk-circle3 {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg);
    }
    .sk-circle .sk-circle4 {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .sk-circle .sk-circle5 {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg);
    }
    .sk-circle .sk-circle6 {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg);
    }
    .sk-circle .sk-circle7 {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .sk-circle .sk-circle8 {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg);
    }
    .sk-circle .sk-circle9 {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg);
    }
    .sk-circle .sk-circle10 {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    .sk-circle .sk-circle11 {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg);
    }
    .sk-circle .sk-circle12 {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg);
    }
    .sk-circle .sk-circle2:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
    .sk-circle .sk-circle3:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .sk-circle .sk-circle4:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
    .sk-circle .sk-circle5:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
    .sk-circle .sk-circle6:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    .sk-circle .sk-circle7:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    .sk-circle .sk-circle8:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    .sk-circle .sk-circle9:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    .sk-circle .sk-circle10:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
    .sk-circle .sk-circle11:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
    .sk-circle .sk-circle12:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }

    @-webkit-keyframes sk-circleBounceDelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    @keyframes sk-circleBounceDelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  .date_of_birth {
    .DatePickerComponent {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 0px;
      p {
        display: none;
      }
      .MuiInputAdornment-root{
        .MuiIconButton-root{
          @include themify($themes) {
            color: themed('combination-theme-color');
          }
          &.Mui-disabled{
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
      button.Mui-disabled{
        color:rgba(0, 0, 0, 0.38);
      }
    }
    .redBorder div::after {
      border-bottom: 2px solid red;
    }
  }

  .btn-success {
    font-size: 0.875rem;
    width: 151px;
    height: 45px;
    border-radius: 50px;
    text-transform: capitalize;
    border: none;
    letter-spacing: 0.02857em;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 19px;
    @include themify($themes) {
      background-color: themed("buttonBgColor") !important;
    }
    &:focus {
      @include themify($themes) {
        background-color: themed("buttonBgDarkColor") !important;
      }
    }
  }

  .btnGetQuoteLink {
    font-size: 15px;
    width: 190px;
    height: 45px;
    border-radius: 25px;
    text-transform: capitalize;
    font-family: "Manrope", sans-serif;
    letter-spacing: 1.65px;
    background-color: white;
    @include themify($themes) {
      color: themed("backgroundColor") !important;
      border: 1px solid themed("backgroundColor") !important;
    }
    margin-left: 20px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-100 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .applicant-home-form {
    .insurance-type {
      .insurance-home,
      .insurance-condo,
      .insurance-ho4,
      .insurance-ho5,
      .insurance-dp1,
      .insurance-dp3 {
        .card-container {
          display: inline-block;
          .p-head {
            text-align: center;
            float: none;
            padding-top: 0;
          }
          .check-mark {
            position: relative;
            bottom: 100px;
          }
        }

        .property-condo {
          background-size: 45px !important;
          background-position: center center;
          height: 86px;
        }
        .property-dp3 {
          background-size: 45px !important;
          background-position: center center;
          height: 86px;
        }
        .property-dp1 {
          background-size: 45px !important;
          background-position: center center;
          height: 86px;
        }
        .property-home {
          background-size: 45px !important;
          background-position: center center;
          height: 56px;
        }
        .property-ho4 {
          background-size: 45px !important;
          background-position: center center;
          height: 86px;
        }
        .property-ho5 {
          height: 56px;
        }
        td {
          display: inline-block;
        }
        .width-td {
          width: 100%;
        }
        .p-para {
          text-align: center;
          float: none;
          padding-top: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  form .col-md-6 .row {
    margin: 0px;
  }
  .wrapper {
    .personal-details-wrapper {
      margin-bottom: 0px !important;
      margin-top: 0px;
      .first_name,
      .middle_name,
      .last_name,
      .email {
        margin-bottom: 20px;
      }
      .date_of_birth,
      .preferred_deductible,
      .phone_number {
        margin-bottom: 20px;
      }
    }
    .loan-officer-container {
      .loan_officer_fname {
        margin-bottom: 20px;
      }
    }

    .address-container {
      margin-bottom: 0px !important;
      .row {
        margin-bottom: 0px !important;
        margin-top: 0px;
        margin-right: 0px;

        .street,
        .unit,
        .city,
        .state,
        .zip {
          margin-bottom: 20px;
        }
      }
    }
    .Content-formControl-79 {
      &.classmargin {
        margin: 16px 8px 0px 8px;
      }
    }
    .container {
      &.insurance-type {
        width: 100%;
        .col-md-6 {
          padding: 0px 15px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
  .modal-content {
    width: 94%;
    margin: 0 auto;
  }

  .insurance-home,
  .insurance-ho5,
  .insurance-ho4,
  .insurance-condo,
  .insurance-dp1,
  .insurance-dp3 {
    min-height: 100px;
  }
  .property-home,
  .property-condo,
  .property-ho5,
  .property-dp3,
  .property-ho4,
  .property-dp1 {
    background-size: 40px !important;
    background-position: center center;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .form-wrapper {
    > .col-md-6 {
      widows: 70%;
      flex: 0 0 70%;
      max-width: 70%;
    }
    .offset-lg-3 {
      margin-left: 10%;
    }
    label {
      font-weight: normal;
    }
    form .col-md-6 .row {
      margin: 0px;
    }
    .wrapper {
      .personal-details-wrapper {
        margin: 0px;
      }
    }
  }
  .modal-content {
    width: 96%;
    margin: 0 auto;
  }
  .insurance-home,
  .insurance-condo {
    min-height: 110px;
  }
}
@media (max-width: 992px) {
  .applicant-home-form {
    .insurance-type {
      .mob-margin-bottom-10 {
        margin-bottom: 10px;
      }
    }
  }
  .margin-top-50 {
    margin-top: 0px !important;
  }
}
@supports (-ms-ime-align: auto) {
  .property-ho5 {
    left: 23px;
  }
}
@supports (-ms-ime-align: auto) {
  .property-ho4 {
    left: 23px;
  }
}
@media (max-width: 375px) {
  .pet-form-card {
    .MuiCardContent-root {
      padding: 0px !important;
    }
  }
}
