@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.common-modal-container {
  .ams-border-bottom {
    border-radius: 0px;
  }
  .MuiTypography-h6 {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 500;
    text-align: left;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    h2 {
      font-family: 'Manrope', sans-serif !important;
    }
    hr {
      border-top: 1px solid #cddbeb;
      margin-bottom: 5px;
    }
  }
  .btn-wraper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    .navigation-back-btn {
      padding-right: 10px;
      border-radius: 16px;
      margin-right: 20px;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
      }
      margin-right: 20px !important;
      width: 100px;
      height: 35px;
      text-transform: capitalize;
    }

    .navigation-btn,
    .navigation-next-btn {
      background: 0% 0% no-repeat padding-box;
      border-radius: 25px;
      color: $white;
      width: 100px;
      text-transform: capitalize;
      @include themify($themes) {
        background-color: themed('backgroundColor');
        border: 1px solid themed('backgroundColor');
      }

      &:disabled {
        color: $white;
        width: 100px;
        text-transform: capitalize;
        @include themify($themes) {
          background-color: themed('backgroundColor');
          border: 1px solid themed('backgroundColor');
        }

        &:disabled {
          color: $white;
          opacity: 0.7;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .plr-24 {
    padding: 0px 24px;
  }
}
