@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.main {
  header {
    user-select: none;
    box-shadow: none;
    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
      .logo {
        padding-right: 15px;
      }
      .header-ht {
        margin: 20px 0px;
        align-items: center;
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .logo {
      max-width: 112px;
      object-fit: contain;
    }

    .logo-text {
      width: 100%;
      border-left: 2px solid $white;
      color: $white;
      font-size: 28px;
      font-weight: 400;
      text-decoration: none;
      padding-left: 30px;
      line-height: 45px;
    }

    .bulk-quote-logo-wrapper {
      width: 100%;

      .divider {
        position: relative;
        float: left;
        width: 1.5px;
        height: 45px;
        background-color: $white;
        margin-top: 13px;
      }

      .bulk-quote-logo {
        width: 180px;
        height: 70px;
        margin-left: 20px;
      }
    }

    .report-logo-wrapper {
      width: 100%;
      .divider {
        position: relative;
        float: left;
        width: 1.5px;
        height: 45px;
        background-color: $white;
        margin-top: 13px;
      }

      .report-logo {
        max-width: 180px;
        width: 100%;
        margin-left: 20px;
      }
    }
    .admin-logo-wrapper {
      width: 100%;

      .divider {
        position: relative;
        float: left;
        width: 1.5px;
        height: 45px;
        background-color: $white;
        margin-top: 13px;
      }
      .admin-logo {
        width: 180px;
        height: 70px;
        margin-left: 20px;
      }
    }

    .coBranding-logo {
      position: relative;
      width: 100%;
      img {
        position: absolute;
        width: 100px; 
        top: 50%;
        right: 0;
        -webkit-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
      }
    }

    .userWrap {
      display: flex;
      justify-content: flex-end;
      .userName {
        padding: 12px 0px 12px 12px;
        color: $white;
        white-space: normal;
        word-wrap: break-word;
        float: right;
        text-align: right;
      }

      .icon {
        width: 100% !important;
        max-width: 40px;
        float: right;
        margin-left: 10px;
      }
    }
  }

  .address-wrap {
    width: 100%;
    .address {
      padding: 20px 35px;
      float: left;
      font-family: 'Manrope', sans-serif;
      width: 70%;

      .address-title {
        font-weight: 300;
        font-size: 15px;
        line-height: 18px;
      }
    }
    .address-w {
      margin-top: 15px;
      font-weight: 300;
      font-size: 42px;
      line-height: 40px;
      font-family: 'Manrope', sans-serif;

      td {
        vertical-align: top;
      }
      span {
        text-transform: uppercase;
        display: block;
      }
    }
    .fa-map-marker-alt {
      margin-right: 20px;
    }
  }

  .table-right-wrapper {
    max-width: 312px;

    @include themify($themes) {
      border: themed('headerBorderColor') !important;
    }
    width: 100%;
    float: left;

    height: 100%;
    font-family: 'Manrope', sans-serif;

    .lbl-caption {
      padding-bottom: 0.75rem;

      text-align: left;
      caption-side: bottom;
    }
    .table-right-title {
      font-weight: 300;
      width: 150px;
      font-size: 14px;
      line-height: 18px;
      color: #f1f1f1;
    }

    td {
      font-size: 18px;
    }
    .h2-tooltip {
      margin-left: 10px;
      position: absolute;
      cursor: pointer;
    }
  }

  .label-text {
    width: 80%;
    display: inline-block;
    padding-top: 10px;
    margin-bottom: 20px;
    font-weight: 100;
    line-height: 21px;
  }

  .label-amount {
    font-weight: 300;
    font-size: 20px;
    line-height: 0px;
    width: 95px;
    text-align: left;
    span {
      margin-top: 18px;
      display: block;
      text-align: left;
      font-size: 10px;
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main {
    header {
      .logo-text {
        text-decoration: none;
        padding-left: 10px;
        line-height: 45px;
      }
    }
    .address-wrap {
      .address {
        padding: 20px;
        float: left;
        font-family: 'Manrope', sans-serif;
        width: 100%;
        padding-bottom: 5px;
      }
      .address-w {
        span {
          margin-top: 1px;
          text-transform: uppercase;
          font-size: 20px;
          line-height: 20px;
          font-weight: 200;
        }
      }
      .fa-map-marker-alt {
        margin-right: 0px;
        height: 27px;
        vertical-align: top;
        margin-top: 4px;
      }
    }
    .table-right-wrapper {
      width: 100%;
      float: left;
      padding: 20px;
      padding-top: 0px;
      height: 100%;
      font-family: 'Manrope', sans-serif;
      .table-right-title {
        min-width: 150px;
        margin-bottom: 8px;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 640px) {
  .main {
    .address-wrap {
      .address {
        padding: 20px;
        float: left;
        font-family: 'Manrope', sans-serif;
        width: 100%;
        padding-bottom: 5px;
      }
      .address-w {
        span {
          margin-top: 1px;
          text-transform: uppercase;
          font-size: 20px;
          line-height: 10px;
          font-weight: 200;
        }
      }
      .fa-map-marker-alt {
        margin-right: 0px;
        height: 27px;
      }
    }
    .table-right-wrapper {
      width: 100%;
      float: left;
      padding: 10px 15px 10px 15px;
      height: 100%;
      font-family: 'Manrope', sans-serif;
      .table-right-title {
        min-width: 150px;
        margin-bottom: 8px;
        font-weight: 300;
      }
    }
  }

  header {
    .header-ht {
      margin: 0px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      > div {
        display: flex;
      }
      .coBranding-logo {
        max-width: 60px;
      }
      .logo {
        max-width: 60px !important;
      }
      .report-logo-wrapper {
        .divider {
          height: 18px;
          margin-top: 10px;
        }
        .report-logo {
          max-width: 108px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .main {
    header {
      .logo-text,
      .bulk-quote-logo-wrapper {
        display: none;
      }
      .userWrap {
        width: 100%;
        .profile-icon {
          display: none;
        }
      }
    }
    .address-wrap {
      .address {
        padding: 20px 20px 0px 20px;
        float: left;
        font-family: 'Manrope', sans-serif;
        width: 100%;
        padding-bottom: 5px;
      }
      .address-w {
        span {
          margin-top: 1px;
          text-transform: uppercase;
          font-size: 20px;
          font-weight: 200;
          display: block;
          line-height: 23px;
        }
      }
      .fa-map-marker-alt {
        margin-right: 0px !important;
        height: 27px;
        vertical-align: top;
        margin-top: 2px;
      }
    }

    .table-right-wrapper {
      padding: 0px 12px 12px 12px;
    }
  }
}
@media (min-width: 320px) and (max-width: 400px) {
  .main {
    header {
      .userWrap {
        .userName {
          white-space: normal;
          word-wrap: break-word;
          .downIcon {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .main {
    header {
      .coBranding-logo {
        position: absolute;
        top: 20px;
      }
    }
  }
}
