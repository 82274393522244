@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.view-bound-modal {
  .graph-loader {
    z-index: 9999;
    color: #fff;
  }
  .view-ams-policy-heading {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    margin-bottom: 8px;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    .closeButton {
      position: absolute;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .document-label {
    font-size: 12px;
  }
  .download-icon {
    cursor: pointer;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .view-info {
    label {
      font-size: 12px;
    }
    p {
      border-bottom: 1px solid #afaeae;
      font-weight: 500;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.04em;
      color: rgba(128, 128, 128, 0.6);
    }
  }
  .MuiInput-underline:before,
  .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:after {
    border-bottom: 1px solid #ccc;
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.54);
  }
  .eoi-document-file {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 232px;
      margin-left: 10px;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
    .MuiInput-underline:before,
    .MuiInput-underline:hover:not(.Mui-disabled):before,
    .MuiInput-underline:after {
      border-bottom: none;
    }
  }
  .MuiInputBase-root {
    color: #3a4b5e;
    font-weight: 600;
    font-family: manrope, sans-serif;
  }
  .carrier-risk-consent-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Roboto', sans-serif
  }
}
@media (max-width: '991px') {
  .view-bound-modal {
    .mob-margin-bottom-20 {
      margin-bottom: 1rem;
    }
  }
}
@media(max-width:575px) {
  .view-bound-modal {
    .eoi-document-file {
      span {
        width: 200px;
      }
    }
  }
}
