.select-autocomplete {
  position: relative;
  display: inline-block;
  input {
    border: none;
    cursor: text;

    &:focus {
      border: none;
      width: 86%;
    }
  }
  .hide-search {
    caret-color: transparent;
    cursor: pointer;
  }
}
.select-portal {
  ul {
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10000;
    top: 100%;
    list-style: none;
    overflow: auto;
    min-height: 30px;
    max-height: 200px;
    background-color: #fff;
    border: 1px solid #00cf83;
    li {
      padding-left: 5px;
    }
    & li[data-focus='true'] {
      background-color: #00cf83;
      color: white;
      cursor: pointer;
    }
    & li:active,
    & li:hover {
      background-color: #00cf83;
      cursor: pointer;
      color: white;
    }
  }
}
