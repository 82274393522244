@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.insured-details-table-wrapper {
  table{
    thead{
      th{
      font-weight: 700;
    }
  }
    th,td{
      font-family: "Manrope", sans-serif;
    }
    tbody{
      tr{
        th,td{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
    }
  }
  .table-pagination-container {
    .MuiPaginationItem-root {
      margin: 0 2px;
      font-weight: 500;
      color: #3a4b5f;
    }

    & .Mui-selected {
      @include themify($themes) {
        color: $white;
        font-weight: 600;
        background-color: themed('backgroundColor') !important;
      }
    }
  }

  .footer-cell {
    border-bottom: none;
  }
  .tableRow {
    white-space: nowrap;
    cursor: pointer;
    &:nth-of-type(odd) {
      @include themify($themes) {
        background-color: themed('table-row-color') !important;
      }
    }
  }
  .records-cell{
    text-align: center;
  }
  .carrier-cell{
    div{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
}
@media(max-width:768px){
  .insured-details-table-wrapper{
  .records-cell{
    text-align: left;
    }
  }
}