@import '../../stylesheets/theme/theme';
@import '../../stylesheets/theme/theme-variables';

.cache-wrapper {
  min-height: calc(100vh - 225px);
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  .icon {
    width: 22px;
    vertical-align: bottom;
    height: 25px;
    margin-right: 8px;
  }

  .btnOutlined {
    background: 0% 0% no-repeat padding-box;
    border-radius: 25px;
    color: $white;
    margin-left: 11px;
    width: 190px;
    @include themify($themes) {
      border: 1px solid themed('backgroundColor');
    }
    span {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      text-transform: capitalize;
    }
  }
}

.cache-dialog-wrap {
  font-family: 'Roboto', sans-serif;

  .navigation-wrapper {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .navigation-next-btn {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #a2a0a099;
    border-radius: 25px;
    color: $white;
    width: 170px;
    @include themify($themes) {
      background-color: themed('backgroundColor');
      border: 1px solid themed('backgroundColor');
    }

    &:disabled {
      color: $white;
    }
  }

  .navigation-delete-btn {
    padding-right: 10px;
    border-radius: 16px;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
    }
    margin-right: 20px;
    width: 170px;
    height: 35px;
  }

  .org-divider {
    font-size: 18px;
    text-align: center;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .org-container {
    max-width: 210px;
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
  }

  .org-code-input {
    margin: 0 auto;
    min-width: 390px;

    input {
      padding: 14px 14px;
    }

    :focus,
    :hover {
      outline: none;
    }
  }

  .selected-org {
    .org-container {
      @include themify($themes) {
        -webkit-box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset,
          0 0 4px #868e96;
        -moz-box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset,
          0 0 4px #868e96;
        box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset, 0 0 4px #868e96;
        border: 1px solid themed('backgroundColor');
      }
    }
  }

  .disabled-org {
    cursor: default;
  }

  .error-row {
    min-height: 24px;
    padding: 8px 20px 0px 20px;
    .error-message {
      width: 100%;
      height: auto;
      color: $red;
      font-size: 12px;
      word-break: break-word;
      text-align: center;
    }
    .org-error {
      width: 390px;
      margin: 0 auto;
      text-align: left;
    }
    .org-success {
      width: 390px;
      margin: 0 auto;
      text-align: left;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .cache-wrapper {
    .org-code-input {
      margin: 0 auto;
      min-width: 220px;
    }
  }
}
