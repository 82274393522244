@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.filter-selector-container {
  font-family: "Manrope", sans-serif;
  font-size: 16px;

  .css-1wa3eu0-placeholder,
  .css-tlfecz-indicatorContainer {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }

  .filter-selector__value-container {
    max-height: 127px;
    overflow-y: auto;
  }

  .filter-selector__control {
    border: 1px solid #d9d9d9;
    box-shadow: none;
    cursor: pointer;
  }

  .filter-selector__option {
    font-size: 16px;
    cursor: pointer;

    &:active {
      background: none;
    }

    &:hover {
      @include themify($themes) {
        background-color: rgba(themed('lightbg'), 0.3);
      }
    }

    label {
      vertical-align: middle;
      cursor: pointer;
      margin: 0px;
    }

    .MuiCheckbox-root {
      padding-top: 0px;
      padding-bottom: 0px;

      &:hover {
        background-color: 'transparent';
      }
    }

    .Mui-checked {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }

  .filter-selector__option--is-selected {
    background: none;
    color: $hippo-text-color;
  }

  .filter-selector__multi-value {
    font-weight: 500;

    .filter-selector__multi-value__label {
      span {
        vertical-align: middle;
      }
    }
  }

  .filter-selector__clear-indicator {
    cursor: pointer;
  }

  .btn-container {
    position: relative;
    text-align: center;

    .btn {
      margin: 5px;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
  .list-parent {
    display: flex;
  }
  .css-1rhbuit-multiValue {
    @include themify($themes) {
      background-color: themed('quote-bg-color') !important;
    }
  }
  .css-12jo7m5 {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}
.css-1pahdxg-control {
  &:hover {
    @include themify($themes) {
      border-color: themed('backgroundColor');
    }
  }
}
.filter-selector__multi-value__remove {
  cursor: pointer;
  @include themify($themes) {
    color: themed('combination-theme-color');
  }
  &:hover {
    @include themify($themes) {
      background-color: themed('quote-bg-color') !important;
    }
  }
}
.menuDropdownList {
  background: $white;
}
