.content-footer {
  font-family: 'Nitti Grotesk SemiLight', sans-serif;
  border: none;

  .p-footer {
    text-align: center;
    width: 100%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 20px;
  }
}
