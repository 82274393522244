@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.file-uploader-wrapper {
  .error-msg {
    color: red;
    text-align: center;
  }
  .file-drop-box {
    margin: 0 auto;
  }
  .file-upload-area {
    position: relative;
    width: 100%;
    height: 41px;
    display: block;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 2px;
    -webkit-transition: background-color 100ms linear;
    -ms-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }
  .upload-icon {
    padding-top: 2px;
    margin-top: 4px;
  }
  .btn-file {
    margin: 0 auto;
    width: 380px;
  }
  .file {
    line-height: 24px;
    background-color: $white !important;
  }
  .file-placeholder {
    position: relative;
    top: 7px;
    left: 10px;
    float: left;
    font-size: 14px;
    color: rgb(161, 161, 161);
  }
  .file-upload-icon {
    float: right;
    height: 35px;
    width: 35px;
    color: $white;
    border-radius: 4px;
    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
    }
  }
  .filename {
    font-size: 14px;
    display: inline-block;
    color: $black;
    text-align: left;
    padding: 0px 5px 0px 0px;
    max-width: 365px;
    word-break: break-word;
  }
  .upload-error {
    color: $red;
    font-size: 12px;
    word-break: break-word;
    max-width: 380px;
  }
  .delete-button {
    background: none;
    border: none;
    padding: 0px;
  }
  .file-delete-icon {
    color: $red;
    font-size: 16px;
    cursor: pointer;
  }
  .file-label {
    display: block;
    margin-bottom: 0px;
  }
  .input-file {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
    cursor: pointer;
  }
  .input-file + .file-label {
    max-width: 100%;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    border-radius: 6px;
  }
  .input-file:active + .file-label {
    color: #fff;
    .filename {
      color: #fff;
      transition: 0.3s ease-out;
    }
  }
  .input-file:focus + .file-label,
  .input-file.has-focus + .file-label {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .highlight {
    @include themify($themes) {
      background-color: themed('backgroundColor');
      border-color: themed('backgroundColor');
    }
    .file-placeholder {
      color: $white;
    }
  }
}
