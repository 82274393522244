@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.grey-bg {
  background: #f6f6f6;
}
.organization-container-wraper {
  .mb-40 {
    margin-bottom: 30px;
  }
  .new-quote-btn {
    font-family: 'Manrope', sans-serif !important;
  }
  .add-icon-border {
    width: 18px;
    height: 18px;
    @include themify($themes) {
      box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
    }
    border-radius: 50%;
  }
  .searchfield {
    width: 100%;
    display: flex;
    max-width: 302px;
    height: 35px;
    border-radius: 6px;
    border: 1px solid #cddbeb;
    font-family: 'Manrope', sans-serif !important;
    &::placeholder {
      color: #3a4b5e;
      font-size: 16px;
    }
    svg {
      @include themify($themes) {
        color: themed('combination-theme-color') !important;
      }
    }
  }
  .MuiCardContent-root {
    padding-top: 24px !important;
  }
  .new-org-btn {
    max-width: 279px;
    height: 40px;
    border-radius: 30px;
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0 0 14px #e6eaf2;
    text-transform: capitalize;
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
    margin-right: 20px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif !important;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
    }
    position: absolute;
    right: 0;
    top: 0px;
    &:hover {
      border: 2px solid;
      background-color: #fff;
      @include themify($themes) {
        border-color: themed('buttonHoverBorder');
      }
    }
  }
  .oraganization-table {
    box-shadow: 0px 0px 19px 1px #edf0f5;
    table {
      th {
        padding: 10px !important;
        font-weight: 700 !important;
        font-family: 'Manrope', sans-serif;
        &:last-child {
          svg {
            display: none;
          }
        }
      }
    }
    .MuiPaper-root {
      box-shadow: none;
    }
    .MuiTableCell-body {
      div {
        justify-content: center;
      }
    }
    .edit-svg-tbl {
      font-size: 18px;
    }
    .text-color {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
    .MuiInputBase-root {
      border: 1px solid #cddbeb;
      border-radius: 6px;
    }
    .MuiFormControl-root {
      position: absolute;
      left: 0;
      top: 0px;
    }
    .MuiToolbar-root {
      div {
        overflow: inherit;
        padding-left: 7px;
      }
    }
    .MuiInput-underline {
      &::before,
      &::after,
      &:hover {
        border-bottom: 1px solid #cddbeb;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #cddbeb;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    tbody {
      .MuiTableCell-root {
        padding: 5px 16px;
        border-bottom: none;
        font-size: 14px !important;
      }
      .MuiTableRow-root {
        &:nth-of-type(odd) {
          @include themify($themes) {
            background-color: themed('table-row-color') !important;
          }
        }
      }
    }
    thead {
      border-bottom: 3px solid #808080a1;
    }

    .logo {
      width: 59px;
      height: 22px;
    }

    margin-top: 1em;
    font-family: 'Manrope', sans-serif !important;
    .MuiTableFooter-root {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }
}
.organization-image-wrapper {
  .organization-logo {
    width: 60px;
    height: 40px;
    object-fit: contain;
  }
  .skeleton-css {
    margin-top: -1.4em;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .organization-container-wraper {
    .oraganization-table {
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .new-org-btn {
      top: 50%;
      width: 100%;
      margin: 0 auto;
      max-width: inherit;
    }
    .MuiToolbar-regular {
      min-height: 100px;
    }
  }
}
