@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.feedback-form-model {
  .feedback-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    font-family: 'Manrope', sans-serif;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  .subject-textfield,
  .decription-textfield {
    border: 1px solid #cddbeb;
    border-radius: 5px;
    input {
      padding-left: 7px !important;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: none !important;
    }
    .mui-focused {
      border-bottom: none !important;
    }
  }
  .decription-textfield {
    div {
      div {
        height: 80px;
        textarea {
          height: 75px !important;
          overflow-y: scroll !important;
          margin: 12px 0px;
        }
      }
    }
  }
  .decription-textfield .subject-label {
    font-weight: 200;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 7px;
  }

  .cancel-button {
    padding-right: 10px;
    border-radius: 16px;
    text-transform: capitalize;
    width: 100px;
    border-radius: 25px;
    height: 35px;
    @include themify($themes) {
      border: 1px solid themed('backgroundColor');
      color: themed('backgroundColor');
    }
  }
  .submit-button {
    box-shadow: none;
    border-radius: 25px;
    color: #fff;
    width: 100px;
    margin-right: 10px;
    text-transform: capitalize;
    @include themify($themes) {
      background: themed('backgroundColor');
    }
    &:hover {
      box-shadow: none;
    }
  }
  .MuiDialogActions-root {
    .submit-button {
      &.Mui-disabled {
        color: #fff;
      }
    }
  }
}
