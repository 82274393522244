@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme';

.wrapper {
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $hippo-text-color;
  padding-bottom: 20px;
  //min-height: calc(100vh - 325px);

  .title-1 {
    font-family: 'Manrope', sans-serif;
    font-weight: normal;
    text-align: center;
    width: 100%;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
    font-size: 58px;
  }

  .title-2 {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    text-align: center;
    width: 100%;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
    font-size: 24px;
  }

  .sub-title {
    font-size: 23px;
    font-weight: 300;
    line-height: 25px;
    font-family: 'Nitti Grotesk SemiLight', sans-serif;
    color: $hippo-subtitle-color;
  }

  .heading-wrap {
    margin-top: 70px;
    margin-bottom: 55px;
  }

  .modal {
    overflow-y: scroll;

    .modal-dialog {
      .modal-content {
        padding: 20px;
      }
      max-width: 850px;
    }

    .modal-header {
      border-bottom: none;
      padding-bottom: 0px;

      .dialog-icon {
        width: 40px;
        background-image: url('../../../assets/images/icon-profile.svg');
        background-size: contain;
        height: 45px;
        background-repeat: no-repeat;
        background-position: center center;
        margin: 10px auto;
      }

      .modal-title {
        font-family: 'Manrope', sans-serif;
        font-weight: 400;
        text-align: center;
        width: 100%;
        letter-spacing: 1.2px;
        margin-bottom: 10px;
        font-size: 36px;

        .fa-user-circle {
          font-size: 50px;
          @include themify($themes) {
            color: themed('backgroundColor');
          }
        }
      }
    }

    .modal-body {
      border-radius: 3px;
      padding-top: 0px;
      color: #5c6f78;
      font-size: 16px;
      font-weight: 300;
      line-height: 17px;
      font-family: 'Nitti Grotesk SemiLight', sans-serif;

      h2 {
        font-family: 'Akzidenz-Medium', sans-serif;
        font-weight: 500;
        text-align: center;
      }
      p {
        text-align: center;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.54);
      }
      .text-center {
        margin-bottom: 25px !important;
        line-height: 20px;

        a {
          @include themify($themes) {
            color: themed('backgroundColor');
          }
          cursor: pointer;
          text-decoration: none;
        }
      }

      .full-address {
        text-transform: capitalize;
        text-transform: uppercase;
      }
    }

    .modal-footer {
      font-family: 'Nitti Grotesk SemiLight', sans-serif;
      border: none;

      .p-footer {
        text-align: center;
        width: 100%;
        font-size: 15px;
        color: rgba(0, 0, 0, 0.54);
        padding-right: 10px;
      }
    }
  }
}
