/*
 * Application global variables.
 */
// ---------------------------------------------------------------------------
//
// Color system
//

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue-100:#B3D1E9;

$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$errorRed: #f44336;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$darkGrey: #3a4b5e;

//common
$flood-price-bg: rgb(84, 152, 223);
$hippo-text-color: #1f1f21;
$hippo-subtitle-color: rgba(0, 0, 0, 0.54);
$hippo-quote-details: rgb(146, 193, 248);
$hippo-lowerprice-bg: #7c5bf4;
$hippo-purchase-btn-bg: #21cc7e;
$hippo-headerTable-borderColor: #41feaf;
$lowestprice-box-bg: #00cf83;
$otherprice-box-bg: #20b37a;
$property-type-home-title: #648ee0;
$property-type-condo-title: #d02902;
$hippoTooltip:#f6fbff;

//light theme variables
$hippo-green: #00cf83;
$hippo-footer-color: #4c4a50;
$hippo-purchase-by-phone-green: #53deab;
$hippo-green-dark: #039b5d;
$hippo-green-light: #a3edd2;
$hippo-green-deep-light: #e3fff3;
$hippo-table-row-color: #f4fffa;
$hippostep-border: #d5dfe8;

//dark theme variables
$hippo-purple: #552b84;
$hippo-drkpurple: #472b5d;
$hippo-yellow: $yellow;
$hippo-purchase-by-phone-purple: #552b84db;
$hippo-yellow-dark: #deab0f;
$hippo-black-color: #000;

// blue theme variables
$hippo-blue: #013a6f;
$hippo-purchase-by-phone-blue: #f4b252;
$hippo-blue-dark: #013a6f;
$otherprice-box-blue-bg: #013a6f;
$hippo-purchase-blue-btn-bg: #f19109;
$hippo-lowerprice-blue-bg: #f09109;
$lowestprice-box-blue-bg: #084f90;
$hippo-headerTable-blue-borderColor: $white;
$hippo-light-border: #bfeedd;

// firstConnect theme variable
$firstConnect-blue: #2196f3;
$firstConnect-disable-step: #f0f8ff;
$firstConnect-disable-step-label: #6b7a93;
$firstConnect-light: #eaf2f9;

//pennymac theme variable
$pennymac-navyblue: #003087;
$pennymac-navyblue-light: #1f95f3;
$pennymac-navyblue-disable-step: #f0f8ff;
$pennymac-navyblue-disable-step-label: #6b7a93;
$pennymac-navyblue-light: #eaf2f9;
$light-border-color: #b0c6ef;
$pennymac-border-step: #2196f3;
$stepbg-fc-pennymac: #f4fbff;
$pennymac-button-focus: #3c62a8;
$light-sky-blue: #81b7e2;
$step-active-white-bg: $white;
$tootltip-color-light:#F3FBFE;


//Doma theme variable
$doma-purplish-blue: #10069F;
$doma-purplish-blue-light: #1f95f3;
$doma-purplish-blue-disable-step: #f0f8ff;
$doma-purplish-blue-disable-step-label: #6b7a93;
$doma-purplish-blue-light: #eaf2f9;
$light-border-color: #b0c6ef;
$pennymac-border-step: #2196f3;
$stepbg-doma: #F6F5FF;
$pennymac-button-focus: #3c62a8;
$light-sky-blue: #81b7e2;
$step-active-white-bg: $white;
$tootltip-color-light:#F3FBFE;



//images
$hippo-green-home: '../../../assets/images/auto.svg';
$fc-blue-home: '../../assets/images/home-fc.svg';

//general global variables
$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
);
