.lender-setting-wrap {
  padding: 30px;
  .form-item {
    label {
      font-size: 14px !important;
      font-family: 'Manrope', sans-serif;
      font-weight: 700 !important;
      margin-bottom: 0px;
      line-height: 1;
      letter-spacing: 0.00938em;
      color: #000;
    }
  }
  .native-select {
    input {
      border-bottom: 1px solid #f7f4f4;
    }
  }
  .normal-font {
    color: #3a4b5e;
    word-break: break-word;
    font-weight: normal !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .width-30-100 {
    width: 30%;
  }
  .w-30 {
    width: 30%;
  }
  .border-lender {
    border-bottom: 1px solid #f7f4f4;
  }
  .normal-font {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.04em;
    color: #3a4b5e;
    word-break: break-word;
    font-weight: normal !important;
  }
  .w-70 {
    width: 70%;
  }
  .text-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}
.switch-margin-left {
  margin-left: -12px;
  display: flex;
  justify-content: flex-start;
  span{
    .MuiIconButton-root{
      cursor: default;
    }
  }
}
.mob-ptb-0 {
  padding-bottom: 0px;
  padding-top: 0px;
}
@media (max-width: 991px) {
  .lender-setting-wrap {
    .width-30-100 {
      width: 77%;
    }
  }
  .m-padding-left-0 {
    padding-left: 0;
  }
  .m-flex{
    display: flex;
    justify-content: flex-end;
  }
  .mob-col-20 {
    width: 30%;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
  }
  .mob-col-80 {
    width: 70%;
    padding-right: 0px;
  }
 
}
@media (min-width: 992px) and (max-width: 1198px) {
  .crm-card-wrapper {
    .width-30-100 {
      width: 30%;
    }
  }
}
