@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.quotes-sidebar {
  #sidebar {
    min-width: 270px;
    font-family: 'Manrope', sans-serif;
    max-width: 270px;
    color: #fff;
    -webkit-transition: all 0.5s;
    -moz-transition: left 0.5s ease;
    transition: left 0.5s ease;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    background: #f4f5f4;
    height: 100%;

    .vehicle-icon {
      width: 20px;
      height: 23px;
      position: relative;
      top: 24px;
      left: 17px;
    }
    hr {
      margin-left: 20px;
      margin-right: 20px;

      border-top: 2px solid #fff;
      margin-bottom: 0px;
    }
    ul {
      padding-inline-start: 50px;

      li {
        list-style-type: none;
        color: #000;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
      }
      .vehicle-li {
        font-weight: 500;

        .edit-icon {
          float: right;
          font-size: 16px;
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
          margin-right: 15px;
          cursor: pointer;
        }

        span {
          font-weight: normal;
        }
        .lbl-span {
          font-size: 14px;
          font-weight: 400;
        }
        .driver-name {
          word-break: break-word;
        }
        .ans-span {
          font-size: 12px;
          color: #969696;
        }
        .email {
          color: #969696;
          word-break: break-word;
        }
        .addvehicle-span {
          cursor: pointer;
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
          font-size: 12px;
          .add-icon {
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .quotes-sidebar {
    #sidebar {
      max-width: 200px;
      width: 100%;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .quotes-sidebar {
    #sidebar {
      min-width: 284px;
      .vehicle-icon {
        left: 20px;
      }
      ul {
        .vehicle-li {
          padding-left: 20px;
        }
      }
    }
  }
}
//For Edge
@supports (-ms-ime-align: auto) {
  .quotes-sidebar {
    #sidebar {
      min-width: 284px;
      .vehicle-icon {
        left: 20px;
      }
      ul {
        .vehicle-li {
          padding-left: 20px;
        }
      }
    }
  }
}
