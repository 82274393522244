@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.quote-questionnaire-container {
  width: 100%;
  min-height: 185px;
  padding: 20px;
  .mt-6 {
    margin-top: 6em;
  }

  .loader-wrapper {
    margin-top: 52px;
  }

  .error {
    color: $errorRed;
    font-size: 12px;
    text-align: left;
    margin-left: 2px;
  }
  .btnContinue {
    border-radius: 25px;
    width: 150px;
    height: 45px;
    color: #fff !important;
    box-shadow: none;
    text-transform: capitalize;
    cursor: pointer;

    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
      box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
    }
  }

  .details-loader {
    @include themify($themes) {
      color: themed('buttonBgColor') !important;
    }
  }

  .loader-text {
    margin-left: 15px;
    font-size: 16px;
  }

  .submit-loader {
    margin-left: 5px;
    color: $white;
  }

  .form-control {
    width: 250px;
    margin-left: 5px;
  }

  .number-stepper-control {
    width: 100%;
    height: 100%;
    max-width: 200px;
    margin-left: 5px;
    label {
      font-weight: bold;
    }
  }

  .alt-form-control {
    margin-left: 20px;
  }

  .number-stepper {
    padding-top: 30px;
    display: flex;
    align-items: center;
    input {
      padding: 5px 10px;
    }
  }

  .btnBackLink {
    font-size: 0.875rem;
    width: 150px;
    height: 45px;
    border-radius: 25px;
    margin-right: 10px;
    margin-top: 60px;
    text-transform: capitalize;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
      box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
    }
    svg {
      font-size: 14px;
    }
  }
  .btn-back {
    font-size: 0.875rem;
    width: 150px;
    height: 45px;
    text-transform: capitalize;
    border-radius: 50px;

    letter-spacing: 0.02857em;
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    @include themify($themes) {
      border-color: themed('buttonBgColor') !important;
      color: themed('buttonBgColor') !important;
      box-shadow: 0 0 1px 0.2px themed('backgroundColor') !important;
    }
    svg {
      font-size: 14px;
    }
  }

  .btn-success {
    font-size: 0.875rem;
    width: 150px;
    height: 45px;
    border-radius: 25px;
    border: none;
    text-transform: uppercase;
    box-shadow: 0 4px 0 0 var(--button-box-side-color),
      0 calc(4px * 2) calc(4px / 2) 0 rgba(0, 0, 0, 0.08) !important;
    letter-spacing: 0.02857em;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    line-height: 1.75;
    margin-top: 60px;
    color: $white;
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
    &:focus {
      @include themify($themes) {
        background-color: themed('buttonBgDarkColor') !important;
      }
    }
    &:disabled {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
@media (max-width: 767px) {
  .questionnaire-container {
    padding: 0px;
      .alt-form-control {
        margin-left: 0px;
        margin-top: 2em;
      }
      .form-control{
        margin-left: 0px;
      }
  }
}
@media (max-width: 576px) {
  .questionnaire-container {
    .form-control {
      width: 100% !important;
    }
  }
}
