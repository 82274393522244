@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.formWidthmargin {
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
}
.autocomplete-address-wrapper {
  .clear-data-icon {
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
    @include themify($themes) {
      color: themed('combination-theme-color');
    }

    &:hover {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
}

.pac-container {
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 9999 !important;

  .pac-item {
    font-family: 'Manrope', sans-serif;
    line-height: 2.3;
    border-top: none;
    border-bottom: 1px solid #e6e6e6;

    &:hover {
      background-color: #80808033;
    }

    span {
      color: #808080;
      font-size: 16px;

      .pac-matched {
        color: rgba(0, 0, 0, 0.9);
        font-weight: 500;
      }
    }

    .pac-icon-marker {
      display: none;
    }
  }
}

.pac-logo {
  &:after {
    margin-top: 10px;
  }
}
