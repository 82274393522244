@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.flood-success-wrapper {
  .success-icon {
    font-size: 8rem;
    margin-bottom: 20px;
    @include themify($themes) {
      color: themed('buttonBgColor') !important;
    }
  }

  .success-message {
    margin-bottom: 50px;
  }
}
