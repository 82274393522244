@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme';
.error-page-wrapper,
.quote-expired-error-page-wrapper {
  .error-wrapper {
    .error-div {
      display: flex;
      justify-content: center;
      margin-top: 87px;
      .ops-label {
        font-weight: 800;
        font-size: 80px;
        line-height: 125px;
        font-family: 'Manrope', sans-serif;
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
      .error-msg {
        font-weight: bold;
        font-size: 24px;
        line-height: 52px;
        color: #3a4b5e;
      }
      .error-code {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .error-page-wrapper {
    .error-wrapper {
      .error-div {
        .error-msg {
          line-height: inherit;
          margin-bottom: 12px;
          font-size: 22px;
        }
      }
    }
  }
}
