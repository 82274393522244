@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.spinner-loader-wrapper {
  .MuiCircularProgress-colorPrimary {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}
