@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.vin-tooltip {
  position: absolute;
  right: 63px;
  bottom: 20px;
  .info-circle {
    &:hover {
      cursor: pointer;
    }
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}
@media(max-width:768px){
  .vin-tooltip{
    display: none;
  }
}