@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.range-btn-label {
  font-weight: 700;
  font-family: "Manrope", sans-serif;
}

.date-range-container {
  .MuiFormControl-root {
    margin-top: 0px;
    margin-bottom: 2px;
  }

  .range-error {
    color: $red;
  }

  .date-range-input {
    border-color: none;
    box-shadow: none;
  }

  .btn {
    background: none;
    color: $hippo-text-color;
    border: 1px solid #d9d9d9;
    box-shadow: none;
    width: 100%;
    padding: 6px 8px;

    svg {
      @include themify($themes) {
        color: themed('combination-theme-color');
      }
      vertical-align: bottom;
    }

    .date-holder {
      padding: 0 30px;
    }

    .to-label {
      font-weight: 500;
      padding: 0px 5px;
    }
  }
}

.daterangepicker {
  td.active {
    @include themify($themes) {
      background-color: themed('backgroundColor');
    }

    &.off {
      color: #e6e6e6;
    }
  }

  .drp-buttons .applyBtn {
    @include themify($themes) {
      border-color: themed('backgroundColor');
      background-color: themed('backgroundColor');
    }
  }

  .ranges .active {
    @include themify($themes) {
      background-color: themed('backgroundColor');
    }
  }
}

@media (max-width: 992px) {
 .margin-bottom-20 {
    margin-bottom: 20px;
  }
}
@media(max-width:560px){
  .daterangepicker.show-calendar .drp-buttons{
    display: grid;
  }
}