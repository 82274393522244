@import '../stylesheets/theme/theme-variables.scss';
@import '../stylesheets/theme/theme.scss';
.MuiSelect-select.MuiSelect-select {
  padding-left: 3px;
}
.steper-list-wrap {
  justify-content: left;
  // padding: 0px 30px;
  .MuiTab-textColorInherit {
    opacity: 1;
    border: 1px solid #f00;
  }
  .list-div {
    .step {
      width: 45px;
      height: 45px;
      border-radius: 100%;
      font-size: 23px;
      display: flex;
      /*   cursor: pointer; */
      justify-content: center;
      align-items: center;
      @include themify($themes) {
        border: 1px solid themed('step-border-color');
        color: themed('step-icon-color');
        background-color: themed('step-deactive-bg');
      }
      span {
        &::before {
          @include themify($themes) {
            color: themed('backgroundColor');
          }
        }
      }

      img {
        width: 24px;
        max-height: 24px;
      }
    }
  }
  .steps-label {
    position: absolute;
    font-family: 'Manrope', sans-serif;
    bottom: 0;
    text-align: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    min-width: 130px;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  .steps-label-deactive {
    @extend .steps-label;
    font-weight: 600;
    color: rgba(58, 75, 94, 0.5) !important;
    min-width: 130px;
  }
  .devider {
    border-bottom: 1px solid #ebeef2;
    // border-bottom: 2px solid red;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: -1;
    top: 38%;
    right: 0;
  }
  .last-child {
    @extend .devider;
    right: 60px;
    opacity: 0.5;
  }
  .first-child {
    @extend .devider;
    left: 60px;
    opacity: 0.5;
  }
}
.link-label {
  &.dashboard-btn {
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 10;
    border: 0px;
    background: unset;
    font-size: 15px;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    .left-arrow-icon {
      font-size: 18px;
    }
  }
  font-size: 12px;
  color: #565980;
  font-weight: bold;
  font-size: 12px;
  color: #565980;
  font-weight: bold;
  background: transparent;
  border: none;
  .left-arrow-icon {
    font-size: 16px;
  }
}
.steps-wrap {
  .step-header {
    margin-top: 1.5rem;
    button {
      pointer-events: none;
      .list-div {
        pointer-events: visible;
      }
    }
    .MuiTabs-flexContainer {
    justify-content: center;
    }
  }
  .step-header-8 {
    margin-top: 1.5rem;
    button {
      pointer-events: none;
      .list-div {
        pointer-events: visible;
      }
    }
    .MuiTabs-flexContainer {
    justify-content: left;
    }
  }
  .MuiTab-textColorInherit {
    opacity: 1;
    min-width: 130px;
  }
  .Mui-selected {
    .steper-list-wrap {
      .list-div {
        cursor: pointer !important;
        .active {
          cursor: pointer !important;
          @include themify($themes) {
            background-color: themed('select-step-bg-color') !important;
            border: 1px solid themed('backgroundColor');
            box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
          }
          ~ .step-text {
            p {
              font-weight: bold;
              cursor: pointer;
              @include themify($themes) {
                color: themed('backgroundColor');
              }
            }
          }
          span {
            &::before {
              @include themify($themes) {
                color: themed('border-step');
              }
            }
          }
        }
      }
    }
  }
  .steper-list-wrap {
    .list-div {
      .active {
        @include themify($themes) {
          background-color: themed('border-step') !important;
          border: 1px solid themed('step-border');
          box-shadow: 0px 0px 1px 1px themed('combination-theme-color');
        }
        ~ .step-text {
          p {
            color: #3a4b5e;
            font-weight: bold;
          }
        }
        span {
          &::before {
            @include themify($themes) {
              color: themed('backgroundColor');
            }
          }
        }
      }
    }
  }
}

.margin-bottom-2 {
  margin-bottom: 3rem;
}
.quote-container {
  /*  .MuiTabs-flexContainer {
    justify-content: center;
  } */
  .MuiTabs-scrollButtons {
    @include themify($themes) {
      color: themed('backgroundColor') !important;
    }
  }
}
@media (min-width: 600px) {
  .quote-container {
    .MuiTab-root {
      min-width: 130px;
    }
  }
}
@media (max-width: 992px) {
  .steps-wrap {
    .step-header {
      margin-top: 5px;
      .MuiTabs-flexContainer {
        text-align: center;
        display: block;
      }
      button {
        &:first-child {
          padding: 10px;
        }
        &:last-child {
          padding: 10px;
        }
      }
    }
    .step-header-8 {
      margin-top: 5px;
      .MuiTabs-flexContainer {
        text-align: center;
        display: block;
      }
      button {
        &:first-child {
          padding: 10px;
        }
        &:last-child {
          padding: 10px;
        }
      }
    }
  }
  .link-label {
    &.dashboard-btn {
      margin-top: 2em;
      position: unset;
    }
  }
}
@media (max-width:1200px) {
  .steps-wrap {
    .step-header {
      margin-top: 5px;
      .MuiTabs-flexContainer {
        text-align: center;
        display: block;
      }
    }
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .steper-list-wrap {
    .list-div {
      .step {
        font-size: 22px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1500px) {
  .theme-navy-blue,
  .theme-sky-blue,
  .theme-hippo-green,
  .theme-purplish-blue {
    .quote-container {
      max-width: 1140px !important;
      width: 100% !important;
      &.rates-container {
        max-width: 1140px !important;
        width: 100% !important;
        .card-p-0 {
          padding: 0px;
        }
        .container {
          max-width: 100%;
        }
      }
    }
  }
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .link-label {
    &.dashboard-btn {
      position: unset;
      margin-top: 2em;
    }
  }
}
