@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.ml-10 {
  margin-left: 10px;
}
.padding-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.margin-right {
  margin-right: 20px !important;
}
.driverDiv {
  .time-address-year {
    min-width: 100% !important;
    margin-top: 0px;
  }
  .padd-left-0 {
    .year-span {
      font-size: 12px;
      color: #827f7f;
    }
  }
  .que-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3a4b5e;
  }
  .label-accident {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000;
  }
  .option-label {
    .MuiFormControlLabel-label {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: rgba(58, 75, 94, 0.5);
    }
  }
  .mlr-15 {
    margin: 0px 15px;
  }
  .time-address-month {
    min-width: 100% !important;
    margin-top: 0px;
  }

  .time-address {
    position: absolute;
    right: -49px;
    bottom: 0;
  }
  .date_of_birth {
    .DatePickerComponent {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 0px;
      p {
        display: none;
      }
      .MuiInputAdornment-root {
        .MuiIconButton-root {
          @include themify($themes) {
            color: themed('combination-theme-color');
          }
          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }
    .redBorder div::after {
      border-bottom: 2px solid red;
    }
  }
  .labelError {
    label {
      color: rgb(150, 150, 150) !important;
    }
  }
}

.right-side-list {
  position: absolute;
  top: 0;
  right: -9%;
  .right-driver-info-box {
    margin-top: 35px;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 6px;
    .card-padding {
      padding: 16px 0px 0px 0px !important;
    }
    .disply-label {
      display: grid;
    }
    .right-driver-info-box-label {
      font-family: 'Manrope', sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #31355c;
      padding: 0px 15px 10px 15px;
    }
    .list-wrap {
      min-height: 60px;
      display: flex;
      height: auto;
      border-top: 2px solid #d9d9d9;
    }
    .active-container {
      @include themify($themes) {
        background-color: themed('quote-bg-color') !important;
      }
      p {
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
    }
    .list-container {
      p {
        color: #000000;
      }
    }
    .list-label {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding-left: 8px;
      .sub-label {
        font-size: 10px;
      }
    }
    .verified-user-icon {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }

    .auto-edit {
      cursor: pointer;
    }

    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: top;
      cursor: pointer;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
      .icon-edit {
        font-size: 20px;
      }
      .icon-cross {
        font-size: 25px;
        margin-left: 5px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        @include themify($themes) {
          background-color: themed('backgroundColor') !important;
        }
      }
    }
    .add-link {
      padding-left: 5px;
      cursor: pointer;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      &:hover {
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
      .add-icon-border {
        width: 18px;
        height: 18px;
        @include themify($themes) {
          box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
        }
        border-radius: 50%;
      }
      .link-label {
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
    }
  }

  .Mui-checked {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;
      pointer-events: unset;
    }
  }
}
.driver-switch-modal {
  .MuiDialogTitle-root {
    padding: 5px;
    h2 {
      button {
        padding: 0px;
      }
    }
  }
}
@media (max-width: 992px) {
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }

  .autoline-steps {
    .vehicle-box-shodow {
      width: 100%;
    }
  }
  .right-side-list {
    position: inherit;
    right: 0;
    width: 100%;
    .right-vehicle-info-box {
      width: 100%;
    }
  }
  .driverDiv {
    .padd-left-0 {
      padding-left: 0px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .display-block {
    display: block;
  }
  .autoline-steps {
    .vehicle-box-shodow {
      width: 80%;
    }
  }
  .right-side-list {
    right: -5% !important;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .driverDiv {
    .time-address-year,
    .time-address-month {
      margin-top: 20px;
    }
  }
  .padd-left-0 {
    padding-left: 15px;
    .year-span {
      float: right;
    }
  }
}
.shodow-bx {
  box-shadow: 0px 0px 19px 1px #edf0f5;
  padding: 20px 0px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .margin-right {
    margin-right: 18px !important;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .driverDiv {
    .time-adess-rw {
      .yr-select-div {
        width: 90%;
      }
      .lbl-select-div {
        width: 10%;
        margin-top: 2em;
      }
    }
  }
}

@media (max-width: 479px) {
  .driverDiv {
    .time-adess-rw {
      .yr-select-div {
        width: 85%;
      }
      .lbl-select-div {
        width: 15%;
        margin-top: 2em;
      }
    }
  }
}
