@import "../../../stylesheets/theme/theme";
@import "../../../stylesheets/theme/theme-variables";
.m-16 {
  margin: 16px 0px;
}
.applicant-form {
  margin-top: 25px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $hippo-text-color;

  .applicant-form-title {
    text-align: center;

    .title-2 {
      font-family: "Manrope", sans-serif;
      font-weight: 700;
      text-align: center;
      width: 100%;
      letter-spacing: 0.7px;
      margin-bottom: 0px;
      font-size: 24px;
      margin-top: 70px;
    }

    .sub-title {
      font-size: 23px;
      font-weight: 300;
      line-height: 25px;
      font-family: "Nitti Grotesk SemiLight", sans-serif;
      color: $hippo-subtitle-color;
      margin-bottom: 55px;
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::-moz-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::placeholder {
    color: peachpuff;
    font-size: 16px;
  }

  input,
  #select-state {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    padding-left: 12px;

    &:focus {
      background: none;
    }
  }

  .first_name,
  .middle_name,
  .last_name,
  .date_of_birth,
  .street,
  .unit,
  .city,
  .state,
  .zip,
  .phone_number,
  .email,
  .loan_officer_fname,
  .loan_officer_lname,
  .loan_officer_phone,
  .loan_officer_email,
  .loan_number,
  .loan_title {
    label {
      font-family: "Manrope", sans-serif;
      // font-weight: 600;
    }
    ::-ms-clear {
      display: none;
    }
  }

  .error {
    color: $errorRed;
    font-size: 12px;
    text-align: left;
    margin-left: 10px;
  }

  .personal-details-wrapper {
    .div-display {
      display: inline-block;
    }
    .email {
      div {
        div {
          margin-left: 0px !important;
        }
      }
    }
  }
  .loan-officer-container {
    margin-right: 0px;

    .loan_title {
      text-align: left;
      padding-left: 23px;
    }
    .div-display {
      text-align: left;
    }
  }
  .address-container {
    .row {
      .state {
        .error {
          margin-left: 10px;
        }
      }
      .zip {
        input {
          height: 1.2em;
        }
      }
    }
  }

  .insurance-type {
    width: 100%;
    margin-left: -5px;
    margin-right: 0px;
    padding-right: 0px;
    cursor: pointer;

    .lbl-insu {
      text-align: left;
      font-family: "Manrope", sans-serif;
      font-weight: bold;
      color: #3a4b5e;
      padding: 0;
      font-size: 13px;
      line-height: 1;
      margin-bottom: 10px;
    }

    .lbl-error {
      color: $errorRed;
    }

    .insurance-home {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      vertical-align: top;

      .p-head {
        text-align: left;
        color: $property-type-home-title;
        margin-bottom: 0px;
        font-size: 1.1rem;
      }
      .p-para {
        color: $gray-700;
        text-align: left;
        font-size: 11px;
      }
    }

    .insurance-condo {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      .p-head {
        color: $property-type-condo-title;
        font-size: 1.1rem;
      }
    }

    .insurance-home,
    .insurance-condo {
      border: 1.5px solid;
      border-color: #bdbdbd;
      user-select: none;
      padding: 10px;
      width: 100%;
      padding-top: 20px;
      padding-bottom: 25px;
      &:hover,
      &:focus {
        -moz-box-shadow: 0 0 4px $gray-700;
        -webkit-box-shadow: 0 0 4px $gray-600;
        box-shadow: 0 0 4px $gray-600;
        outline: 0;
      }

      .p-head {
        text-align: left;
        margin-bottom: 0px;
        float: left;
      }
      .p-para {
        color: #3a4b5e;
        text-align: left;
        font-family: "Manrope", sans-serif !important;
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: normal;
        line-height: 19px;
        padding-right: 10px;
        padding-top: 30px;
      }

      .insu-icon-img {
        vertical-align: middle;
        text-align: center;
        padding-right: 0px;
      }
    }
  }

  .h2-tooltip {
    top: 22px;
    position: absolute;
    width: 20px;
    right: -23px;
  }

  .information-icon {
    position: absolute;
    right: 14%;

    @include themify($themes) {
      color: themed("buttonBgColor") !important;
    }
  }
  .tooltip-icon {
    width: 25px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: 18px;

    @include themify($themes) {
      @if (themed("buttonBgColor") == $hippo-green) {
        background-image: url("../../../assets/images/tooltip-opendoor.svg");
      } @else if (themed("buttonBgColor") == $hippo-blue) {
        background-image: url("../../../assets/images/tooltip-firstAmerican.svg");
      } @else {
        background-image: url("../../../assets/images/tooltip-better.svg");
      }
    }
  }

  .classmargin {
    .state-class {
      text-align: left;
      #select-state {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .form-submit-action {
    margin-top: 40px;
    text-align: center;

    .loader-wrapper,
    .loader-wrapper-quote {
      width: 100%;
      position: relative;
      height: 100%;
      top: 1px;
      left: 0px;
      padding-left: 40%;
    }

    .sk-circle {
      width: 30px;
      height: 30px;
      position: relative;
      float: left;
    }
    .sk-circle .sk-child {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
    .sk-circle .sk-child:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 18%;
      height: 18%;
      background-color: white;
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }

    .loader-wrapper-quote {
      .sk-circle .sk-child:before {
        @include themify($themes) {
          background-color: themed("backgroundColor") !important;
        }
      }
    }
    .sk-circle .sk-circle2 {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg);
    }
    .sk-circle .sk-circle3 {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg);
    }
    .sk-circle .sk-circle4 {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .sk-circle .sk-circle5 {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg);
    }
    .sk-circle .sk-circle6 {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg);
    }
    .sk-circle .sk-circle7 {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .sk-circle .sk-circle8 {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg);
    }
    .sk-circle .sk-circle9 {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg);
    }
    .sk-circle .sk-circle10 {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    .sk-circle .sk-circle11 {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg);
    }
    .sk-circle .sk-circle12 {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg);
    }
    .sk-circle .sk-circle2:before {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }
    .sk-circle .sk-circle3:before {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }
    .sk-circle .sk-circle4:before {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }
    .sk-circle .sk-circle5:before {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }
    .sk-circle .sk-circle6:before {
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    .sk-circle .sk-circle7:before {
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    .sk-circle .sk-circle8:before {
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    .sk-circle .sk-circle9:before {
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    .sk-circle .sk-circle10:before {
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
    .sk-circle .sk-circle11:before {
      -webkit-animation-delay: -0.2s;
      animation-delay: -0.2s;
    }
    .sk-circle .sk-circle12:before {
      -webkit-animation-delay: -0.1s;
      animation-delay: -0.1s;
    }

    @-webkit-keyframes sk-circleBounceDelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }

    @keyframes sk-circleBounceDelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }
      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }

  .date_of_birth {
    .DatePickerComponent {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 0px;
      p {
        display: none;
      }
      .MuiInputAdornment-root{
        .MuiIconButton-root{
          @include themify($themes) {
            color: themed('combination-theme-color');
          }
          &.Mui-disabled{
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }
    .redBorder div::after {
      border-bottom: 2px solid red;
    }
  }

  .btn-success {
    font-size: 0.875rem;
    margin: 10px 10px;
    width: 150px;
    height: 45px;
    border-radius: 25px;
    border: none;
    text-transform: uppercase;
    box-shadow: 0 4px 0 0 var(--button-box-side-color),
      0 calc(4px * 2) calc(4px / 2) 0 rgba(0, 0, 0, 0.08) !important;
    letter-spacing: 0.02857em;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    @include themify($themes) {
      background-color: themed("buttonBgColor") !important;
    }
    &:focus {
      @include themify($themes) {
        background-color: themed("buttonBgDarkColor") !important;
      }
    }
  }
  .btnGetQuoteLink {
    font-size: 0.875rem;
    max-width: 150px;
    width: 100%;
    height: 45px;
    text-transform: capitalize;
    border-radius: 25px;
    box-shadow: 0 4px 0 0 var(--button-box-side-color),
      0 calc(4px * 2) calc(4px / 2) 0 rgba(0, 0, 0, 0.08) !important;
    letter-spacing: 0.02857em;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    background-color: white;
    @include themify($themes) {
      color: themed("backgroundColor") !important;
      border: 1px solid themed("backgroundColor") !important;
    }
    margin: 10px 10px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-100 {
    width: 100%;
  }
}
