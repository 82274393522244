@import '../../../stylesheets/theme/theme';
footer {
  .footer-wrapper {
    position: relative;
    user-select: none;
    right: 0;

    left: 0;
    bottom: 0;
    padding: 1rem;

    text-align: center;

    p {
      font-size: 12px;
      color: $gray-600;
      margin-bottom: 0px;
      margin-top: 5em;
    }

    a {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
}

@media (max-width: 450px) {
  footer {
    .footer-wrapper {
      p {
        margin-top: 12em;
      }
    }
  }
}
