@import '../../../stylesheets/theme/theme-variables';
@import '../../../stylesheets/theme/theme.scss';
// global css
.notification-success,
.notification-error {
  opacity: 1 !important;
  .message {
    font-size: 14px !important;
    font-family: 'Manrope', sans-serif;
  }
}
.form-label {
  label {
    margin: 0px;
  }
}
// end
.add-new-org-model {
  input,
  select {
    border-bottom: 1px solid #000;
  }
  .margin-bottom-35 {
    margin-bottom: 35px;
  }
  .MuiFormLabel-root {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: #000;
    text-transform: capitalize;
    opacity: 1;
  }
  .form {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    &-item {
      width: 100%;
      margin-right: 0px;
      margin-left: 0px;
      &-item {
        width: 100%;
        height: 33px;
        .MuiInputBase-input {
          font-size: inherit;
          text-align: left;
          // padding: 0.6em 0 0.6em 0.6em;
        }
        &__input {
          background: #ffffff;
          border-radius: 6px;
          margin-left: 0px !important;
          width: 100%;
          height: 33px;
          .MuiInputBase-input {
            font-size: 0.8em;
            text-align: left;
            // padding: 0.6em 0 0.6em 0.6em;
          }
        }

        &__select {
          .select__item {
            cursor: pointer;
          }
          .MuiNativeSelect-icon {
            @include themify($themes) {
              color: themed('backgroundColor');
            }
          }
        }
      }
    }
  }
  .tooltip-que-icon {
    position: absolute;
    top: -4px;
    right: -4px;
    .tooltip {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
  .radio-button-label{
    display: flex;
    width: 100%;
    .MuiFormControlLabel-label{
      flex-grow: 1;
    }
  }
  .heap-radio-label {
    @extend .radio-button-label;
    width: 65%;
  }
  .title-heading {
    font-size: 18px;
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
  }
  .product-select {
    .MuiInput-underline {
      &::before {
        border-bottom-color: #000;
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .add-new-org-model {
    .switch-label {
      width: 100%;
      label {
        width: 100%;
        .MuiFormControlLabel-label {
          width: 100%;
        }
      }
    }
    .content-class{
      justify-content: flex-end;
    }
  }
}
@media (max-width: 991px) {
  .add-new-org-model {
    .margin-bottom-35 {
      margin-bottom: 20px;
    }
  }
}
