@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.home-edit-sidebar {
  font-family: 'Roboto', sans-serif;

  .sidebar-wrapper {
    width: 270px;
    color: $black;
    -webkit-transition: left 0.5s ease;
    -moz-transition: left 0.5s ease;
    transition: left 0.5s ease;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    background: #f8fbfa;
    height: 100%;
    word-break: break-all;

    ul {
      padding-inline-start: 20px !important;

      li {
        list-style-type: none;
        font-weight: 400 !important;
        font-size: 14px !important;
        margin: 0 0 20px 0;
        line-height: 1.6;

        .sidebar-icons {
          position: relative;
          float: left;
          margin: 2px 12px 5px 0px;
          width: 20px;
          height: 23px;
        }

        .material-icon {
          width: 30px;
          height: 25px;
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
          margin-right: 2px;
          left: -5px;
        }

        .text-bold {
          font-size: 16px;
          font-weight: 500;
        }

        .personal-details {
          font-size: 14px;
          color: #758290;
          font-weight: 400;
          span {
            padding-left: 37px;
          }
        }

        .address-lines,
        .email-address,
        .applicant-name {
          padding-left: 37px;
        }

        .sub-title {
          font-size: 14px;
          font-weight: 500;
          color: #3a4b5f;
          svg {
            font-size: 20px;
            color: #969696;
          }
        }
      }
    }

    .edit-icon {
      float: right;
      font-size: 16px;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
      margin-right: 15px;
      cursor: pointer;
    }

    .text-align-right {
      text-align: right;
    }

    .divider {
      width: 100%;
      border-top: 1px solid #80808047;
      margin: 0;
    }

    .deductibles-table {
      width: 85%;
      margin: 20px;
      .deductible-font {
        font-size: 20px;
        font-weight: 500;
        color: #3a4b5f;
      }

      th,
      td {
        padding: 6px;
      }

      th {
        font-size: 16px;
        font-weight: 600;
      }

      td {
        font-size: 14px;
        font-weight: 400;
      }

      .text-weight {
        font-weight: 500 !important;
      }

      .highlight-text {
        @include themify($themes) {
          color: themed('highlight-text-color') !important;
        }
      }
    }

    .applicant-details-container {
      padding: 15px 20px 25px 5px;
      .applicant-heading {
        margin: 10px 20px 20px;
        font-weight: 500;
        font-size: 20px;
        color: #3a4b5f;
      }
    }

    .vehicle-icon {
      width: 20px;
      position: relative;
      top: 24px;
      left: 17px;
    }

    hr {
      margin-left: 20px;
      margin-right: 20px;
      border-top: 2px solid $white;
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 991px) {
  .home-edit-sidebar {
    .sidebar-wrapper {
      max-width: 200px;
      width: 100%;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .home-edit-sidebar {
    .sidebar-wrapper {
      min-width: 284px;
      .vehicle-icon {
        left: 20px;
      }
      ul {
        padding-left: 20px !important;
        .vehicle-li {
          padding-left: 20px;
        }
      }
    }
  }
}

@supports (-ms-ime-align: auto) {
  .home-edit-sidebar {
    .sidebar-wrapper {
      min-width: 284px;
      .vehicle-icon {
        left: 20px;
      }
      ul {
        .vehicle-li {
          padding-left: 20px;
        }
      }
    }
  }
}
