.graph-spinner-wrapper {
  .graph-container {
    .spinner {
      transform: rotate(90deg);
      margin: 0 auto;
      width: 75px;
      height: 50px;
      text-align: center;
      font-size: 10px;
    }

    .spinner > div {
      background-color: #e9ecef;
      height: 100%;
      width: 10px;
      display: inline-block;
      margin: 2px;
      -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .md {
      width: 75px;
      height: 65px;
    }

    .sm {
      width: 75px;
      height: 48px;

      & > div {
        width: 6px;
      }
    }

    .spinner .rect2 {
      -webkit-animation-delay: -1.1s;
      animation-delay: -1.1s;
    }

    .spinner .rect3 {
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
    }

    .spinner .rect4 {
      -webkit-animation-delay: -0.9s;
      animation-delay: -0.9s;
    }

    .spinner .rect5 {
      -webkit-animation-delay: -0.8s;
      animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
      0%,
      40%,
      100% {
        -webkit-transform: scaleY(0.4);
      }
      20% {
        -webkit-transform: scaleY(1);
      }
    }

    @keyframes sk-stretchdelay {
      0%,
      40%,
      100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
      }
      20% {
        transform: scaleY(1);
        -webkit-transform: scaleY(1);
      }
    }
  }
}
