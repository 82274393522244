@mixin font-face($style-name, $file) {
  $filepath: '../../assets/fonts/' + $file;
  @font-face {
    font-family: '#{$style-name}';
    src: url($filepath + '.eot');
    src: url($filepath + '.eot?#iefix') format('embedded-opentype'),
      url($filepath + '.woff') format('woff'), url($filepath + '.ttf') format('truetype'),
      url($filepath + '.svg#' + $style-name + '') format('svg');
  }
}

@mixin hippo-font-face($style-name, $file) {
  $filepath: '../../assets/fonts/' + $file;
  @font-face {
    font-family: '#{$style-name}';
    src: url($filepath + '.eot');
    src: url($filepath + '.eot?#iefix') format('embedded-opentype'),
      url($filepath + '.woff2') format('woff'), url($filepath + '.ttf') format('truetype'),
      url($filepath + '.svg#' + $style-name + '') format('svg');
  }
}
