.minh-260 {
  min-height: 120px;
}
.org-acc-select {
  .org-select__control {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    border-color: #7c7979 !important;
    &:hover {
      border-bottom: 2px solid #000000de !important;
      box-shadow: none;
      transition: all 0.1s ease-in;
    }
  }
  .org-select__control--is-focused {
    box-shadow: none;
  }
  .org-select__single-value {
    font-weight: 500;
  }
  .org-select__option {
    padding: 0;
    padding-left: 5px;
    cursor: pointer;
  }
  .org-select__option--is-selected {
    background-color: #00cf83;
    color: white;
    cursor: pointer;
  }
  .org-select__menu {
    border-radius: 0px;
    margin: 0;
    border: 1px solid #00cf83;
  }
  .org-select__menu-list {
    max-height: 75px;
    padding: 0;
  }
  .org-select__value-container {
    padding: 0;
    cursor: text;
  }
  .org-select__group {
    padding: 0;
  }
  .org-select__group-heading {
    margin: 0;
  }
  .org-select__control--is-disabled {
    background-color: transparent;
  }
  .org-select__indicator-separator {
    display: none;
  }
  .org-select__dropdown-indicator {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0, 0, 0, 0.54);
  }
  .org-select__indicator {
    padding: 0px;
    position: absolute;
    right: 6px;
    svg {
      display: none;
    }
  }
  .org-select__input {
    input {
      max-width: 225px;
    }
  }
}
