@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.stepsideBarDiv {
  padding-top: 30px;
  padding-bottom: 30px;
  .sidebarIconLbl {
    padding: 20px 0px;

    .iconImg {
      text-align: center;
    }

    .step-icon {
      &.active {
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }

      &.disabled {
        opacity: 0.4;
      }
    }

    .StepLbl {
      color: #3c3636;
      font-weight: 500;
      border: none;
      padding: 15px 0px;
      text-align: center;

      &.disabled {
        opacity: 0.4;
      }
    }
  }
  /* sidebar */
  .divided {
    display: flex;
    align-items: center;

    .step {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      @include themify($themes) {
        background: themed('backgroundColor');
      }
      background-size: 32px 25px;
      background-position: center;
      display: inline-block;
      background-repeat: no-repeat;

      .step-img {
        width: 30px;
        height: 30px;
        margin-top: 10px;
        margin-left: 10px;
      }
    }

    .divider-green {
      flex-grow: 1;
      border-bottom: 2px solid;
      @include themify($themes) {
        border-color: themed('backgroundColor');
      }
    }
    .divider-grey {
      flex-grow: 1;
      border-bottom: 2px solid #e0d6d6;
    }
    .vehicle-div,
    .driver-div,
    .details-div,
    .quotes-div {
      .label-name {
        position: absolute;
        bottom: -15px;
        @include themify($themes) {
          color: themed('stepLabelColor');
        }
      }

      &.disabled {
        cursor: default;
        opacity: 0.4;
        .label-name {
          @include themify($themes) {
            color: themed('StepLabelDisableColor');
          }
        }
        .step {
          @include themify($themes) {
            background-color: themed(content-box-color);
          }
          background: white;

          .step-img {
            margin-top: 9px;
            margin-left: 9px;
          }
        }
      }
    }
    .complete {
      opacity: 1;
      font-weight: 500;
    }
    .blank {
      opacity: 50%;
    }
  }
  /* end */
}
@media (min-width: 320px) and (max-width: 767px) {
  .stepsideBarDiv {
    .sidebarIconLbl {
      display: inline-block;
      width: 25%;
      .StepLbl {
        font-size: 14px;
      }
    }
  }
}
