@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.org-container {
  &:hover,
  &:focus {
    cursor: pointer;
    -moz-box-shadow: 0 0 4px $gray-700;
    -webkit-box-shadow: 0 0 4px $gray-600;
    box-shadow: 0 0 4px $gray-600;
    outline: 0;
  }
}
