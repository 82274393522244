@import '../../../../stylesheets/theme/theme';
@import '../../../../stylesheets/theme/theme-variables';

.select-field-form-contron {
    .property-info-default-icon {
        position: absolute;
        top: -6px;
        cursor: default;
        font-size: 22px;

        @include themify($themes) {
            color: themed('buttonBgColor') !important;
        }
    }
}
