@import '../../../../stylesheets/theme/theme';
@import '../../../../stylesheets/theme/theme-variables';

.property-info-status-banner-wrapper {
  .property-info-status-banner {
    border-radius: 0px;

    .MuiAlert-message {
      text-align: left;
    }

    .MuiAlert-icon {
      display: flex;
      align-items: center;
    }
    
    .MuiAlert-icon {
      display: flex;
      align-items: center;
    }

    .retry-button {
      background-color: #f44336;
      color: white;
      border: 1px solid white;
      margin-right: 1rem;
    }

    @media (max-width: 599px) {
      display: block !important;
      padding: 0px 6px;
      position: relative;

      .MuiAlert-icon {
        float: left;
        display: inline;
        width: 7%;
        margin-right: 9px;
        position: absolute;
        left: 5px;
        top: 50%;
        padding: 0px;

        svg {
          font-size: 18px;
          margin-top: -40px;
        }
      }

      .MuiAlert-message {
        font-size: 13px;
        margin-left: 24px;
        width: 88%;
        float: left;
      }

      .MuiAlert-action {
        width: 100%;
        flex-direction: row-reverse;

        .retry-button {
          font-size: 10px;
          margin-right: 10px;
          margin-bottom: 9px;
        }
      }
    }
  }
}
