@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.petDetails-wrapper {
  .MuiRadio-colorSecondary.Mui-checked{
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
      cursor: default;
      pointer-events: unset;
    }
  }
  .mb-6 {
    margin-bottom: 3em;
  }
  .pet-heading {
    font-size: 16px;
    font-weight: 700;
  }
  .delete-icon {
    cursor: pointer;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
    }
  }
  .radio-wrap {
    legend {
      font-size: 12px;
      text-align: left;
    }
    .radio-label {
      padding-left: 12%;
    }
    .pet-radio {
     
      label {
        margin: 0 auto;
        padding: 0px;
        .MuiRadio-root {
          padding: 0px;
          
        }
      
        .MuiIconButton-colorSecondary:hover {
          background-color: transparent;
          padding: 0px;
        }
        .MuiFormControlLabel-label {
          margin-left: 10px;
        }
      }
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: rgba(0, 0, 0, 0.6);
  }
  .Add-another-btn {
    width: 100%;
    max-width: 255px;
    height: 40px;
    border: none;
    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
    }
    color: #fff;
    margin-bottom: 60px;
    font-weight: 700;
    border-radius: 30px;
  }
  .MuiFormControl-root {
    .MuiFormHelperText-root {
      color: #f44336;
      font-size: 12px;
      text-align: left;
    }
  }
  .red-star-20 {
    font-size: 20px;
    vertical-align: sub;
    color: #f44336;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .petDetails-wrapper {
    .radio-wrap {
      .pet-radio {
        label {
          width: 50%;
        }
      }
      .radio-label {
        padding-left: 0px;
      }
    }
  }
}
@media (max-width: 360px) {
  .petDetails-wrapper {
    .Add-another-btn {
      font-size: 14px;
      svg {
        display: none;
      }
    }
  }
}
@media (max-width: 576px) {
  .petDetails-wrapper {
    .position-sm {
      position: absolute;
    }
  }
}
