@import '../../../../stylesheets/theme/theme';
@import '../../../../stylesheets/theme/theme-variables';

.umbrellaDetails-wrapper {
    label + .MuiInput-formControl,
    .MuiFormControl-marginNormal {
        margin-top: 0px;
    }
}
.umbrell-wraper {
    padding: 20px;
    .p-20 {
        padding: 20px;
    }
    .light-bg {
        background-color: #F9FBFC;
        padding: 20px;
        margin-top: 30px;
        margin-left: -20px;
        margin-right: -20px;
    }
    .white-bg {
        background-color: #fff;
         padding-top: 20px;
    }
    .question-group-label {
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 20px;
        @include themify($themes) {
            color: themed('backgroundColor');
        }
        @media(max-width:768px) {
            margin-top: 20px;
        }
    }
    .white-bg-spacing {
        padding-left: 0;
    }
    .light-bg-spacing {
        margin-left: 0px;
        padding-left: 0;
    }
    .primary-member-div {
        p {
            font-family: 'Manrope';
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #303030;
            padding-bottom: 24px;
            text-align: left;
        }

        @include themify($themes) {
            border-bottom: 1px solid themed('member-border-bottom');
        }

        margin-bottom: 20px;
      
    }

    .question-sub-group-label {
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: gray;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .delete-icon {
        margin-top: 4px;
        font-size: 20px;
        .icon-delete1 {
        cursor: pointer;

        @include themify($themes) {
            color: themed('backgroundColor') !important;
        }
    }
    }

    .repeatable-delete-row {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .add-member-btn {
        font-family: "Manrope";
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        border: none;
        background: none;
        margin-top: 30px;
        cursor: pointer;
        padding-left: 0px;
        @include themify($themes) {
            color: themed('backgroundColor');
        }
        svg {
            border-radius: 15px;
            color: #fff;
            @include themify($themes) {
                background-color: themed('backgroundColor');
            }
        }
    }
}

.umbrell-button-wrap {
    margin-top: 4rem;
}
@media (max-width:768px) {
    .umbrell-wraper {
        padding: 0px;
        .light-bg {
           
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .umbrellaDetails-wrapper {
        .form-wrapper {
            .mob-padding-0 {
                padding: 0px;
            }
        }
    }
}
