@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.upload-eoi-modal {
  .MuiDialogTitle-root {
    padding: 16px;
  }
  .eoi-heading {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.04em;
    margin-bottom: 8px;
    font-family: 'Manrope', sans-serif !important;
    text-align: center;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  .upload-div {
    border: 2px dashed rgba(0, 0, 0, 0.6);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    .icon {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
    p {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.04em;
      color: rgba(0, 0, 0, 0.6);
    }
    .upload-file-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }
  }
  .cancel-btn {
    background: #ffffff;
    box-shadow: 0px 0px 19px 1px #c9d3e3;
    border-radius: 25px;
    text-transform: capitalize;
    @include themify($themes) {
      color: themed('backgroundColor');
      border: 1px solid themed('backgroundColor');
    }
  }
}
