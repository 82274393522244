@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme.scss';

.result-wrapper {
  width: 100%;
  min-height: 100vh;
  .padding-3 {
    padding: 0px 3px;
  }
  .button-width {
    width: 163px !important;
    min-height: 50px;
  }
  .adjust-row-width {
    margin-left: -135px;
    margin-right: 135px;
    .dashboard-label {
      width: 120px;
      padding-left: 15px;
      font-size: 14px;
      cursor: pointer;

      @include themify($themes) {
        color: themed('backgroundColor');
      }

      span {
        vertical-align: middle;
      }
    }
    .product-listing {
      display: flex;
      justify-content: flex-end;
      color: $gray-500;
      padding-right: 30px;
      p {
        font-size: 14px;
      }
    }
    .dot-home {
      height: 10px;
      width: 10px;
      @include themify($themes) {
        background-color: themed('backgroundColor');
      }
      border-radius: 50%;
      display: inline-block;
    }
    .dot-flood {
      @extend .dot-home;
      @include themify($themes) {
        background-color: themed('floodDotColor');
      }
    }
  }

  .position-relative {
    height: 100%;
    min-height: 100vh;
  }

  .quote-loader {
    height: 155px !important;

    .block-ui-message-container {
      top: 68%;
    }
  }

  .quote-list-container {
    .quote-card {
      margin-bottom: 3rem;
      margin-top: 2rem;
      &:last-of-type {
        .green-div {
          & :after {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
        }
      }
    }
  }
  .btnWrap {
    justify-content: left;
    display: flex;
    margin-left: 15px;

    button {
      background: transparent;
      @include themify($themes) {
        box-shadow: 0 0 14px #e6eaf2 !important;
        color: themed('backgroundColor');
        margin-right: 20px;
        border: 1px solid $white;
        &:hover {
          background-color: #f4fffa;
          border: 1px solid !important;
          @include themify($themes) {
            border-color: themed('content-box-color') !important;
          }
        }
      }
      box-shadow: none;
      width: auto;
      font-size: 0.875rem;
      font-weight: 500;
      max-width: 230px;
      padding: 8px 14px;
      margin-right: 10px;
      text-transform: capitalize;

      .dashboard-icon,
      img {
        width: 34px;
        height: 28px;
        padding-right: 10px;
      }

      .iconCar {
        width: 40px;
        height: 20px;
      }

      .iconHome {
        width: 30px;
        height: 20px;
      }

      .iconFlood {
        width: 32px;
        background-image: url('../../../assets/images/flood-green.svg');
        background-size: contain;
        height: 20px;
        background-repeat: no-repeat;
      }
    }
  }
  .expandCollapseBtn {
    display: block;
    position: relative;
    margin: 0 auto;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    outline: none;
    user-select: none;

    span {
      @include themify($themes) {
        color: themed('backgroundColor');
        border-bottom: 2px solid themed('purchaseByPhone') !important;
      }
      padding-bottom: 2px;
    }
  }

  .quote-list-collapsed {
    display: none !important;
  }

  .quote-list-expanded {
    display: block !important;
  }

  .quote-card {
    svg {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
    .mx-w {
      max-width: 1250px;
    }

    .speech-bubble {
      position: absolute;
      @include themify($themes) {
        background: themed('lowestPriceBgColor');
      }
      border-radius: 0.4em;
      top: -10px;
      padding: 5px;
      width: 140px;
      height: 36px;

      color: $white;

      p {
        margin-bottom: 5px;

        span {
          vertical-align: text-bottom;
        }
      }

      .tick-circle {
        margin-right: 5px;
        vertical-align: text-bottom;
        font-size: 24px;
        color: $white;
      }
    }
    .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 55%;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      @include themify($themes) {
        border-top-color: themed('lowestPriceBgColor');
      }
      border-bottom: 0;
      margin-left: -22px;
      margin-bottom: -14px;
    }
    .qoute-tooltip {
      top: 0;
      display: inline-block;
      right: 50px;
      position: inherit;
      .tooltip-icon {
        width: 12px;
        background-size: contain;
        height: 12px;
        background-repeat: no-repeat;
        cursor: pointer;

        @include themify($themes) {
          @if (themed('buttonBgColor') ==$hippo-green) {
            background-image: url('../../../assets/images/tooltip-opendoor.svg');
          } @else {
            background-image: url('../../../assets/images/tooltip-better.svg');
          }
        }
      }
    }

    .hide-lowest-price {
      display: none !important;
    }

    .card-img-left {
      width: 142px;
      height: 142px;
    }

    .btn {
      border: none;
      font-size: 15px;
      font-weight: 400;
      padding: 15px 25px;
      min-width: 215px;
      width: 35%;
      line-height: 20px;
      @include themify($themes) {
        background-color: themed('purchaseButtonBgColor');
      }
      letter-spacing: 1px;
    }
    .bridge-carrier-btn {
      border: none;
      font-size: 15px;
      font-weight: 400;
      padding: 15px 25px;
      min-width: 215px;
      width: 35%;
      line-height: 20px;
      background-color: #eff2f1;
      color: #757ea3;
      border-radius: 4px;
    }

    .purchase-by-phone {
      @include themify($themes) {
        background-color: themed('purchaseByPhone');
      }
      .purchase-by-phone-white-btn {
        color: #757ea3;
        background-color: #eff2f1;
      }
    }

    .col-img {
      object-fit: cover;
      text-align: center;

      img {
        padding: 10px;
        height: 100%;
        max-height: 125px;
      }
    }

    .ml-30 {
      margin-left: 36px;
    }

    .button-wrap {
      margin: 0 auto;
    }
    .result-col {
      vertical-align: middle;
      color: rgb(150, 150, 150);
      min-width: 150px;
      font-family: 'Manrope', sans-serif;
      .w-13 {
        width: 12%;
        flex: 0 0 12.5%;
      }

      .row {
        margin-left: 0px;
        margin-right: 0px;
      }

      &.row {
        margin-left: 0px;
        margin-right: 0px;
      }

      .card-font {
        float: inherit;
        font-size: 27px;
        font-weight: 100;
        color: $black;
        text-align: left;
      }

      .price-font {
        font-size: 27px;
        font-weight: 500;

        color: #fff;
      }

      .green-div {
        background: $hippo-green;
        color: #bfffe8;
        @include themify($themes) {
          background-color: themed('otherPriceBoxColor');
          display: flex;
          align-items: center;
        }
        & :first-child::after {
          content: '';
          width: 100%;
          position: absolute;
          max-width: 145px;
          height: auto;
          min-height: 154px;
          top: 37px;
          left: 0px;
          z-index: -1;
          @include themify($themes) {
            background-color: themed('content-box-color');
            opacity: 0.4;
          }
        }

        p {
          margin-bottom: 0px;
        }
      }
      .white-div {
        @include themify($themes) {
          background-color: #eff2f1;
          display: flex;
          align-items: center;
        }
        & :first-child:before {
          content: '';
          width: 100%;
          position: absolute;
          max-width: 145px;
          @include themify($themes) {
            background-color: #f8fbfa;
          }
          height: auto;
          min-height: 150px;
          top: 37px;
          left: 0px;
          z-index: -1;
        }

        p {
          margin-bottom: 0px;
        }
        .price-font {
          color: #757ea3;
        }
      }
      .price-div-padding {
        padding: 0px 20px;
      }
      .selected-quote {
        @include themify($themes) {
          background-color: #2789eb;
          border: 2px solid #2568ac;
          border-radius: 4px;
          height: 100%;
          padding: 21px 11px;
        }
        & :first-child:before {
          content: '';
          width: 100%;
          position: absolute;

          @include themify($themes) {
            background-color: #f8fbfa;
            opacity: 0.2;
          }
          height: auto;
          min-height: 150px;
          top: -70px;
          left: 0;
          z-index: -1;
        }

        p {
          margin-bottom: 0px;
        }
        .price-font {
          color: #757ea3;
        }
      }
      .flood-quote-bridge-btn {
        height: 28px;
        margin-top: 6px;
        width: 100%;
        border: none;
        font-size: 11px !important;
        background-color: #2e63a8;
        color: $white;
        text-align: center !important;
        border-radius: 4px;
        font-weight: 400 !important;
      }

      .no-before {
        & ::before {
          opacity: 0 !important;
        }
      }

      .flood-quote-price {
        .price-font {
          line-height: 27px !important;
        }
        .check-mark {
          background-color: rgb(236, 227, 227);
          width: 18px;
          height: 18px;
          position: absolute;
          right: 10px;
          top: 10px;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          background-image: url('../../../assets/images/flood-checked.svg');
          background-position: center;
        }
      }

      .premium-value {
        vertical-align: middle;
      }

      .flood-quote-price {
        background-color: $flood-price-bg !important;
        border: 2px solid #2568ac;
        padding: 12px;
        border-radius: 8px;
        height: 100%;
        .coverage-label {
          margin-bottom: 0px;
        }
        .price-label,
        .flood-exception,
        .premium-price {
          padding-left: 8px;
        }
      }
      .flood-quote-price-not-selected {
        background-color: #d7eafe;
        border: 2px solid #d7eafe;
        padding: 12px 0px 12px 20px;
        border-radius: 8px;
        height: 100%;
        min-height: 135px;
        cursor: pointer;

        .check-mark-white {
          background-color: #fff;
          width: 18px;
          height: 18px;
          position: absolute;
          right: 10px;
          top: 10px;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          background-position: center;
        }

        .price-font {
          font-size: 27px;
          font-weight: 500;
          line-height: 30px;
          color: #2789eb !important;
        }
        .font-bold-600 {
          font-weight: 600;
        }
        .coverage-label {
          margin-bottom: 0px;
        }
        .text-grey {
          color: #4d5e72;
        }
      }
      .font-weight-500 {
        font-weight: 500;
      }
      .flood-premium-term {
        font-size: 16px;
        margin-left: 2px;
      }

      .na-value {
        line-height: 35px;
      }

      .flood-exception {
        color: $white;
        text-align: left;
        font-size: 14px;
      }
      .text-aqua {
        color: #21fcef;
      }
      .last-price {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .quote-lowest-price {
        @include themify($themes) {
          background-color: themed('lowestPriceBoxColor');
        }
      }

      .coverage-label {
        font-size: 14px;
        font-weight: 100;
        white-space: nowrap;
        text-align: left;
      }
    }

    .container-fluid {
      padding-left: 80px;
      padding-right: 80px;
    }
    .fa-info-circle {
      margin-left: 5px;
      @include themify($themes) {
        color: themed('buttonBgColor') !important;
      }
    }
    .sec-insurance-list {
      .row {
        margin-left: 0px;
        margin-right: 0px;
      }
      .bg-blue-div {
        padding: 0px;

        .bg-blue {
          position: absolute;
          background: #c6f5ff;
          padding: 5px 10px;
          width: auto;
          z-index: 1;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          color: #3a96ed;
          min-width: 210px;
          text-align: center;
          font-weight: 500;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .exceptions-container div:not(:first-child) {
            display: none;
          }
          .exception-expand {
            position: absolute;
            top: 6px;
            font-size: 13px;
            right: 26px;
            cursor: pointer;
            letter-spacing: 1px;
          }

          .expand-icon {
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 15px;
            top: 9px;
            right: 6px;
            cursor: pointer;
            background-image: url('../../../assets/images/expand-icon.png');
            background-position: initial;
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
        .exception-hand {
          cursor: pointer;
          text-align: center;
        }
        .expand {
          .exceptions-container div:not(:first-child) {
            display: block;
          }
          .expand-icon {
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 15px;
            top: 9px;
            right: 6px;
            cursor: pointer;
            background-image: url('../../../assets/images/collapse-icon.png');
            background-position: initial;
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
      }
    }
  }

  .quote-card:nth-child(1) {
    .green-div {
      & :before {
        display: none;
      }
    }
  }

  .collapsed-quote-list {
    .quote-card:first-of-type {
      .green-div {
        & ::before {
          content: '';
          width: 100%;
          position: absolute;
          @include themify($themes) {
            background-color: themed('backgroundColor');
            opacity: 0.2;
          }
          height: auto;
          min-height: 70px;
          top: -84px;
          left: 0;
        }
      }
    }
  }

  .wrapper {
    .error {
      color: $red;
      font-size: 12px;
      text-align: left;
      margin-left: 10px;
    }
  }
  .speech-bubble {
    p {
      margin-bottom: 5px;
    }
  }

  .lowest-price-icon {
    background-image: url('../../../assets/images/lowest-price-white.png');
    height: 30px;
    background-position: initial;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    span {
      margin-left: 27px;
      font-size: 13px;
    }
  }
  .mob-price-div {
    display: none;
  }

  .no-quote-available-container {
    font-size: 25px;
    padding: 30px;
    text-align: center;
    color: $gray-700;
  }
}
.center-content {
  display: flex;

  align-items: center;
}
.result-model {
  .modal-header {
    border-bottom: none;
    padding: 0px;
  }
  .modal-body {
    .call-clr {
      margin: 0 auto;
      line-height: 1.4;
    }
    .personal-data {
      margin: 0 auto;
      .first_name,
      .phone_number {
        + div {
          margin: 8px 0px 8px 0px;
        }
      }
    }
    .time_select {
      margin-bottom: 1rem;
      .radio-error {
        color: #dc3545;
        font-size: 12px;
        text-align: left;
        margin-left: 27px;
      }
    }
    .plbl {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.54);
    }

    .radio_btn {
      @include themify($themes) {
        color: themed('buttonBgColor') !important;
        height: 50px;
        width: 50px;
      }
    }
    .modal-title {
      font-family: 'Manrope', sans-serif;
      font-size: 47px;
      font-weight: 400;
      margin-top: 0.5rem !important;
      a {
        @include themify($themes) {
          color: themed('buttonBgColor') !important;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .modal-dialog {
    max-width: 650px;
    margin: 1.75rem auto;
    .modal-content {
      padding: 20px;
      border-radius: 0.8rem;
    }
  }
  .form-submit-action {
    margin-bottom: 70px;
    margin-top: 0px;
  }

  //Second screen of Modal
  .modal-final-screen {
    svg {
      font-size: 50px;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }

    .call-clr {
      color: rgba(0, 0, 0, 0.54);
      font-size: 26px;
      line-height: 1.4;
    }
    .plbl {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.54);
    }
    .btn {
      margin-bottom: 60px;
    }
  }
}
@media (max-width: 1000px) {
  .result-wrapper {
    .quote-card {
      .sec-insurance-list {
        .bg-blue-div {
          .bg-blue {
            .exceptions-container div:not(:first-child) {
              display: none;
            }
            .exception-expand {
              display: none;
            }
            .expand-icon {
              position: absolute;
              display: inline-block;
              width: 15px;
              height: 15px;
              top: 9px;
              right: 6px;
              cursor: pointer;
              background-image: url('../../../assets/images/expand-icon.png');
              background-position: initial; /* Center the image */
              background-repeat: no-repeat; /* Do not repeat the image */
              background-size: 15px 15px;
            }
          }
          .expand {
            .exceptions-container div:not(:first-child) {
              display: block;
            }
            .expand-icon {
              position: absolute;
              display: inline-block;
              width: 15px;
              height: 15px;
              top: 9px;
              right: 6px;
              cursor: pointer;
              background-image: url('../../../assets/images/collapse-icon.png');
              background-position: initial;
              background-repeat: no-repeat;
              background-size: 15px 15px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1180px) {
  .result-wrapper {
    .quote-card {
      .speech-bubble {
        top: -16px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  .result-wrapper {
    .quote-card {
      margin-bottom: 2rem;
      margin-top: 2rem;
      .green-div {
        background: $white !important;
        padding: 0px;
        & :after {
          content: '';
          width: 100%;
          position: absolute;
          background: none !important;
          height: auto;
          min-height: 81px;
          top: 126px;
          left: 0;
        }
        & :before {
          content: '';
          width: 100%;
          position: absolute;
          background: none !important;
          height: auto;
          min-height: 81px;
          top: 126px;
          left: 0;
        }
        .col-md-12 {
          padding-left: 11px;
          padding-right: 11px;
        }
      }
      .col-img {
        padding: 0px;
        flex: 0 0 100%;
        max-width: 100%;
        img {
          position: relative;
          overflow: hidden;
          object-fit: cover;
          float: left;
          max-width: 140px;
        }
      }
      .qoute-tooltip {
        .tooltip-icon {
          width: 20px;
          background-size: contain;
          height: 20px;
          vertical-align: middle;
          background-position: 1px 4px;
          background-repeat: no-repeat;
          margin-top: 7px;
        }
      }
      .sec-insurance-list {
        .offset-lg-2 {
          margin-left: 0%;
        }

        .col-lg-5 {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .speech-bubble {
          display: none;
        }
        .bg-blue-div {
          padding: 0px;
          .bg-blue {
            margin: 0 auto;
            width: 80%;
            max-width: 80%;
            position: relative;
          }
        }

        .fm {
          flex: 100%;
          max-width: 100%;
          border-bottom: 1px solid #efefef;
        }
        .table-row {
          position: relative;
          border-radius: 15px;
          margin: 15px 20px 40px;
          padding: 25px;
          background-color: $white;
        }

        .coverage-label {
          color: $gray-600;
          font-size: 14px;
          min-width: 150px;
          text-align: left;
          float: left;
          line-height: 40px;
        }
        .coverage-label span {
          float: right;
          line-height: 40px;
        }
        .content-box {
          .col-img {
            border-right: 0px;
            text-align: center;
            border: none !important;
          }
        }
      }
      .btn-success {
        height: auto;
      }
      .btn {
        font-size: 13px;
        font-weight: 300;
        padding: 15px 25px;
        min-width: 215px;
        width: 35%;
        line-height: 20px;
      }
      .speech-bubble {
        position: absolute;
        top: 50%;
        padding: 5px;
        width: 140px;
        right: 0;
        height: 36px;
        color: $white;
        p {
          color: $white;
        }
      }
      .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 55%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        @include themify($themes) {
          border-top-color: themed('lowestPriceBgColor');
        }
        border-bottom: 0;
        margin-left: -22px;
        margin-bottom: -14px;
      }
      .result-col {
        padding: 25px;
        .green-div {
          background-color: $white !important;
          margin: 0px;

          .align-self-center {
            padding-right: 16px;
            padding-left: 16px;
          }
          .coverage-label {
            font-weight: 500;
            color: #000000;
            font-size: 15px;
            min-width: 0px;
          }
        }
        .price-font {
          float: right;
          font-size: 18px;
          color: $black;
          font-weight: 500;
          line-height: 40px;
        }
        .price-font-white {
          float: right;
          font-size: 18px;
          color: $white;
          font-weight: 500;
          line-height: 40px;
        }
        .card-font {
          float: right;
          color: $black;
          font-size: 15px;
          font-weight: 300;
          line-height: 40px;
        }
      }

      .mob-price-div {
        position: relative;
        float: right;
        padding: 6px 11px 6px 38px;
        border-radius: 10px;
        font-weight: 400;
        @include themify($themes) {
          color: themed('lowestPriceBgColor');
          border: 1px solid themed('lowestPriceBgColor');
          @if (themed('buttonBgColor') == $hippo-green) {
            background: url('../../../assets/images/lowestprice-mobile-purple.png')
              no-repeat
              5px
              center;
          } @else if (themed('buttonBgColor') == $hippo-blue) {
            background: url('../../../assets/images/lowestprice-mobile-firstamerican.png')
              no-repeat
              5px
              center;
          } @else {
            background: url('../../../assets/images/lowestprice-mobile-purple.png')
              no-repeat
              5px
              center;
          }
        }
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;
        margin-top: 47px;
        span {
          display: block;
          padding-top: 5px;
        }
      }
    }
  }

  .time_select {
    .radio_btn {
      width: 34%;
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .main {
    .header-addressbar {
      .table-right-wrapper {
        padding: 20px;
      }
    }
  }

  .result-col .col-md-2 {
    padding-left: 11px;
    padding-right: 11px;
  }

  .result-wrapper {
    .quote-card {
      margin-bottom: 2rem;
      margin-top: 2rem;
      .ml-30 {
        margin-left: 0px;
      }
      .result-col {
        .green-div {
          background: $white !important;
          background-color: $white !important;
          padding: 0px;
          flex: 100%;
          max-width: 90%;
          margin: 0 auto;
          & :after {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
          & :before {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0 !important;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
          .col-md-12 {
            padding-left: 13px;
            padding-right: 11px;
          }
        }
        padding: 25px;
        .green-div,
        .quote-lowest-price {
          background-color: $white !important;
          width: 50%;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 19px;

          .coverage-label {
            font-weight: 500;
            color: #000000;
            font-size: 15px;
            min-width: 0px;
          }
        }
        .price-font {
          float: right;
          font-size: 18px;
          color: $black;
          font-weight: 500;
          line-height: 40px;
        }
        .card-font {
          float: right;
          color: $black;
          font-size: 15px;
          font-weight: 300;
          line-height: 40px;
        }
      }
      .btn {
        font-size: 13px;
        font-weight: 300;
        padding: 15px 25px;
        min-width: 215px;
        width: 35%;
        line-height: 20px;
      }
      .mx-w {
        max-width: 720px;
      }

      .col-img {
        padding: 0px;
        border: none !important;

        img {
          position: relative;
          overflow: hidden;
          object-fit: cover;
          float: left;
          max-width: 99px;
          margin-left: 10px;
        }
      }
      .qoute-tooltip {
        .tooltip-icon {
          width: 20px;
          background-size: contain;
          height: 20px;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-position: 1px 4px;
          margin-top: 7px;
        }
      }
      .sec-insurance-list {
        .bg-blue-div {
          padding: 0px;
          .bg-blue {
            margin: 0 auto;
            width: 80%;
            font-size: 12px;
            position: relative;
            .expand-icon {
              top: 6px;
            }
          }

          .expand {
            .expand-icon {
              top: 6px;
            }
          }
        }

        .fm {
          flex: 100%;
          max-width: 100%;
          border-bottom: 1px solid #efefef;
        }
        .table-row {
          position: relative;
          border-radius: 15px;
          margin: 15px 20px 40px;
          padding: 25px;
          background-color: $white;
        }

        .coverage-label {
          color: $gray-600;
          font-size: 14px;
          text-align: left;
          float: left;
          font-weight: 100;
          line-height: 40px;
        }
        .coverage-label span {
          float: right;
          line-height: 40px;
        }
        .content-box {
          .col-img {
            border-right: 0px;
            text-align: center;
          }
        }
      }

      .speech-bubble {
        display: none;
        p {
          color: $white;
        }
      }
    }
    .mob-price-div {
      position: relative;
      float: right;
      background-position: 7px center;
      background-repeat: no-repeat;

      @include themify($themes) {
        color: themed('lowestPriceBgColor');
        border: 1px solid themed('lowestPriceBgColor');
        @if (themed('buttonBgColor') == $hippo-green) {
          background: url('../../../assets/images/lowestprice-mobile-purple.png')
            no-repeat
            5px
            center;
        } @else if (themed('buttonBgColor') == $hippo-blue) {
          background: url('../../../assets/images/lowestprice-mobile-firstamerican.png')
            no-repeat
            5px
            center;
        } @else {
          background: url('../../../assets/images/lowestprice-mobile-purple.png')
            no-repeat
            5px
            center;
        }
      }

      padding: 6px 13px 6px 42px;
      border-radius: 10px;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      margin-top: 26px;
      span {
        display: block;
        padding-top: 5px;
      }
    }
    .result-model {
      .modal-body {
        .modal-title {
          font-family: 'Manrope', sans-serif;
          font-size: 32px;
          font-weight: 400;
          margin-top: 0.5rem !important;
        }
      }
    }
  }
}

@media (min-width: 581px) and (max-width: 789px) {
  .result-model {
    .modal-body {
      .time_select {
        .radio-error {
          margin-left: 19px;
        }
        .justify-content-center {
          justify-content: left !important;
        }
        .time-wrap {
          margin: 0px;

          text-align: left;
          width: 33%;
        }
      }
    }
  }
}
@media (max-width: 580px) {
  .result-wrapper {
    .wrapper {
      .radio-error {
        text-align: center;
      }
    }
  }
  .result-model {
    .modal-body {
      .time_select {
        .time-wrap {
          width: 100%;
          clear: both;

          justify-content: center;
        }
        .radio-error {
          color: #dc3545;
          font-size: 12px;
          text-align: center;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .time-wrap {
    width: 33%;
    clear: both;
  }
}
@media (min-width: 767px) {
  .main {
    .table-right-wrapper {
      padding: 10px 15px 10px 15px;
      margin-left: 15px;
    }
  }
  .time-wrap {
    width: 30%;
    clear: both;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .result-wrapper {
    .quote-card {
      .result-col {
        .w-13 {
          width: 10%;
          flex: 0 0 10.666667%;
        }
      }
      .sec-insurance-list {
        .bg-blue-div {
          .bg-blue {
            max-width: 480px;
          }
        }
      }
    }
  }
}
