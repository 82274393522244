@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.date_of_accidents {
  .DatePickerComponent {
    width: 100% !important;
    margin-bottom: 0px;
    margin-top: 0px;
    p {
      display: none;
    }
    .MuiInputAdornment-root{
      .MuiIconButton-root{
        @include themify($themes) {
          color: themed('combination-theme-color');
        }
        &.Mui-disabled{
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }
  }
  .redBorder div::after {
    border-bottom: 2px solid red;
  }
}
.labelError {
  label {
    color: rgb(150, 150, 150) !important;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .w-70 {
    width: 70% !important;
  }
}
