@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.carrier-appointments-wrapper {
  .mb-40 {
    margin-bottom: 30px;
  }
  .new-quote-btn {
    font-family: 'Manrope', sans-serif !important;
  }
  .add-icon-border {
    width: 18px;
    height: 18px;
    @include themify($themes) {
      box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
    }
    border-radius: 50%;
  }
  .searchfield {
    width: 100%;
    display: flex;
    max-width: 302px;
    height: 35px;
    border-radius: 6px;
    border: 1px solid #cddbeb;
    font-family: 'Manrope', sans-serif !important;
    &::placeholder {
      color: #3a4b5e;
      font-size: 16px;
    }
    svg {
      @include themify($themes) {
        color: themed('combination-theme-color') !important;
      }
    }
  }
  .MuiCardContent-root {
    padding-top: 24px !important;
  }
  .new-org-btn {
    max-width: 279px;
    height: 40px;
    border-radius: 30px;
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0 0 14px #e6eaf2;
    text-transform: capitalize;
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
    font-weight: 600;
    font-family: 'Manrope', sans-serif !important;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
    }
    position: absolute;
    right: 0;
    top: 0px;
    &:hover {
      border: 2px solid;
      background-color: #fff;
      @include themify($themes) {
        border-color: themed('buttonHoverBorder');
      }
    }
  }
  .carrier-apooinment-table {
    .oganization-logo {
      width: 60px;
      height: 40px;
      object-fit: cover;
    }
    table {
      th {
        padding: 6px 19px;
        font-weight: 700 !important;
        font-family: 'Manrope', sans-serif;
        vertical-align: initial;
        text-align: center;
        &:last-child {
          svg {
            display: none;
          }
        }
        > span{
          word-wrap: break-word;
          hyphens: auto;
          white-space: normal;
          text-align: center;
          line-height: 16px;
          display: flex;
          justify-content: center;
        }
      }
      td {
        padding: 6px !important;
        text-align: center;
        .api-password-div {
          input {
            text-align: center;
            padding-top: 14px;
          }
        }
      }
    }
    .edit-svg-tbl {
      font-size: 18px;
    }
    .MuiPaper-root {
      box-shadow: none;
    }
    .MuiTableCell-body {
      div {
        justify-content: center;
      }
    }
    .edit-svg {
      font-size: 18px;
    }
    .text-color {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
    
    .MuiFormControl-root {
      position: absolute;
      left: 0;
      top: 0px;
    }
    .MuiToolbar-root {
      div {
        overflow: inherit;
        padding-left: 7px;
      }
    }
    .MuiInput-underline{
      &::before{
      height: 30px;
      border: 1px solid #cddbeb;
      border-radius: 6px;
      }
      &::after{
        border-bottom: none;
      }
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
      border-bottom: 2px solid #cddbeb;
    }
    tbody {
      .MuiTableCell-root {
        padding: 5px 16px;
        border-bottom: none;
        font-size: 14px !important;
      }
      .MuiTableRow-root {
        &:nth-of-type(odd) {
          @include themify($themes) {
            background-color: themed('table-row-color') !important;
          }
        }
      }
    }
    thead {
      border-bottom: 3px solid #808080a1;
    }

    .logo {
      width: 59px;
      height: 22px;
    }

    margin-top: 1em;
    font-family: 'Manrope', sans-serif !important;
    .MuiTableFooter-root {
      .MuiTableCell-root {
        border-bottom: none;
      }
    }
  }
  .api-password-div {
    div {
      svg {
        margin-left: 4px;
        font-size: 16px;
        cursor: pointer;
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
    }
  }
}

.api-password-form-control {
  .eye-icon {
    svg {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
  }
}
@media (max-width: 767px) {
  .carrier-appointments-wrapper {
    .carrier-apooinment-table {
      .MuiFormControl-root {
        position: absolute;
        left: 0;
        top: -40px;
      }
      .MuiFormControl-root{
        width: 100%;
      }
      table {
        th {
          &:last-child {
            > span {
              &:last-child {
                display: block !important;
              }
            }
          }
        }
        
      }
    }
    .new-org-btn {
      margin-right: 0px;
      top: 10px;
      width: 100%;
      margin: 0 auto;
      max-width: inherit;
    }
  }
}
@media (max-width: 991px) {
  .carrier-appointments-wrapper {
    .carrier-apooinment-table {
      table {
          th {
            font-size: 12px !important;
            text-align: center;
            padding: 6px;
          }
        
      }
    }
  }
}
