@import '../../../stylesheets/theme/theme.scss';
@import '../../../stylesheets/theme/theme-variables';
.spinner {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.autoline-steps {
  .vehicle-box-shodow {
    width: 90%;
    margin: 35px auto 0px auto;
    border: 1px solid #c4c4c4;
    box-shadow: 0px 1px 6px #0000004d;
    padding-bottom: 40px;
  }
}
.right-side-list {
  position: absolute;
  top: 0;
  right: -9%;
  .right-vehicle-info-box {
    margin-top: 35px;
    background: #ffffff;
    border: 2px solid #d9d9d9;
    border-radius: 6px;
    .card-padding {
      padding: 16px 0px 0px 0px !important;
    }
    .right-vehicle-info-box-label {
      font-family: 'Manrope', sans-serif;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: #31355c;
      padding: 0px 15px 10px 15px;
    }
    .list-wrap {
      min-height: 60px;
      display: flex;
      height: auto;
      border-top: 2px solid #d9d9d9;
    }
    .active-container {
      @include themify($themes) {
        background-color: themed('quote-bg-color') !important;
      }
      p {
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
    }
    .list-container {
      p {
        color: #000000;
      }
    }
    .list-label {
      font-weight: bold;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      padding-left: 8px;
    }

    .auto-edit {
      cursor: pointer;
    }

    .icons {
      display: flex;
      justify-content: flex-end;
      align-items: top;
      cursor: pointer;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
      .icon-edit {
        font-size: 20px;
      }
      .icon-cross {
        font-size: 25px;
        margin-left: 5px;
      }
      .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        @include themify($themes) {
          background-color: themed('backgroundColor') !important;
        }
      }
    }
    .add-link {
      padding-left: 5px;
      cursor: pointer;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      &:hover {
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
      .add-icon-border {
        width: 18px;
        height: 18px;
        @include themify($themes) {
          box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
        }
        border-radius: 50%;
      }
      .link-label {
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        color: #000000;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1300px) {
  .error-ht{
    height: inherit !important;
  }
  .display-block {
    display: block;
  }
  .autoline-steps {
    .vehicle-box-shodow {
      width: 80%;
    }
  }
  .right-side-list {
    right: -5% !important;
  }
}
@media (max-width: 992px) {
  .order-1 {
    order: 1;
  }
  .order-2 {
    order: 2;
  }

  .autoline-steps {
    .vehicle-box-shodow {
      width: 100%;
    }
  }
  .right-side-list {
    position: inherit;
    right: 0;
    width: 100%;
    .right-vehicle-info-box {
      width: 100%;
    }
  }
}
@media (max-width: 767px) {
  .autoline-steps {
    .vin-wrapper {
      margin: 0px;
    }
  }
  .right-vehicle-info-box {
    width: 74%;
  }
}
