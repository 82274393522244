@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme';

.error-page-wrapper,
.error-container {
  max-width: 1250px;
  margin: auto;
  display: block;
  font-family: 'Manrope', sans-serif;

  .btnWrap {
    margin-top: 50px;
    .other-quotes-button {
      &:hover {
        border: 1px solid !important;
        @include themify($themes) {
          border-color: themed('content-box-color') !important;
        }
        background-color: #f4fffa;
      }
    }

    button {
      background: 0% 0% no-repeat padding-box;
      box-shadow: 0 0 14px #e6eaf2;
      text-transform: capitalize;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      border: none;
      width: 163px;
      min-height: 50px;
      margin-right: 20px;

      &:hover {
        border: 1px solid;
        @include themify($themes) {
          border-color: themes('content-box-color');
        }
        background-color: #f4fffa;
      }

      .dashboard-icon,
      img {
        width: 34px;
        height: 28px;
        padding-right: 10px;
      }

      .iconCar {
        width: 40px;
        background-size: contain;
        height: 20px;
        background-repeat: no-repeat;
      }
    }
  }
}
.sec-insurance-list {
  .margin-top-5 {
    margin-top: 5em;
  }
  .content-box {
    .not-found-error {
      font-size: 3em;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
  }
}

.error-div {
  h5 {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    text-align: center;
    margin: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 19px;
    margin-left: 15px;
    line-height: 35px;
  }
}

.sec-insurance-list {
  .hippo-context-box {
    background-color: #f3fff9 !important;
    border: 2px solid !important;
    @include themify($themes) {
      border-color: themed('content-box-color') !important;
    }
    border-radius: 4px;
  }
  .other-context-box {
    background-color: #ffffff;
    border: 2px solid #dde6f1;
    border-radius: 4px;
  }
  .content-box {
    background-color: $white;
    border: 2px solid #dde6f1;
    border-radius: 4px;
    padding: 10px;

    .p-call {
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      text-align: center;
      @include themify($themes) {
        color: themed('backgroundColor');
        span {
          display: inline-block;
        }
      }
      padding-top: 18px;
      padding-left: 0;
      padding-right: 0px;

      a {
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
    }

    .col-img {
      text-align: center;
      .error-img {
        height: 100px;
        object-fit: contain;
      }
    }

    .col-p {
      font-family: 'Manrope', sans-serif;
      font-weight: 300;
      margin-bottom: 0px;
    }
    .col-e {
      font-family: 'Manrope', sans-serif;
      font-weight: 300;
      padding-left: 0px;
      padding-top: 18px;
      margin-bottom: 0px;
      text-align: center;
    }
    .row {
      .col-md-3 {
        z-index: 1;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sec-insurance-list {
    .content-box {
      .p-call {
        font-size: 14px;
      }
      .col-p,
      .col-e {
        font-size: 14px;
      }
      .row {
        .col-md-3 {
          z-index: 1;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .sec-insurance-list {
    .content-box {
      .p-call {
        font-size: 14px;
      }
      .col-p,
      .col-e {
        font-size: 14px;
        padding-top: 0px;
      }
      .col-img {
        .error-img {
          height: 100px;
          object-fit: contain;
          width: 50%;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .error-page-wrapper,
  .error-container {
    height: calc(100vh - 172px);
  }
}
