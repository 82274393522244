@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme.scss';
.origin-label {
  display: flex;
  align-items: center;

  .tooltip-container {
    height: 20px;
    margin-left: 5px;
    .tooltip{
      @include themify($themes){
        color: themed('backgroundColor');
      }
    }
  }
}
.iframe-wrap {
  .padding-6 {
    padding: 6px 0px;
  }
  label {
    font-family: 'Manrope', sans-serif;
    color: #000;
  }
  .status-switch {
    left: -10px;
  }
  .ams-tab-data {
    width: 100%;
  }
  .origin-wrap{
    max-height: 50px;
    min-height: 20px;
    height: auto;
    overflow-y: scroll;
  }
}
.iframe-setting-modal {
  .add-origin-btn{
    border-radius: 25px;
    color: #fff;
    width: 115px;
    text-transform: capitalize;
    @include themify($themes){
      background-color: themed('backgroundColor');
      border: 1px solid themed('backgroundColor');
    }
    &:disabled {
      color: $white;
      @include themify($themes) {
        background-color: themed('backgroundColor');
        border: 1px solid themed('backgroundColor');
      }
      &:disabled {
        color: $white;
        opacity: 0.7;
      }
    }
  }
  .close-icon {
    &:hover {
      cursor: pointer;
    }
  }
}

.ams-tab-data {
  height: auto;
}
@media(max-width:767px) {
  .iframe-setting-tbl {
    .MuiToolbar-regular {
      min-height: 20px;
      padding-left: 0px;
     div{
        &:nth-child(1){
            width: 100%;
        }
      } 
    }
    .MuiFormControl-root{
      margin-top: 13px;
    }
    .MuiInputAdornment-root {
      width: auto !important;
    }
    .new-org-btn {
      margin-right: 0px;
      top: 10px;
      width: 100%;
      margin: 0 auto;
      max-width: inherit;
      margin-bottom: 30px;
      position: relative;
      top: 10px;
      left: 0;
    }
    .new-org-btn-div {
      margin-top: 20px;
      padding-left: 0px !important;
      margin-left: 0px;
    }
    th {
      > span {
        &:last-child {
          display: block !important;
        }
      }
    }
  }
}
@media(max-width:991px) {
  .iframe-wrap {
    .mob-status-wrap {
      .sm-w-50 {
        width: 50%;
      }
      .sm-right-align {
        display:flex;
        justify-content:flex-end
      }
    }
  }
}
