@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme.scss';
.plan-modal {
  .plans-heading {
    font-weight: bold;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #31355c;
  }
  .plan-modal-content {
    padding-bottom: 27px;
    .silver-border {
      border: 3px solid #d6d6d6;
    }
    .gold-border {
      border: 3px solid #d4af37;
    }
    .platinum-border {
      border: 3px solid #aaa9ad;
    }
    .plan-card {
      border-radius: 15px;
      box-shadow: none;
      height: 100%;

      .plan-label {
        font-weight: 700;
        font-size: 18px;
        line-height: 33px;
        padding: 15px 0px;
        font-family: 'Manrope', sans-serif;
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
      .plan-list {
        flex-direction: row;
        display: grid;
        margin-bottom: 10px;
        .plan-price-label {
          color: #31355cbf;
          font-weight: bold;
          font-size: 14px;
          line-height: 27px;
        }
        .plan-price {
          color: #31355c;
          @extend .plan-price-label;
          ul {
            padding-inline-start: inherit;
            margin-bottom: 0;
            li {
              list-style-type: none;
            }
          }
        }
      }
    }
  }
}
