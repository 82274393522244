@import '../../stylesheets/theme/theme-variables.scss';
@import '../../stylesheets/theme/theme.scss';

.report-page-wrapper {
  width: 100%;
  min-height: calc(100vh - 215px);
  max-width: 1300px;

  .global-error {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red;
  }

  .global-error-row {
    min-height: 18px;
  }

  .details-card-wrapper {
    overflow: inherit;

    .field-label {
      color: rgba(0, 0, 0, 0.54);
      font-size: 0.8rem;
      font-family: 'Manrope', sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      margin-bottom: 5px;
    }

    .info-icon {
      cursor: pointer;
      font-size: 20px;
      width: 22px;
      height: 23px;
      margin-left: 3px;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }

  .details-card-wrapper,
  .filter-card-wrapper {
    box-shadow: 0 0 25px #e6eaf2;
    border-radius: 5px;
  }

  .card-title {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;

    &.card-filter-title {
      padding-bottom: 8px;
      border-bottom: 1px solid $gray-300;
    }

    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }

  .MuiFormHelperText-root {
    color: $red;
    height: 0px;
  }

  .filter-list-wrapper {
    max-height: 274px;
    overflow-y: auto;

    .filter-loader {
      margin: 0 auto;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }

    .filter-item-checkbox {
      min-width: 38px;

      .Mui-checked {
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }

      .MuiButtonBase-root {
        &:hover {
          background-color: inherit;
          @include themify($themes) {
            color: themed('backgroundColor');
          }
        }
      }
    }

    .filter-item {
      max-height: 38px;
    }

    .filer-loading {
      max-height: 255px;
      height: 255px;

      .no-filters-msg {
        width: 100%;
        text-align: center;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $hippo-text-color;
        user-select: none;
      }
    }

    .trash-btn {
      svg {
        font-size: 20px;
        color: rgb(224, 129, 129);
      }

      &:hover {
        background-color: inherit;

        svg {
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
        }
      }
    }
  }

  .date-range-container {
    button {
      width: 100%;
      max-width: 291px;
      .d-flex > span {
        width: 100%;
        max-width:inherit;
      }
    }
  }

  .radio-label {
    .MuiTypography-root {
      font-size: 14px;
    }
  }

  .quote-radio {
    &:hover,
    &.Mui-checked {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
    &:hover {
      @include themify($themes) {
        background-color: rgba(themed('backgroundColor'), 0.04);
      }
    }
  }

  .report-submit-btn {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #a2a0a099;
    border-radius: 5px;
    color: $white;
    margin-right: 20px;
    width: 215px;
    margin-top: 10px;
    @include themify($themes) {
      background-color: themed('backgroundColor');
      border: 1px solid themed('backgroundColor');
    }

    &:disabled {
      color: $white;
      opacity: 0.7;
    }
  }
  .save-submit-btn {
    @extend .report-submit-btn;
    width: auto;
    min-width: 300px;
  }
}
@media (max-width: 991px) {
  .report-page-wrapper {
    .date-range-container {
      button {
        width: 100%;
        max-width: inherit;
        .d-flex > span {
          width: 100%;
          max-width: inherit;
        }
      }
    }
    .details-card-wrapper {
      .mob-mb {
        margin-bottom: 20px;
      }
    }
  }
}
@media (max-width: 585px) {
  .report-page-wrapper {
    .report-submit-btn,
    .save-submit-btn {
      width: 100%;
    }
  }
}

