label {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    margin: 0px;
}
.helper-text {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: left;
    color: #000000;
    background: #D4E6F4;
    border-radius: 10px;
    padding: 1rem;
    word-break: break-word;
}
.que-handler {
    .star-error {
        font-size: 20px;
        vertical-align: sub;
        color: red;
        @media (max-width:600px) {
            position: absolute;
            left: -9px;
            top: 0;
        }
    }
}