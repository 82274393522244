@import '../../stylesheets/theme/theme';
@import '../../stylesheets/theme/theme-variables';

//global
.text-red{
  color: red !important;
}
.MuiTooltip-tooltip {
  font-size: 12px !important;
}
.add-icon-border {
  @include themify($themes) {
    box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
  }
  border-radius: 50%;
  }
.text-danger {
  font-size: 13px;
}
.lob-wrapper {
  margin-top: 16px;
  justify-content: center;
  .display-flex {
    display: flex;
  }

  .center-quote-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .MuiButton-root {
    text-transform: capitalize !important;
  }
  .CardWrapper {
    margin-top: 30px;
    .circular-progress {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
  .searchDiv {
    float: left;
    width: 100%;
    border-radius: 4px;

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .search-submit-btn {
      cursor: pointer;
    }
  }

  .btn-green {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0 0 14px #e6eaf2;
    text-transform: capitalize;
    font-family: "Manrope", sans-serif;
    color: #fff;
    font-size: 14px;
    margin-right: 20px;
    width: 163px;
    min-height: 50px;
    border: 2px solid #fff;
    margin-right: 20px;
    @include themify($themes) {
      color: themed('backgroundColor');
      &:hover {
        border: 2px solid;
        background-color: #f4fffa;
        @include themify($themes) {
          border-color: themed('buttonHoverBorder');
        }
      }
    }

    .icon {
      width: 100%;
      max-width: 24px;
      height: 28px;
      margin-right: 10px;
    }
  }

  .SearchIconclr {
    @include themify($themes) {
      color: themed('combination-theme-color');
    }
  }
  .breadcrumWrap {
    margin-top: 10px;
    .breadcrumb {
      background: none;
      .leftArrowicon {
        width: 18px;
        margin-right: 12px;
      }
      .bold {
        font-size: 18px;
        font-weight: 500;

        .content {
          color: #d1d1d1;
          padding-left: 10px;
          padding-right: 10px;
        }
        .spanLast {
          @include themify($themes) {
            color: themed('backgroundColor');
          }
        }
      }
    }

    ul {
      padding-inline-start: 0px;
      li {
        .leftArrowicon {
          width: 18px;
          margin-right: 12px;
        }
      }
    }
  }
  .CardWrapper {
    box-shadow: 0 0 25px #e6eaf2;
    border-radius: 5px;
    overflow: visible;

    .lbl {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
      .iconHome {
        width: 22px;
        vertical-align: bottom;
        height: 25px;
        margin-right: 15px;
      }
    }
  }

  .ams-div {
    padding: 0px;
    padding-top: 0px;
    display: flex;
    margin: 0px 17px;
    justify-content: center;
    .ams-button-text {
      cursor: pointer;
    }
  }
}
@media (max-width: 991px) {
  .lob-wrapper {
    .center-quote-date {
      display: block;
      justify-content: flex-end;
      align-items: center;
    }
    .date-range-container {
      width: 100%;
    }
    .display-flex {
      display: block;
    }
    .btn-green {
      margin-bottom: 20px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
  .other-quotes-container {
    .other-quotes-button {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}
@media(max-width:769px){
  .new-quote-btn{
    width: 100% !important;
    margin-right: 0px !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 991px) {
  .lob-wrapper {
    .searchDiv {
      float: left;
      width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .lob-wrapper {
    .btn-green {
      font-size: 12px;
    }
  }
}
.form-model {
  display: flex !important;;
  justify-content: inherit;
}
.field-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.8rem;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-bottom: 5px;
}