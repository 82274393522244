.flood-details-wrapper {
  width: 100%;
  padding-top: 20px;

  .container {
    height: calc(100vh - 125px);
  }

  .card-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 620px;
    margin-top: 20px;
    box-shadow: 0 0 25px #e6eaf2;
    border-radius: 5px;

    .page-title {
      width: 100%;
      text-align: center;
      font-weight: 600;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  }
}
