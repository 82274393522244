@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.generic-model {
  ul {
    li {
      text-align: left;
    }
  }
  .close-button {
    width: 100px;
    margin-bottom: 20px;
    border-radius: 25px;
    text-transform: capitalize;
    @include themify($themes) {
      border: 1px solid themed('backgroundColor');
      color: themed('backgroundColor');
    }
  }
  h2 {
    font-family: 'Manrope', sans-serif !important;
    font-weight: bold;
  }
  p {
    color: #3a4b5e;
    font-weight: 500;
    font-family: 'Manrope', sans-serif !important;
  }
}