.flood-plan-modal {
    .close-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
    .heading {
        font-family: 'Manrope';
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #31355C;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .flood-plan-content {
        padding-bottom: 50px;

        .flood-plan-tbl-container {
            border: 1px solid #D6D6D6;
            border-radius: 15px;

            .flood-plan-table {
                font-family: 'Manrope';
                font-weight: 700;
                font-size: 24px;
                line-height: 33px;
                color: #2196F3;

                td {
                    padding: 10px;
                    border-bottom: none;
                }

                thead {
                    tr {
                        background-color: none;

                        th {
                            width: 27%;
                            border-bottom: none;
                        }
                    }
                }

                tbody {
                    tr {
                        &:nth-child(odd) {
                            background-color: #F3FBFF;
                        }
                    }
                }

                tr {
                    th {
                        font-family: 'Manrope';
                        font-weight: 700;
                        font-size: 24px;
                        line-height: 33px;
                        text-align: center;
                        color: #2196F3;
                        border-right: 1px solid #D6D6D6;
                    }

                    td {
                        font-family: 'Manrope';
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 27px;
                        text-align: center;
                        color: #31355C;
                        border-right: 1px solid #D6D6D6;

                        &:first-child {
                            text-align: left;
                        }

                        span {
                            font-family: 'Manrope';
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 25px;
                            color: #31355C;
                        }

                        p {
                            margin-left: 18px;
                        }

                    }

                }

                .multiline-row {
                    p {
                        margin-top: 10px;
                    }

                    td {

                        &:nth-child(2),
                        &:nth-child(3),
                        &:last-child {
                            vertical-align: bottom;
                        }
                    }
                }
            }
        }
    }
}
