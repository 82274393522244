@import "../../../stylesheets/theme/theme";
@import "../../../stylesheets/theme/theme-variables";
.m-16 {
  margin: 16px 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.m-8 {
  margin: 8px;
  margin-top: 3em;
}
.mr-10 {
  margin-right: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.classmargin {
  margin: 0 8px !important;
}
.form-wrapper {
  width: 95%;
  max-width: 850px;
  margin: 0 auto;
  // border: 3px solid black;
}
.star-error {
  font-size: 20px;
  vertical-align: sub;
  color: red;
}
.red-star {
  font-size: 15px;
  vertical-align: sub;
  color: red;
}
.small-star-error {
  @extend .star-error;
}
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}
.MuiPickersDay-daySelected:hover {
  @include themify($themes) {
    background-color: themed("backgroundColor");
  }
}
.MuiPickersDay-current,
.MuiPickersYear-yearSelected,
.MuiPickersYear-root:focus {
  @include themify($themes) {
    color: themed("backgroundColor");
  }
}
.MuiPickersDay-daySelected {
  color: #fff !important;
}

.applicant-home-title {
  text-align: center;
  margin: 20px 0px 15px 0px;

  .title-icon {
    font-size: 35px;
    @include themify($themes) {
      color: themed("backgroundColor");
    }
  }

  .title-2 {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
    text-align: center;
    width: 100%;
    word-break: break-word;
    letter-spacing: 0.7px;
    margin-bottom: 0px;
    font-size: 24px;
    @include themify($themes) {
      color: themed("form-heading");
    }
  }

  .sub-title {
    font-size: 23px;
    font-weight: 300;
    line-height: 25px;
    font-family: "Manrope", sans-serif;
    color: $hippo-subtitle-color;
  }
}
.applicant-home-form {
  margin-top: 25px;
  padding-bottom: 20px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $hippo-text-color;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::-moz-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  :-ms-input-placeholder {
    color: peachpuff;
    font-size: 16px;
  }
  ::placeholder {
    color: peachpuff;
    font-size: 16px;
  }

  input,
  #select-state {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  select {
    line-height: 1.2;
    font-family: "Manrope", sans-serif;
    font-weight: 400;
    padding-left: 3px;
    &:focus {
      background: none;
    }
  }

  .first_name,
  .middle_name,
  .last_name,
  .date_of_birth,
  .preferred_deductible,
  .street,
  .unit,
  .city,
  .state,
  .zip,
  .phone_number,
  .email,
  .loan_officer_fname,
  .loan_officer_lname,
  .loan_officer_phone,
  .loan_officer_email,
  .loan_number,
  .loan_title {
    label {
      font-family: "Manrope", sans-serif;
      font-weight: bold;
    }
    ::-ms-clear {
      display: none;
    }
  }

  .error {
    color: $errorRed;
    font-size: 12px;
    text-align: left;
  }

  .homeTypeError {
    padding-left: 15px;
  }

  .personal-details-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 0px;

    .div-display {
      display: inline-block;
    }
    .email {
      div {
        div {
          margin-left: 0px !important;
        }
      }
    }
    .moved-in-past-months-wrapper {
      .moved-in-past-months {
        height: 35px;

        .moved-in-past-text, .radio-group-field-wrapper {
          padding-left: 7px;
          font-weight: 700;
          font-size: 16px;
        }

        @media (max-width: 767px) {
          margin-left: 4px;
          background-color: transparent;
          flex-direction: column;
          align-items: start !important;
          height: 75px;

          .moved-in-past-text {
            width: 112%;
            text-align: left;
            font-size: 15px;
            padding: 5px;
          }

          .radio-group-field-wrapper {
            padding: 5px;
          }
        }
      }
     
    }
  }
  .loan-officer-container {
    margin-right: 0px;
    .row {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .loan_title {
      text-align: left;
      padding-left: 23px;
    }
    .div-display {
      text-align: left;
    }
  }
  .padding-top-20 {
    padding-top: 10px;
  }
  .padding-bottom-20 {
    padding-bottom: 10px;
  }
  .address-container {
    .previous-address-label {
      padding-left: 22px;

      @media (max-width: 767px) {
        label {
          font-size: 15px;
        }
      }
    }

    .row {
      .state {
        .error {
          margin-left: 10px;
        }
      }
      .zip {
        input {
          height: 1.2em;
        }
      }
    }
  }

  .insurance-type {
    width: 100%;
    margin-left: -5px;
    margin-right: 0px;
    padding-right: 0px;
    .lbl-insu {
      text-align: left;
      float: left;
      font-family: "Manrope", sans-serif;
      color: #3a4b5e;
      padding: 0;
      font-size: 13px;
      line-height: 1;
      margin-bottom: 10px;
    }

    .lbl-error {
      color: $errorRed;
    }

    .insurance-home {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      vertical-align: top;

      .p-head {
        text-align: left;
        color: $property-type-home-title;
        margin-bottom: 0px;
        font-size: 1.1rem;
        font-weight: 500;
      }
      .p-para {
        color: $gray-700;
        text-align: left;
        font-size: 11px;
      }
    }
    .insurance-ho4 {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      .p-head {
        color: #f4749c;
        font-size: 1.1rem;
      }
    }
    .insurance-condo {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      .p-head {
        color: $property-type-condo-title;
        font-size: 1.1rem;
      }
    }
    .insurance-ho5 {
      display: inline-block;
      text-align: left;
      padding: 30px;
      border-radius: 3px;
      .p-head {
        color: #8b54df;
        font-size: 1.1rem;
      }
    }
    .box-shodow-1{
      &:hover,
      &:focus {
        -moz-box-shadow: 0 0 4px $gray-700;
        -webkit-box-shadow: 0 0 4px $gray-600;
        box-shadow: 0 0 4px $gray-600;
        outline: 0;
      }
     }
     .box-shodow-0{
      &:hover,
      &:focus {
        -moz-box-shadow: 0;
        -webkit-box-shadow: 0;
        box-shadow: none;
        outline: 0;
      }
     }
    .insurance-home,
    .insurance-ho5,
    .insurance-ho4,
    .insurance-condo,
    .insurance-dp1,
    .insurance-dp3 {
      border: 1.5px solid #bdbdbd;
      user-select: none;
      padding: 10px;
      width: 100%;
      padding-top: 20px;
      .width-td {
        width: 20%;
      }
      .p-head {
        text-align: left;
        margin-bottom: 0px;
        float: left;
        font-weight: normal;
      }
      .p-para {
        color: rgba(0, 0, 0, 0.54);
        text-align: left;
        margin-bottom: 0px;
        font-family: "Manrope", sans-serif !important;
        font-size: 13px;
        font-weight: normal;
        line-height: 19px;
        padding-right: 10px;
        padding-top: 30px;
      }

      .insu-icon-img {
        vertical-align: middle;
        text-align: center;
        padding-right: 0px;
      }

      .card-container {
        vertical-align: initial;
        padding-left: 10px;
        .check-mark {
          background-color: #f2fdf8;
          width: 18px;
          height: 18px;
          float: right;
          border-radius: 50%;
          border: 1px solid #bdbdbd;
          position: absolute !important;
          right: 14px;
          top: 14px;
        }
        .disable-icon{
          @extend .check-mark;
          border:0;
        }
      }
      &.border-disable{
        @extend .check-mark;
        border: 2.5px solid #ccc;
        .disable-icon{
          background-size: 18px;
          background-image: url("../../../assets/images/checked-disable.svg");
        }
      }
    }

    .active {
      @include themify($themes) {
        border: 1.5px solid themed("buttonBgColor");
      }
      .check-mark {
        background-size: 18px;
        @include themify($themes) {
          @if (themed("buttonBgColor") == $hippo-blue) {
            background-image: url("../../../assets/images/checked-fa.svg");
          } @else if (themed("buttonBgColor") == $hippo-yellow) {
            background-image: url("../../../assets/images/checked-better.svg");
          } @else if (themed("buttonBgColor") == $firstConnect-blue) {
            background-image: url("../../../assets/images/checked-fc.svg");
          } @else if (themed("buttonBgColor") == $pennymac-navyblue) {
            background-image: url("../../../assets/images/checked-navy-blue.svg");
          } @else if (themed("buttonBgColor") == $doma-purplish-blue) {
            background-image: url("../../../assets/images/checked-purplish-blue.svg");
          } @else {
            background-image: url("../../../assets/images/checked.svg");
          }
        }
        border: none !important;
      }
      
     
    }
    
  }

  .h2-tooltip {
    top: 22px;
    position: absolute;
    width: 20px;
    right: -23px;
  }

  .information-icon {
    position: absolute;
    right: 14%;

    @include themify($themes) {
      color: themed("buttonBgColor") !important;
    }
  }
  .tooltip-icon {
    width: 25px;
    height: 19px;
    background-repeat: no-repeat;
    background-size: 18px;

    @include themify($themes) {
      @if (themed("buttonBgColor") == $hippo-green) {
        background-image: url("../../../assets/images/tooltip-opendoor.svg");
      } @else if (themed("buttonBgColor") == $hippo-blue) {
        background-image: url("../../../assets/images/tooltip-firstAmerican.svg");
      } @else {
        background-image: url("../../../assets/images/tooltip-better.svg");
      }
    }
  }

  .classmargin {
    .state-class {
      text-align: left;
      #select-state {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .form-submit-action {
    margin-top: 5em;
    text-align: center;

    .loader-wrapper,
    .loader-wrapper-quote {
      width: 100%;
      position: relative;
      height: 100%;
      top: 1px;
      left: 0px;
      padding-left: 40%;
    }
  }
  .loader-wrapper-quote {
    .sk-circle .sk-child:before {
      @include themify($themes) {
        background-color: themed("backgroundColor") !important;
      }
    }
  }

  .date_of_birth {
    .DatePickerComponent {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 0px;
      p {
        display: none;
      }
      .MuiInputAdornment-root{
        .MuiIconButton-root{
          @include themify($themes) {
            color: themed('combination-theme-color');
          }
          &.Mui-disabled{
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
      button.Mui-disabled{
        color:rgba(0, 0, 0, 0.38);
      }
    }
    .redBorder div::after {
      border-bottom: 2px solid red;
    }
  }
  .btn-back {
    font-size: 0.875rem;
    width: 150px;
    height: 45px;
    border-radius: 50px;
    letter-spacing: 0.02857em;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: capitalize;
    @include themify($themes) {
      border-color: themed("buttonBgColor") !important;
      color: themed("buttonBgColor") !important;
      box-shadow: 0 0 1px 0.2px themed("backgroundColor") !important;
    }
    svg {
      font-size: 14px;
    }
  }

  .btn-success {
    font-size: 0.875rem;
    width: 150px;
    height: 45px;
    border-radius: 25px;
    text-transform: capitalize;
    border: none;
    letter-spacing: 0.02857em;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    line-height: 19px;
    @include themify($themes) {
      background-color: themed("buttonBgColor") !important;
    }
    &:focus {
      @include themify($themes) {
        background-color: themed("buttonBgDarkColor") !important;
        box-shadow: 0 0 0 0.2rem themed("box-shadow-theme") !important;
      }
    }
  }
  .btnGetQuoteLink {
    font-size: 15px;
    width: 190px;
    height: 45px;
    border-radius: 25px;
    box-shadow: 0 4px 0 0 var(--button-box-side-color),
      0 calc(4px * 2) calc(4px / 2) 0 rgba(0, 0, 0, 0.08) !important;
    font-family: "Manrope", sans-serif;
    letter-spacing: 1.65px;
    text-transform: capitalize;
    background-color: white;
    @include themify($themes) {
      color: themed("backgroundColor") !important;
      border: 1px solid themed("backgroundColor") !important;
    }
    margin-left: 20px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-100 {
    width: 100%;
  }
}

@media (min-width: 320px) and (max-width: 560px) {
  .applicant-home-form {
    .insurance-type {
      .insurance-home,
      .insurance-condo,
      .insurance-ho4,
      .insurance-ho5,
      .insurance-dp1,
      .insurance-dp3 {
        .card-container {
          display: inline-block;
          .p-head {
            text-align: center;
            float: none;
            padding-top: 0;
          }
          .check-mark {
            position: relative;
            bottom: 100px;
          }
        }

        td {
          display: inline-block;
        }
        .width-td {
          width: 100%;
        }
        .p-para {
          text-align: center;
          float: none;
          padding-top: 0;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  form .col-md-6 .row {
    margin: 0px;
  }
  .wrapper {
    .personal-details-wrapper {
      margin-bottom: 0px !important;
      margin-top: 0px;
      .first_name,
      .middle_name,
      .last_name,
      .email {
        margin-bottom: 20px;
      }
      .date_of_birth,
      .preferred_deductible,
      .phone_number {
        margin-bottom: 20px;
      }
    }
    .loan-officer-container {
      .loan_officer_fname {
        margin-bottom: 20px;
      }
    }
    .address-container {
      margin-bottom: 0px !important;
      .row {
        margin-bottom: 0px !important;
        margin-top: 0px;
        margin-right: 0px;
        .street,
        .unit,
        .city,
        .state,
        .zip {
          margin-bottom: 20px;
        }
      }
    }
    .Content-formControl-79 {
      &.classmargin {
        margin: 16px 8px 0px 8px;
      }
    }
    .container {
      &.insurance-type {
        width: 100%;
        .col-md-6 {
          padding: 0px 15px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
  .modal-content {
    width: 94%;
    margin: 0 auto;
  }

  .insurance-home,
  .insurance-ho4,
  .insurance-ho5,
  .insurance-condo,
  .insurance-dp1,
  .insurance-dp3 {
    min-height: 100px;
  }
}
@media (max-width: 767px) {
  .m-8 {
    margin-top: 1em;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .form-wrapper {
    > .col-md-6 {
      widows: 70%;
      flex: 0 0 70%;
      max-width: 70%;
    }
    .offset-lg-3 {
      margin-left: 10%;
    }
    label {
      font-weight: normal;
    }
    form .col-md-6 .row {
      margin: 0px;
    }
    .wrapper {
      .personal-details-wrapper {
        margin: 0px;
      }
    }
  }
  .modal-content {
    width: 96%;
    margin: 0 auto;
  }
  .insurance-home,
  .insurance-condo {
    min-height: 110px;
  }
}
