@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.license-modal {
  .cancel-btn-modal {
    padding-right: 10px;
    border-radius: 16px;
    text-transform: capitalize;
    width: 100px;
    border-radius: 25px;
    height: 35px;
    @include themify($themes) {
      border: 1px solid themed('backgroundColor');
      color: themed('backgroundColor');
    }
  }
}
