@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.bridge-modal {
  .continue-btn {
    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
    }
    font-size: 0.875rem;
    width: 151px;
    height: 45px;
    border-radius: 50px;
    border: none;
    letter-spacing: 0.02857em;
    font-family: 'Manrope', sans-serif;
    font-weight: bold;
    line-height: 19px;
    box-shadow: none;
    color: #fff;
    text-transform: capitalize;
    &:hover {
      box-shadow: none;
    }
    span {
      text-transform: inherit;
    }
  }
  .api-error-p {
    p {
      display: grid;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      margin: 2em 0px;
    }
  }
}
