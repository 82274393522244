@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme.scss';

* {
  font-family: 'Manrope', sans-serif;
}

.visibility-icon {
  svg {
    font-size: 18px;
    cursor: pointer;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
    }
  }
}

.MuiSwitch-colorPrimary,
.MuiCheckbox-colorSecondary {
  &.Mui-checked {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  opacity: 0.5;

  @include themify($themes) {
    background-color: themed('backgroundColor') !important;
  }
}

.lob-modal {
  position: relative;
  height: 100%;
  min-height: 280px;
  padding-top: 2px;
  .mb-10 {
    margin-bottom: 10px;
  }

  .margin-b-5 {
    margin-bottom: 5px;
  }
}

.grey-border {
  border: 1px solid #cddbeb !important;
}

.error-wrap {
  input {
    border: 1px solid #cddbeb;
    border-radius: 6px;
    padding: 5px 5px 5px 10px;
    min-height: 22px;
    overflow: auto;
    max-height: 52px;
  }
}

select,
.MuiInputBase-input {
  padding-left: 3px !important;
}

.organization-img {
  width: 128px;
  height: 50px;
  object-fit: contain;
}

.label {
  font-size: 16px;
  font-weight: bold;
}
.org-editor {
  .MuiCardContent-root {
    padding: 20px;
  }
}
.ams-tab-data {
  border-bottom: 1px solid #f7f4f4;
  height: 25px;
}

.normal-font {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.04em;
  color: #3a4b5e;
  word-break: break-word;
  font-weight: normal !important;
}

.back-btn {
  border: 0px;
  background: unset;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;

  @include themify($themes) {
    color: themed('backgroundColor');
  }

  .left-arrow-icon {
    font-size: 24px;
  }
}

.vertical-divider {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0px 20px;
}

.devider-border {
  border-left: 1px solid #eaecf0;
  border-right: 1px solid #eaecf0;
}

.organization-info {
  position: relative;
  margin-right: -15px;
  margin-left: -15px;
  box-shadow: 0px 0px 19px #edf0f5;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__org {
    .info-item {
      display: flex;
      align-items: baseline;
      font-family: 'Manrope', sans-serif;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.04em;
      margin-bottom: 10px;
      color: #000;

      &__text {
        word-break: break-all;
      }

      .w-56 {
        width: 56%;
      }
    }
    .allow-download-quote-label-wrap {
      width: 25%;
      @media (max-width:576px) {
        width: 50%;
      }
      .allow-download-quote-label {
        font-size: 14px;
      }
    }
  }

  .status-button {
    width: 110px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #cddbeb;
    box-shadow: 0px 0px 10px #dae9f4;
    border-radius: 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;

    @include themify($themes) {
      color: themed('backgroundColor');
    }

    &:hover {
      background: white;
    }
  }

  .center-div-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiCardContent-root {
    min-height: 193px;
    height: auto;
  }

  .text-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.padding-left-3 {
  padding-left: 4px !important;
}

.card-edit-svg {
  font-size: 18px;
  cursor: pointer;
  float: right;
}

.edit-svg {
  font-size: 18px;
  position: absolute;
  top: 15px;
  right: 15px;

  span {
    cursor: pointer !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.text-color {
  @include themify($themes) {
    color: themed('backgroundColor') !important;
  }
}

.nav-link {
  font-size: 16px;
  line-height: 22px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 0px;
  color: #1f1f1f;
}

.nav-link.active {
  font-weight: 700;
  font-size: 16px;

  @include themify($themes) {
    color: themed('iconcolor');
  }
}

.nav-item {
  margin-right: 0.5em;

  &:last-of-type {
    margin-right: 0 !important;
  }
}

.tab-content {
  display: contents;

  .card-padding {
    padding: 15px;
  }
}

.tab-container {
  width: 100%;
  border-top-left-radius: 0;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
}

.settings {
  border: 1px solid #e1e4e9;
  border-radius: 5px;
  min-height: 380px;
  font-size: 16px;
  padding: 1em;
  height: 100%;

  &-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    flex-grow: 1;

    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}

.MuiSwitch-track {
  opacity: 0.12;
}

.form {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  font-family: 'Manrope', sans-serif;
  font-size: 16px;

  &-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    &__label {
      font-size: 14px !important;
      font-family: 'Manrope', sans-serif;
      font-weight: 700 !important;
      margin-bottom: 0px;
      line-height: 180%;
      letter-spacing: 0.04em;
    }

    label {
      font-family: 'Manrope', sans-serif;
      color: #000;
    }

    &__input {
      border: 0;
      margin-left: 0px !important;
      border-radius: 4px;
      width: 100%;

      .MuiInputBase-input {
        font-size: inherit;
        text-align: left;
      }
    }

    .MuiSwitch-root {
      width: 56px;
      height: 36px;
    }

    .MuiSwitch-thumb {
      width: 18px;
      height: 17px;
    }

    .col-3 {
      padding: 0px;
      display: flex;
      justify-content: flex-end;
    }

    .col-6 {
      padding: 0px;
      display: flex;
      justify-content: flex-start;
    }
  }

  &-item.text {
    justify-content: flex-start;
  }

  .row {
    margin: 0px;
  }

  .webhook-input,
  .error-text-input {
    border: 1px solid #cddbeb;
    border-radius: 6px;
    width: 100%;
    padding: 5px 5px 5px 10px;
    min-height: 35px;
    overflow: auto;
    max-height: 72px;
  }

  .quote-fetch-input {
    div {
      display: flex;
      justify-content: center;
      width: 60px;
    }

    input {
      width: 100%;
      max-width: 70px;
      text-align: center;
      height: 20px;
      border-radius: 6px;
    }
  }
}

.ams-card-padding {
  padding: 30px;
}

.product-item {
  color: #00cf83;
  border: 1px solid #cddbeb;
  border-radius: 6px;
  width: 100%;
  position: relative;
  height: 100%;
  min-height: 87px;

  &-button-div {
    .icon {
      width: 30px;
      height: 30px;
      margin: 0 auto;
    }
  }

  .product-label {
    @include themify($themes) {
      color: themed('combination-theme-color');
      font-weight: bold;
      line-height: 19px;
    }

    margin-top: 6.2px;
  }

  div {
    display: grid;
  }

  .button-icon {
    position: absolute;
    right: 3px;
    top: 5px;
    margin: 0;

    .MuiCheckbox-root {
      padding: 0px;

      svg {
        font-size: 20px;
      }
    }
  }
}

.product-btn-icon {
  position: relative;
  width: 100%;
  border: 1px solid #cddbeb;

  .button-icon {
    margin: 0;

    .MuiCheckbox-root {
      margin: 0;
      padding: 0px 6px 0px 0px;

      svg {
        font-size: 20px;
      }
    }
  }
}

.product-item.active {
  @include themify($themes) {
    box-shadow: 0 0 0px 1px themed('backgroundColor');
  }
}

.product-item.disabled {
  @include themify($themes) {
    border-color: themed('backgroundColor');
    box-shadow: 0px 0px 10px themed('content-box-color');
  }

  border: 1px solid;
  cursor: default;
  background-color: transparent;
}

.ams-button {
  max-width: 128px;
  width: 100%;
  margin: 0;
  margin-right: 2em;
  border: 0;
  padding: 0.8em;
  box-shadow: 0px 0px 19px 1px #edf0f5;
}

.organization-model-tab {
  .nav-tabs {
    border-bottom: 0px;

    .nav-link.active {
      border: 1px solid #f9f9f9;
      border-bottom: none;
    }
  }
}

.MuiInput-underline.Mui-disabled:before {
  border-bottom: 1px solid !important;
}

.ams-modal-form {
  label {
    font-family: 'Manrope', sans-serif;
    font-weight: 700;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.6);
    text-transform: capitalize;
    opacity: 1;
  }
}

.ams-tab-form {
  .text-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  label {
    font-family: 'Manrope', sans-serif;
    color: #000;
  }

  .col-row-mb {
    .row {
      margin-bottom: 20px;
    }
  }

  .eoi-recipient-row {
    .w-22 {
      width: 20%;
      padding-left: 15px;
    }

    .w-78 {
      width: 78%;
      padding-left: 15px;
    }
  }
}

.ams-modal-form {
  .row {
    margin-bottom: 20px;
  }
}

.label-placement {
  label {
    margin-left: 0px;
  }

  .MuiSwitch-root {
    margin-left: 20px;
  }
}

.crm-card-wrapper {
  height: 100%;
  min-height: 300px;
  padding: 30px;

  .border-bottom-grey {
    border-bottom: 1px solid #f7f4f4;
  }

  .w-30 {
    width: 30%;
  }

  .width-p {
    width: 25%;
  }

  .form-item {
    .native-select {
      margin-left: 0.5rem;

      select {
        color: rgba(0, 0, 0, 0.54) !important;
      }
    }
  }

  .width-25-100 {
    width: 27%;
  }
}

.crm-dialog-wraper {
  overflow: hidden;

  .form-item {
    .native-select {
      select {
        color: rgba(0, 0, 0, 0.87) !important;
      }
    }
  }

  .padding-1 {
    padding: 1px;
  }

  .lead-type-rec-label {
    display: flex;
    align-items: baseline;
  }

  .content-class {
    justify-content: flex-start;
  }

  .small-star-error {
    vertical-align: sub;
  }
}
.sfdc-wrap {
  .select-autocomplete {
    width: 100%;
    .MuiNativeSelect-select:focus {
      width: 100%;
    }
  }
}
@media (max-width: 768px) {
  .admin-step {
    display: contents;

    li {
      width: 100%;
      margin-bottom: 0px !important;
      margin-right: 0px;
      border: 1px solid #e1e4e9;
      margin: 10px 0px;

      .nav-link {
        width: 100%;
        padding: 7px;
      }

      .nav-link.active {
        box-shadow: 0 4px 10px 1px #d4d4d5;

        @include themify($themes) {
          border: 1px solid themed('backgroundColor');
        }
      }
    }
  }

  .mob-wdt-50 {
    width: 50%;
  }

  .sm-right-align {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 576px) {
  .crm-card-wrapper {
    .w-30 {
      width: 100%;
    }
  }

  .form {
    .quote-fetch-input {
      div {
        width: inherit;
      }
    }
  }
}

@media (max-width: 991px) {
  .organization-info {
    .center-div-wrap {
      position: inherit;

      .img-wrap {
        display: flex;
        justify-content: center;
      }

      .padding-bottom {
        padding-bottom: 20px;
      }
    }

    .devider-border {
      border: none;
    }
  }

  .ams-modal-form {

    .mob-style,
    .label-placement {
      margin-bottom: 20px;
    }

    .row {
      margin-bottom: 0px;
    }
  }

  .ams-tab-form {
    .col-row-mb {
      padding: 0px !important;
    }

    .eoi-recipient-row {
      .w-22 {
        width: 100%;
        padding-left: 0px;
      }

      .w-78 {
        width: 100%;
        padding-left: 0px;
      }
    }

    .radio-mob-style {
      display: flex;

      .MuiFormControlLabel-label {
        flex-grow: 1;
      }
    }
  }

  .crm-card-wrapper {
    .width-25-100 {
      width: 70%;
    }

    .width-p {
      width: 50%;
    }

    .form-item {
      .native-select {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  .eoi-wrapper {
    .paddingleft-0 {
      padding-left: 0px;
    }

    .marginbottom-20 {
      margin-bottom: 20px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1198px) {
  .crm-card-wrapper {
    .width-25-100 {
      width: 30%;
    }
  }
}

