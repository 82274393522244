@import '../../stylesheets/theme/theme';
@import '../../stylesheets/theme/theme-variables';

// global Steps SCSS
button:focus {
  outline: none;
}

.MuiInputLabel-root {
  text-transform: capitalize;
}
.add-another-driver-label {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.04em;
}
.auto-steps-container {
  .adjust-row-width {
    margin-left: -135px;
    margin-right: 135px;
  }
}

.list-spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

  div {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    background-color: #333333ad;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.auto-dialog-container {
  .MuiDialogActions-root {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;

    .btn-yes {
      @include themify($themes) {
        background-color: themed('backgroundColor');
        color: #fff;
        border-radius: 26px;
        padding: 5px;
        box-shadow: 0px 3px 6px #a2a0a099;
        margin: 10px;
        text-transform: capitalize;
      }
    }
    .btn-no {
      @include themify($themes) {
        color: themed('backgroundColor');
        border: 1px solid themed('backgroundColor');
        border-radius: 18px;
        padding: 3px;
        margin: 10px;
        text-transform: capitalize;
      }
    }
  }
}

.autoline-steps {
  min-height: 100vh;
  .MuiFormLabel-root {
    font-weight: bold !important;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0.04em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    max-width: 330px;
  }
  .form-control {
    width: 100%;
  }
  .MuiRadio-root {
    @include themify($themes) {
      color: themed('highlight-text-color');
    }
    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .margin-top-70 {
    margin-top: 70px;
  }
  .vin-auto-capitalize {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #3a4b5e;
  }
  .Mui-disabled {
    .vin-auto-capitalize {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .icon,
  .highlight-text {
    @extend .add-another-driver-label;
    @include themify($themes) {
      color: themed('highlight-text-color') !important;
    }
  }
  .mainheadingrow {
    margin-bottom: 30px;
    .MainHeading {
      text-align: center;
      font-size: 27px;
      font-weight: 500;
    }
    .icon {
      text-align: center;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
  }
  .subtitle {
    font-size: 10px;
    color: #928989;
  }
  .padd-20 {
    padding: 20px 0px;
  }
  .ResidencyDiv {
    .VoiletHeading {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      @include themify($themes) {
        color: themed('highlight-text-color') !important;
      }
    }
  }
  .devider {
    border-bottom: 1px solid #cddbeb;
    padding: 20px 0px;
  }
  .devider-spacing {
    margin-top: 3em;
    margin-bottom: 2em;
  }
  .devider-spacing-2 {
    @extend .devider-spacing;
    margin-top: 2em;
  }
  .sub-title-blue {
    .VoiletHeading {
      @include themify($themes) {
        color: themed('highlight-text-color') !important;
      }
      line-height: 25px;
      font-weight: 600;
      font-size: 18px;
    }

    &.sub-other-heading {
      margin-bottom: 1rem;
      .VoiletHeading {
        @include themify($themes) {
          color: themed('highlight-text-color') !important;
        }
      }
    }
    .small-label-heading {
      color: #3a4b5e;
      text-transform: uppercase;
      @extend .add-another-driver-label;
    }
    .VoiletSubHeading {
      color: black;
      text-align: right;
      .VoiletSubHeading-text {
        @extend .add-another-driver-label;
      }
      .addIcon {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        cursor: pointer;
        @include themify($themes) {
          box-shadow: 0px 0px 1px 2px themed('add-icon-border');
          background-color: themed('step-border-bg');
          color: themed('icon-button-color');
        }
      }
    }
    .logo-container {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      .delete-logo {
        cursor: pointer;
        @include themify($themes) {
          color: themed('backgroundColor') !important;
        }
      }
    }
  }

  .Violationslbl {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    span {
      font-size: 14px;
    }
  }

  .linkbtn {
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: pointer;
    margin-right: 20px;
    padding: 3px 0px;

    .link-label {
      vertical-align: middle;
      svg {
        font-size: 14px;
      }
    }
  }
  .margin-top-bottom {
    margin: 2em 0px;
  }
  .devider-label {
    position: absolute !important;
    top: -13px;
    left: 46%;
    background: #fff;
    padding: 0px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #3a4b5e;
  }
  .vehicle-selector-wrapper {
    ::-webkit-scrollbar {
      width: 12px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0f8ff;
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #98a2b0;
      border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #98a2b0;
    }
    .MuiListItem-button {
      &:hover {
        @include themify($themes) {
          background-color: themed('quote-bg-color');
          color: themed('buttonBgColor');
        }
      }
    }

    .MuiListItem-root.Mui-selected,
    .MuiListItem-root.Mui-selected:hover {
      @include themify($themes) {
        background-color: themed('quote-bg-color');
      }
    }
  }
  .selectionlabel {
    font-size: 13px;
    text-align: left;
    font-weight: bold;
    background: #f4fbff;
    padding-left: 18px;
    padding: 0px 0px 0px 18px;
    margin-top: 14px;
    line-height: 36px;
  }
  .vehicleDiv {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #cddbeb;
    }
    .MuiOutlinedInput-root {
      border-radius: 6px;
    }
    .Vehiclelbl {
      text-align: center;
      padding: 20px 0px;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }

    .vehicleListItem {
      padding: 0px 16px;
      span {
        font-size: 13px !important;
        font-weight: bold;
        color: #3a4b5e;
        line-height: 28px;
      }
    }
  }

  .vehicleFormTwo,
  .driverFormTwo {
    .vehicleInfoBox,
    .driverInfoBox {
      display: flex;
      align-items: center;
      box-shadow: 0px 1px 6px #0000004d !important;
      @include themify($themes) {
        background: themed('backgroundColor') !important;
      }
      border-radius: 6px;
      padding: 10px 20px;
      padding-left: 10px;
      padding-right: 0px;
      color: #fff;
      .VIBlbl {
        margin-right: 40px;
        @extend .add-another-driver-label;
        .icon {
          float: right;
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
        }
        .info-tick-icon {
          font-size: 24px;
          margin-right: 8px;
          color: #fff;

          /*  @include themify($themes) {
            color: themed("backgroundColor") !important;
          } */
        }
      }
      .icon-edit {
        font-size: 22px;
      }
      .icon-cross {
        font-size: 28px;
        margin-top: 4px;
      }
    }
    &.selected-vehicle-info {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
      .driverInfoBox {
        background-color: #fff !important;
        p,
        .info-tick-icon {
          @include themify($themes) {
            color: themed('backgroundColor') !important;
          }
        }

        @include themify($themes) {
          color: themed('backgroundColor') !important;
          border: 1px solid themed('backgroundColor');
        }
      }

      .driverInfoBox {
        .icon,
        .highlight-text {
          @include themify($themes) {
            color: themed('highlight-text-color') !important;
          }
        }
        p {
          @extend .add-another-driver-label;
          color: #fff;
          /* @include themify($themes) {
            color: themed("backgroundColor") !important;
          } */
        }
        .VIBlbl {
          margin-right: 40px;
          @extend .add-another-driver-label;
          .icon {
            float: right;
            @include themify($themes) {
              color: themed('backgroundColor') !important;
            }
          }
          .info-tick-icon {
            font-size: 24px;
            margin-right: 8px;
            color: #fff;
            /*  @include themify($themes) {
              color: themed("backgroundColor") !important;
            } */
          }
        }
      }
    }
    .policyholderlbl {
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
    .policyholderlbl {
      font-size: 14px;
      margin-bottom: 0px;
    }
  }

  input {
    label {
      font-family: 'Manrope', sans-serif;
    }
    ::-ms-clear {
      display: none;
    }
  }
  .btnwrap {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    .btnBackLink {
      padding-right: 20px;
      border-radius: 25px;
      width: 150px;
      height: 45px;
      text-transform: capitalize;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
        box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
      }
      svg {
        font-size: 14px;
      }
      &:hover {
        background: transparent;
      }
      .link-label {
        font-size: 0.875rem;
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
    }
    .btnContinue {
      border-radius: 25px;
      width: 150px;
      height: 45px;
      color: #fff;
      box-shadow: none;
      text-transform: capitalize;
      @include themify($themes) {
        background-color: themed('backgroundColor') !important;
        box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
      }
    }

    .btnGetQuoteLink {
      padding-right: 20px;
      border-radius: 16px;
      text-transform: capitalize;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
      }
      margin-left: 20px;
    }
  }
  .margin-top {
    margin-top: 2em !important;
  }
  .vehicle-devider {
    border-bottom: 1px solid #cddbeb;
    padding-bottom: 40px;
    padding-top: 38px;
  }
  .add-vehicle-btn,
  .add-driver-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    button {
      background: transparent;
      box-shadow: none;
      padding: 0px;
    }
    /*You can use [title] selector as well*/
    [data-title] {
      font-size: 30px; /*optional styling*/
      position: relative;
      cursor: pointer;
    }

    [data-title]:hover::before {
      content: attr(data-title);
      position: absolute;
      bottom: -42px;
      border-radius: 4px;
      display: inline-block;
      padding: 8px 20px;
      border-radius: 2px;

      text-transform: capitalize;
      font-family: 'Manrope', sans-serif;
      font-weight: 700;
      @include themify($themes) {
        color: themed('tooltip-text-color') !important;
        background: themed('tooltipBgColor') !important;
      }
      font-size: 11px;
      font-family: sans-serif;
      white-space: nowrap;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    }
    [data-title]:hover::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 8px;
      font-family: 'Manrope', sans-serif;
      font-weight: 700;
      text-transform: capitalize;
      display: inline-block;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
    }
  }
  .vehicleBtnDiv,
  .driverBtnDiv {
    .centerBtn {
      .btnAddVehicle,
      .btnAddDriver {
        border-radius: 30px;
        color: #fff;
        display: block;
        width: 100%;
        max-width: 255px;
        height: 40px;
        font-weight: bold;
        font-family: 'Manrope', sans-serif;
        font-size: 13px;
        line-height: 18px;
        text-transform: capitalize;
        font-feature-settings: 'cpsp' on, 'liga' off, 'kern' off;
        display: block;
        box-shadow: none;
        @include themify($themes) {
          border: 1.5px solid themed('backgroundColor') !important;
          background: themed('backgroundColor') !important;
        }
      }
    }
    .centerAlignBtn {
      justify-content: center;
      align-items: center;
      display: flex;
      display: -ms-flexbox;
      text-align: center;
      button {
        text-transform: capitalize;
      }
      .btnotherAddDriver {
        @include themify($themes) {
          border: 1.5px solid themed('backgroundColor') !important;
          background: themed('backgroundColor');
        }
        border-radius: 30px;
        color: #fff;
        display: block;
        margin: 0 auto;
        box-shadow: none;
        width: 255px;
        height: 40px;
      }
      .btnContinue {
        border-radius: 25px;
        width: 150px;
        height: 45px;
        color: #fff;
        @include themify($themes) {
          background-color: themed('backgroundColor') !important;
          box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
        }
        display: block;
      }
      .btnGetQuoteLink {
        border-radius: 25px;
        width: 150px;
        height: 45px;
        color: #fff;
        @include themify($themes) {
          color: themed('backgroundColor') !important;
          border: 1px solid themed('backgroundColor') !important;
        }
        display: block;
        margin-left: 20px;
      }
    }
  }
  .error {
    color: $errorRed;
    font-size: 12px;
    text-align: left;
    margin-left: 10px;
  }
  .btn-success {
    font-size: 15px;
    width: 190px;
    height: 45px;
    border-radius: 25px;
    text-transform: uppercase;
    border: none;
    font-family: 'Manrope', sans-serif;
    letter-spacing: 1.65px;
    @include themify($themes) {
      background-color: themed('buttonBgColor') !important;
    }
    &:focus {
      @include themify($themes) {
        background-color: themed('buttonBgDarkColor') !important;
      }
    }
  }
}
@media (min-width: 577px) {
  .btnwrap {
    .btnBackLink {
      margin-right: 10px;
    }
    .btnContinue {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
@media (max-width: 576px) {
  .autoline-steps {
    .btnwrap {
      display: block !important;
      button {
        margin-bottom: 20px;
        margin-left: 0px;
        margin-right: 0px;
      }
      .btnwrap {
        .btnBackLink {
          margin-right: 0px;
        }
        .btnContinue {
          margin-left: 0px;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .autoline-steps {
    .vehicle-selector-wrapper {
      margin: 0px !important;
      .col-md-3 {
        margin-bottom: 10px;
      }
    }
    .MuiFormLabel-root {
      width: 100% !important;
    }
    .margin-0 {
      margin: 0px !important;
    }
  }
  .btnwrap {
    .btnBackLink {
      width: 100% !important;
    }
    .btnContinue {
      float: right;
      margin-left: 0px !important;
      width: 100% !important;
    }
  }
  .sub-title-blue {
    .VoiletSubHeading-text {
      display: none;
    }
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .autoline-steps {
    .MuiFormLabel-root {
      width: auto;
      max-width: 258px;
    }
  }
}
@media (max-width: 991px) {
  .autoline-steps {
    min-height: inherit;
  }
}
