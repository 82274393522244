@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme.scss';

.jc-proper {
  justify-content: flex-end;
}

.padding-left-0 {
  padding-left: 0px;
}

.coverage-for-one-lob {
  width: 75%;
  float: right;
  @media (max-width:992px) {
    width: 100%;
    padding-top: 16px;
  }
}

.carrier-error {

  .carrier-error-block-inner {
    margin: 0px 14px;
    margin-bottom: 0px;
    min-height: 150px;
    border-radius: 10px;
    height: 100%;
    padding: 20px 0px 0 0px;

    .product-logo-div {
      border-right: 1px solid #e3e8eb;

      .carrier-logo-div {
        height: 115px;
        display: flex;
        align-items: center;
      }
    }
  }

  .error-content {
    flex-direction: column;

    .dark-label {
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 15px;
    }

    .light-label {
      font-family: 'Manrope';
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #000000;
    }
  }
}

.lob-name-none {
  display: none;

  @media (max-width:991px) {
    display: block;
  }
}

.blnk-select-view-more {
  display: flex;
  align-items: center;
  padding: 0px;

  .blnk-select-field {
    width: 100%;

    .MuiOutlinedInput-input {
      padding: 5px;
    }

    select {
      font-size: 12px;
      height: inherit;
      text-overflow: ellipsis;
      width: 77%;
    }

    .MuiSelect-icon {
      top: 7px;
    }

    .MuiSelect-select.MuiSelect-select {
      padding-left: 12px !important;
      padding-right: 39px !important;
    }
  }
}

.light-label {
  font-size: 12px;
  font-weight: 600;
  color: #aeaeae;
}

.policy-bound-label {
  z-index: 1;
  cursor: pointer;
}

.info-circle-view {
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  @include themify($themes) {
    color: themed('backgroundColor');
  }
}

.margin-t-2 {
  margin-top: -2px;
}

.padding-left-0 {
  padding-left: 0px !important;
}

.padding-right-34 {
  padding-right: 34px !important;
}

.width-16 {
  flex: 0 0 16%;
  max-width: 16%;
}

.padding-right-0 {
  padding-right: 0px;
}

.padding-right-0 {
  padding-right: 0px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.applicant-wrap>div.MuiGrid-item {
  padding: 13px;
}

.non-admitted-confirmation-text {
  font-family: 'Roboto', sans-serif;
  max-width: 410px;
  margin-top: 12px;
}
.rates-wrapper {
  .disabled-imcomplete-quote {

    h1,
    .clickhere-text {
      color: rgba(58, 75, 94, 0.5) !important;
      cursor: default;
    }
  }

  .rates-left-section {

    .home-rates-blocks,
    .auto-rates-blocks {
      max-width: 100%;
      flex-basis: 95%;
      margin-left: 14px;

      @media (max-width:768px) {
        flex-basis: 100%;
        margin-left: 0px;
      }
    }

    .home-rates-blocks-error {
      max-width: 100%;
      flex-basis: 100%;
    }

    .checkbox-wrap {
      margin-top: 16px;
    }

    .rli-rates-block {
      .rates-block-inner {
        padding-bottom: 0px;
      }
    }
    .rates-block {
      .help-icon,
      .underwriting-exposure-management,
      .underwriting-property-risk, 
      .addressable-carrier-error {
        position: absolute;
        top: 16px;
        right: 8px;
        font-size: 20px;
        color: #ff6600fd;
      }
      .cancel-icon,
      .application-error-external,
      .application-error-internal,
      .application-error,
      .non-addressable-carrier-error {
        @extend .help-icon;
        color: #ff0000;
      }
      .agent-addressable,
      .agent-addressable-property-data,
      .agent-addressable-coverages {
        @extend .help-icon;
        color: #00cf83;
      }
    }
    .rates-block,
    .rli-rates-block {
      .flood-quake-rates-blocks {
        .bg-light-blue {
          background: unset;
        }
      }

    }

    .rli-rates-block {
      .rli-padding-0 {
        padding: 0px;

        .no-coverage-label {
          margin-top: 10px;
        }
      }
    }

    .no-coverage-label {
      font-family: 'Manrope';
      font-weight: 700;
      font-size: 12.9231px;
      line-height: 18px;
      color: #3A4B5E;
    }
  }

  .download-loader {
    width: 30px !important;
    height: 30px !important;
    color: #fff !important;
  }

  .dc-list {
    padding-inline-start: 20px;
    margin-bottom: 0px;
  }

  svg {
    font-size: inherit;
  }

  .more-product-button {
    width: 100%;
    height: 40px;
    margin-top: 16px;
    border-radius: 30px;

    @include themify($themes) {
      background: themed('backgroundColor');
    }

    .add-icon {
      font-size: 18px;
    }
  }

  .add-icon-border {
    font-size: 20px !important;
  }

  .mb-10 {
    margin-bottom: 15px;
  }

  p {
    font-family: 'Manrope', sans-serif;
  }

  .label {
    font-size: 13px;
    color: #3a4b5e;
    font-weight: bold;
  }

  .active {
    @include themify($themes) {
      border: 1px solid themed('buttonHoverBorder') !important;
      box-shadow: 0px 4px 10px 1px #d4d4d5;
    }
  }

  .btn-green {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 19px 1px #edf0f5;
    text-transform: capitalize;
    color: #fff;
    width: 140px;
    height: auto;
    border: 1px solid #fff;
    min-height: 50px;
    border-radius: 6px;

    @include themify($themes) {
      color: themed('backgroundColor');
    }

    &:hover {
      border: 1px solid;
      background-color: #fff;

      @include themify($themes) {
        border-color: themed('buttonHoverBorder');
      }
    }

    .ams-button__navigation {
      height: 60px;
    }
  }

  .text {
    &:hover {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .rates-block-deactive {
    border: none;
    box-shadow: none;
  }

  .rates-block,
  .rli-rates-block {
    width: 100%;
    height: auto;
    min-height: 169px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    position: relative;

    &:hover {
      @include themify($themes) {
        border-color: themed('lightbg');
      }

      border: 1px solid;
      box-shadow: 0px 4px 10px 1px #d4d4d5;
    }

    .checkbox-wrap-radio {
      position: absolute;
      right: -2px;
      top: 8px;
    }

    .checkbox-wrap {
      position: absolute;
      right: 0px;
      top: 0px;

      svg {
        font-size: 25px !important;
      }

      .MuiIconButton-colorSecondary {
        &:hover {
          @include themify($themes) {
            background-color: themed('quote-bg-color') !important;
          }
        }
      }
    }

    .container-rates-block {
      padding-right: 16px;
    }

    .rates-block-inner {
      border-radius: 10px;
      height: 100%;
      min-height: 180px;
      padding: 20px 0px 20px 0px;

      .product-logo-div {
        border-right: 1px solid #e3e8eb;

        .carrier-logo-div {
          height: 115px;
          display: flex;
          align-items: center;
        }

        img {
          width: 115px;
        }
      }
      .non-admitted-carrier-div {
        border: 1px solid #f44336;
        padding: 9px;
        font-weight: 600;
        color: #f44336;
        font-family: 'Manrope', sans-serif;
        font-size: 12px;

        .non-admitted-label {
          width: auto;
          min-width: 115px;
          font-weight: 900;
        }
      }
      .non-admitted-info-icon {
        font-size: 20px !important;
        font-weight: 900;
      }
    }

    .rates-block-inner__height {
      height: auto;
      min-height: 130px;
    }

    .price-radio-button {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;

      .MuiCheckbox-root {
        color: #d9d9d9;
      }
    }

    .purchaseButtonFlood {
      flex-direction: column;
    }

    .purchase-button-height {
      height: 100%;

      .mb-13 {
        margin-bottom: 13px;
      }
    }

    .linkButtonFlood {
      display: flex;
      flex-direction: row;
      align-items: center;

      .imgButtonFlood {
        margin-right: 8px;
        width: 32px !important;
      }
    }

    .product-logo-column {
      flex-direction: column;
    }

    .form-control-price {
      height: 130px;
      width: 30%;
      margin-left: 2% !important;
      margin-right: 2% !important;
      margin-bottom: 3% !important;

      &:last-child {
        margin-right: 0px !important;
      }

      .MuiFormControlLabel-label {
        width: 100%;
      }

      .flood-rates-price-wraps {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 16px;

        .premium-value {
          @include themify($themes) {
            color: themed('rates-block-price-color');
          }

          font-weight: bold;
          line-height: 34px;
          width: 100%;
          font-size: 30px;
          margin-bottom: 0.1em;
        }

        .premium-value-label {
          @include themify($themes) {
            color: themed('rates-block-price-color');
          }

          font-weight: 600;
          font-size: 12.9231px;
          width: 100%;
          line-height: 18px;
        }
        .plan-name {
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 12.9231px;
          line-height: 18px;
          color: #6B7A93;
        }
      }

      .quake-cov-label {
        position: absolute;
        bottom: 11px;
        height: 26px;
        line-height: 13px;
        width: 77%;
        margin: 0 auto;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .premium-value-label {
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          color: #3A4B5E;
        }
      }

      &.MuiFormControlLabel-root {
        align-items: flex-start;
      }

      .check-icon {
        padding: 2px;
      }
    }

    .rli-form-control-price {
      height: 130px;
      width: 31%;
      margin-right: 16px;
      margin-bottom: 0px !important;

      &:last-child {
        margin-right: 0px !important;
      }

      .MuiFormControlLabel-label {
        width: 100%;
      }

      .flood-rates-price-wraps {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 16px;

        .premium-value {
          @include themify($themes) {
            color: themed('rates-block-price-color');
          }

          font-weight: bold;
          line-height: 34px;
          width: 100%;
          font-size: 30px;
          margin-bottom: 0.1em;
        }

        .premium-value-label {
          @include themify($themes) {
            color: themed('rates-block-price-color');
          }

          font-weight: 600;
          font-size: 12.9231px;
          width: 100%;
          line-height: 18px;
        }
      }

      .quake-cov-label {
        position: absolute;
        bottom: 5px;
        height: 26px;
        line-height: 13px;
        width: 77%;
        margin: 0 auto;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .premium-value-label {
          font-weight: 600;
          font-size: 11px;
          line-height: 13px;
          color: #8895a5;
        }
      }

      &.MuiFormControlLabel-root {
        align-items: flex-start;
      }

      .check-icon {
        padding: 2px;
      }

      .flood-rates-price-wraps {
        .premium-value {
          font-size: 24px;
        }

        .premium-value-label {
          font-size: 10.9231px;
        }
      }

      .MuiIconButton-root {
        position: absolute;
        right: -3px;
        top: -4px;
      }
    }


    .bg-light-green {
      background-color: #e3fff3;
    }

    .bg-light-blue {
      background-color: #eaf5ff;
    }

    .price-wraper {
      .center-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
      }

      .price-list-wrap {
        .form-control-price {
          background: #dcf3ff;
          border-radius: 10px;
          position: relative;
          padding: 5px 5px;
          margin-right: 0px;
          margin-left: 0px;

          .MuiFormControlLabel-label {
            font-size: 12px;
          }

          .MuiIconButton-root {
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        .rli-form-control-price {
          @extend .form-control-price;
          width: 21%;
          background-color: #F3FBFF;
          margin-bottom: 3.2% !important;
        }
      }
      .dic-polices {
        color: #FFFFFF;
        @extend .label;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
        background-color: #FF0000;
        padding-left: 27px;
        padding-right: 27px;
      }
      .price-label {
        color: #3a4b5e;
        @extend .label;
        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        text-align: center;
      }

      .price-amount {
        @extend .label;
        font-size: 42px;
        line-height: 50px;
        text-align: center;
        color: #3a4b5e;
      }

      .error-label {
        word-break: break-word;
        @media (max-width: 768px) {
          padding-bottom: 20px;
        }
      }
    }

    .purchase-online-label {
      @extend .label;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      .submit-loader {
        position: absolute;
        left: 35%;
      }
    }

    .requested-bind-label {
      font-size: 13px;
      font-weight: bold;
      line-height: 17px;
      text-align: center;
      color: #a2aebb;

      .submit-loader {
        position: absolute;
        left: 35%;
      }
    }

    .home-with-bg-img {
      max-width: 60px;
    }

    .label-home {
      text-align: center;
      margin-top: 3rem;
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      text-transform: uppercase;
      color: #2196f3;
    }

    .forword-arrow-div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      max-height: 153px;
      cursor: pointer;
      padding-right: 5px;

      svg {
        background-color: #f3fbff;
        border-radius: 26px;
        color: #2196f3;
        padding: 6px;
      }
    }

    .logo-wrap {
      .padding-0 {
        padding: 0px;
      }

      .padding-left-0 {
        padding-left: 0px;
      }
    }

    .incomplete-quote-card,
    .quote-loader-card {
      .padding-left-0 {
        padding-left: 0px;
      }
    }

    .incomplete-quote-wrap,
    .quote-loader-wrap {
      .insurence-logo-div {
        padding: 4em 0px;

        .product-label {
          font-weight: bold;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          margin-top: 10px;
          justify-content: center;
          text-align: center;

          @include themify($themes) {
            color: themed('backgroundColor');
          }

          text-transform: uppercase;
        }
      }
    }

    .insurence-logo-div {
      padding: 48px 0px;

      .product-label {
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        margin-top: 10px;
        justify-content: center;
        text-align: center;

        @include themify($themes) {
          color: themed('backgroundColor');
        }

        text-transform: uppercase;
      }

      img {
        width: 54px;
      }

      .logo-label {
        text-align: center;
        margin-top: -19px;
        font-weight: bold;
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        color: #2196f3;
      }
    }

    .next-icon-div {
      height: 100%;
      max-height: 169px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .prev-next-icon {
        background: #e3f3fb;
        border-radius: 20px;

        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
    }

    .left-5 {
      margin-left: 5em;
    }

    .select-wrap {
      border: 1px solid #d9dfef;
      border-radius: 4px;
      width: 100%;
      max-width: 250px;

      .MuiInput-underline:hover:not(.Mui-disabled):before,
      .MuiInput-underline:after {
        border-bottom: 0px !important;
      }

      .MuiInput-underline:before {
        border-bottom: 0px !important;
      }

      .MuiSelect-select {
        padding-left: 10px;
        font-size: 13px;
        font-weight: 600;
        font-family: 'Manrope', sans-serif;
      }

      select {
        font-size: 13px;
        font-family: 'Manrope', sans-serif;
        padding: 0;
        padding-left: 20px;
        color: #3a4b5e;
        height: 36px;
        font-weight: bold;
      }

      .MuiSelect-icon {
        top: inherit;
      }
    }

    .deductible {
      width: 100%;
    }

    .MuiCardContent-root:last-child {
      padding-bottom: 16px;
    }

    .loader-div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      min-height: 234px;
    }

    .MuiRadio-colorSecondary.Mui-checked {
      @include themify($themes) {
        color: themed('backgroundColor');
      }

      .view-more-item {
        display: block;
        margin: 10px 0;
      }
    }

    .label-view-more {
      @extend .label;
      font-size: 13px;
      float: right;
      cursor: pointer;

      .add-icon {
        font-size: 16px;
      }
    }

    .download-indication {
      font-size: 13px;
      font-weight: bold;
      margin-left: 7px;
      .vertical-line{
        padding-right: 7px;
        @media (max-width: 575px) {
          display: none;
        }
      }
    }
    
    .download-indication-label-clr {
      color: #3a4b5e;
      cursor: pointer;
    }
    
    .download-indication-disable {
      color: rgba(58, 75, 94, 0.5);
      cursor: default;
    }
    
    @media (max-width: 576px) {
      .label-view-more, .download-indication {
        font-size: 13px;
        text-align: center;
      }
      .label-view-more {
        width: 100%;
      }
    }
    

    .view-more-button-div {
      .width-50 {
        width: 50%;
      }

      .plan-details-label {
        cursor: pointer;
        float: left;
        font-size: 13px;
        font-weight: bold;

        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
      
    }

    .radio-button-box {
      margin-left: 0;
      margin-right: 0;

      svg {
        color: #d9d9d9;
      }
    }

    .check-icon {
      font-size: 24px;

      @include themify($themes) {
        color: themed('lightbg');
      }
    }
  }

  .nav {
    display: flex;
    padding: 0px;
    justify-content: center;

    @media (min-width: 991px) {
      justify-content: flex-start;
    }

    .business-options {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .btn-green {
        width: 100%;
        max-width: 170px;
        height: 63px;

        img {
          width: 35px;
          height: 35px;
        }

        span {
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-transform: uppercase;
        }
      }

      .text-color {
        @include themify($themes) {
          color: themed('business-button-color');
        }
      }

      @media (min-width: 991px) {
        & {
          flex-direction: row;
          justify-content: flex-start;
        }

        .btn-green {
          margin-right: 1em;
          margin-bottom: 0em;
        }

        .btn-green:last-child {
          margin-right: 0em;
        }
      }


      .width-60 {
        width: 60%;
      }

      .ams-adhoc-button {
        width: 100%;
        max-width: 170px;
        height: 63px;
        background: #fff;
        box-shadow: 0px 0px 19px 1px #edf0f5;
        border-radius: 6px;
        border: 1px solid #fff;

        .ams-label {
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;
        }

        .width-40 {
          width: 40%;
          display: flex;
          justify-content: space-around;

          @media (max-width:768px) {
            display: block;
            justify-content: initial;
            width: initial;
          }
        }

        &:hover {
          @include themify($themes) {
            border: 1px solid themed('backgroundColor');
            background-color: transparent;
          }
        }

        @media (max-width:991px) {
          width: 100%;
          max-width: 100%;

          .width-40 {
            width: initial;
          }
        }
      }
    }

    .add-product {
      display: flex;
      padding: 0px;
      justify-content: center;

      @media (min-width: 991px) {
        justify-content: flex-end;
      }
    }
  }

  .vehicles-right-section,
  .drivers-right-section {
    .add {
      .add-circle-icon {
        width: 16px;
        height: 16px;

        @include themify($themes) {
          box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
        }

        border-radius: 50%;
      }
    }
  }

  .vehicles-right-section,
  .coverages-right-section,
  .drivers-right-section,
  .annual-price-right-section {
    border: 2px solid #ced9de;
    border-radius: 6px;

    .text-label {
      @extend .label;
      font-size: 12px;
      text-transform: uppercase;
    }

    .price-green-div {
      @include themify($themes) {
        background-color: themed('quote-bg-color') !important;
        border-bottom: 3px solid;
        border-color: themed('lowestPriceBoxColor');
      }

      padding: 0px 8px;

      .price-div-label {
        font-family: 'Lora', serif !important;
        @extend .label;
        font-size: 35px;
        line-height: 54px;
        text-align: right;

        span {
          @extend .price-div-label;
          font-size: 28px;
          vertical-align: text-bottom;
          padding-left: 10px;
        }
      }
    }

    .icon {
      width: 26px;
    }

    .icon-label {
      font-family: 'Manrope', sans-serif !important;
      font-weight: bold;
      @extend .label;
      margin-left: 10px;

      .logo-img {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        object-fit: contain;
      }
    }

    .applicant-label,
    .vehicles-label,
    .coverages-label,
    .drivers-label {
      @extend .label;
      font-size: 18px;

      .edit-icon {
        font-size: 18px;
        float: right;
        cursor: pointer;

        @include themify($themes) {
          color: themed('iconcolor');
        }
      }
    }

    .add {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 50%;

      svg {
        width: 0.7em;
        height: 0.7em;

        @include themify($themes) {
          fill: themed('iconcolor');
        }
      }

      &-label {
        font-weight: bold;
        margin-left: 0.5em;

        @include themify($themes) {
          color: themed('iconcolor');
        }
      }
    }

    .applicant-list,
    .vehicles-list,
    .drivers-list,
    .pet-list {
      p {
        @extend .label;
      }

      .normal-font {
        font-weight: normal;
      }
    }

    .capitalize {
      text-transform: capitalize;
    }

    .vehicles-list {
      .vehicle-item {
        &__info {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .drivers-list,
    .pet-list {

      .driver-item,
      .pet-item {
        &__info {
          display: flex;
          justify-content: space-between;
          word-break: break-word;
        }
      }
    }

    .vehicles-list,
    .drivers-list,
    .pet-list {
      .add {
        padding: 5px 0px;
      }
    }

    .policy-bound-wrap {
      .policy-bond {
        position: absolute;
        top: 0;
        left: 0;
        width: 102px;
        cursor: pointer;
      }

      .policy-bound-div {
        img {
          width: 100px;
          height: 100px;
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .download-button {
    width: 100%;
    font-family: 'Manrope', sans-serif;
    height: 41px;
    color: #fff;
    text-transform: capitalize;

    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
    }

    border-radius: 100px;
    font-weight: bold;
    font-size: 12.9231px;
    line-height: 18px;
  }

  .see-more-rates-label {
    font-size: 12px;
    border-bottom: 1px solid #fff;
    color: #fff;

    .plus-icon {
      font-size: 18px;
    }
  }

  .block-headers,
  .block-body {
    display: flex;
  }

  .view-more-section {
    border-top: 1px solid #e3e8eb;
    margin-top: 20px;

    .vehicle-info {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      margin: 10px 0px 15px 0px !important;

      @include themify($themes) {
        color: themed('theme-color');
      }
    }

    .block-table {
      table {
        @include themify($themes) {
          color: themed('$darkGrey');
        }

        td,
        th {
          font-family: 'Manrope', sans-serif;
        }

        th {
          font-weight: bold;
          font-size: 13px;
          line-height: 16px;
          color: #202021;
          border-bottom: 2px solid #e0e0e0;
        }
      }
    }

    .pet-details-wrap {
      .pet-details-heading {
        font-weight: 700;
        font-size: 14px;
        line-height: 27px;
        text-align: left;

        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }

      .rebuilding-cost-label {
        font-size: 15px;

        .petname-div {
          max-width: 117px;
          word-break: break-word;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .details-info-label {
          @extend .label;
          font-size: 15px;
        }
      }
    }

    .block-wraper {
      background: #f9fbfc;
      border-radius: 10px;
      height: 100%;
      padding: 2em 1em;
      min-height: 117px;
      overflow-x: auto;

      .rebuilding-cost-label {
        @extend .label;
        font-weight: 800;

        .info-circle-view {
          font-size: 14px;
        }
      }

      .cost-number-label {
        @extend .label;
        font-size: 24px;
      }
    }

    .block-table {
      padding: 1em;
    }

    .normal-label {
      @extend .label;
      font-weight: 500;
    }

    .view-more-grid {
      margin-bottom: 0.5rem;

      .MuiGrid-item {
        padding-bottom: 0px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .footer-section-list {
      li {
        font-size: 13px;
        font-weight: 500;
        color: #3a4b5e;

        span {
          font-weight: 800;
        }
      }
    }
  }

  .add-product-button {
    width: 179px;
    text-transform: capitalize !important;
    height: 49px;
    font-size: 14px;
    background: #ffffff;
    font-weight: 600;
    border: 0.813842px solid #e0e0e0;
    box-shadow: 0px 0px 9.76611px 5.6969px rgba(234, 238, 241, 0.3) !important;
    border-radius: 100px !important;
    display: flex;
    justify-content: space-around;

    @include themify($themes) {
      color: themed('backgroundColor');
    }

    .add-circle-icon {
      width: 18px;
      height: 18px;

      @include themify($themes) {
        box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
      }

      border-radius: 50%;
    }

    @media (max-width: 576px) {
      width: 100%;
      justify-content: center;

      .add-circle-icon {
        margin-left: 10px;
      }
    }
  }

  .btn-wrap-content {
    display: flex;
    justify-content: space-between;

    .add-product-button {
      width: 179px;
      text-transform: capitalize !important;
      height: 49px;
      font-size: 14px;
      background: #ffffff;
      font-weight: 600;
      border: 0.813842px solid #e0e0e0;
      box-shadow: 0px 0px 9.76611px 5.6969px rgba(234, 238, 241, 0.3) !important;
      border-radius: 100px !important;
      display: flex;
      justify-content: space-around;

      @include themify($themes) {
        color: themed('backgroundColor');
      }

      .add-circle-icon {
        width: 18px;
        height: 18px;

        @include themify($themes) {
          box-shadow: 0px 0px 1px 2px themed('combination-theme-color');
        }

        border-radius: 50%;
        &.disable {
          color: #8895a5;
          box-shadow: 0px 0px 1px 2px #8895a5 !important;
        }
      }

      &.Mui-disabled {
        color: #8895a5;
      }

      @media (max-width: 576px) {
        width: 100%;
        justify-content: center;

        .add-circle-icon {
          margin-left: 10px;
        }
      }
    }

    .refresh-btn-wrap {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 576px) {
        justify-content: center;
      }

      .refresh-btn {
        width: 135px;
        text-transform: capitalize !important;
        height: 49px;
        font-size: 14px;
        background: #ffffff;
        font-weight: 600;
        border: 0.813842px solid #e0e0e0;
        box-shadow: 0px 0px 9.76611px 5.6969px rgba(234, 238, 241, 0.3) !important;
        border-radius: 100px !important;

        @include themify($themes) {
          color: themed('backgroundColor');
        }

        .refreshIcon {
          font-size: 24px;
        }

        &.Mui-disabled {
          color: #8895a5;
        }

        @media (max-width: 576px) {
          width: 100%;
        }
      }
    }
    @media (max-width: 576px) {
      display: block;
    }
  }

  .incomplete-quote {
    cursor: pointer;
    padding: 25px 15px;
    gap: 12px;
    margin: 15px 0;
    min-height: 195px;

    h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 0px;

      @include themify($themes) {
        color: themed('theme-color');
        opacity: 0.4;
      }
    }

    p {
      font-size: 20px;
      line-height: 27px;
      font-weight: 500;
      margin-bottom: 0px;
      text-transform: capitalize;

      @include themify($themes) {
        color: themed('theme-color');
      }
    }
  }

  .carrier-img {
    max-width: 50px;
  }

  .loader-wrapper,
  .loader-wrapper-quote {
    width: 100%;
    position: relative;
    height: 100%;
    top: 1px;
    left: 0px;
    padding-left: 40%;
  }

  .sk-circle {
    width: 30px;
    height: 30px;
    position: relative;
    float: left;
  }

  .sk-circle .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 18%;
    height: 18%;
    background-color: white;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  }

  .loader-wrapper-quote {
    .sk-circle .sk-child:before {
      @include themify($themes) {
        background-color: themed('backgroundColor') !important;
      }
    }
  }

  .add-product {
    font-family: 'Manrope', sans-serif;
    text-transform: capitalize;
    box-shadow: 0px 0px 19px 2px #edf0f5 !important;
    border-radius: 6px;
    font-size: 18px;
    font-weight: bold;

    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border-color: themed('backgroundColor') !important;
    }

    .MuiButton-label {
      svg {
        margin-right: 10px;
      }
    }

    &:hover {
      @include themify($themes) {
        background-color: unset !important;
      }
    }
  }

  .discount-wrap {
    background: #f0f3f4;
    padding: 15px 11px;
    text-align: center;

    h2 {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;

      @include themify($themes) {
        color: themed('theme-color');
      }
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;

      li {
        &:last-child {
          border: 0px;
        }

        list-style: unset;
        display: inline-flex;
        padding: 0px 10px;
        margin: 0px 0px 0px 5px;
        border-right: 1px solid;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        @include themify($themes) {
          color: themed('discounts-applied-text-color');
        }
      }
    }
  }

  .sk-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }

  .sk-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  .sk-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }

  .sk-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }

  .sk-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }

  .sk-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .sk-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }

  .sk-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }

  .sk-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }

  .sk-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }

  .sk-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }

  .sk-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .sk-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }

  .sk-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .sk-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  .sk-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }

  .sk-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }

  .sk-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }

  .sk-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }

  .sk-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }

  .sk-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  .sk-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }

  @-webkit-keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .select-carrier-row {
    .center-div {
      p {
        .plan-label {
          cursor: pointer;
          padding-left: 2px;

          @include themify($themes) {
            color: themed('backgroundColor');
          }
        }
      }
    }
  }
  .h-63 {
    height: 63px;
  }
}

.error-text {
  font-size: 10px !important;
}
.view-content-wrap {
  .view-more-item {
    .label-view-more {
      .add-icon {
        font-size: 16px;
      }
    }
  }
  .button-view-more {
    display: flex;
    align-items: center;
    &:hover {
      background-color: transparent;
    }
    p {
      font-size: 13px;
      float: right;
      cursor: pointer;
      color: #3a4b5e;
      font-weight: bold;
    }
  }
}

.view-more-item-responsive {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
}

.display-view {
  justify-content: flex-end;
  @media (max-width: 576px) {
    justify-content: center;
  }
}

.view-more-section {
  margin-bottom: 20px;
  .lbl-details {
    font-family: 'Manrope';
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #000000;
    margin-bottom: 12px;
    margin-top: 5px;
  }
  .sub-para {
    @extend .lbl-details;
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 0px;
    word-break: break-word;
  }
}
.slider-wraper {
  width: 100% !important;

  .slider {
    width: 100%;

    .slide {
      height: auto;
      flex-direction: row !important;
      flex-flow: row !important;
    }
  }
}

.axis-horizontal {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.left-position {
  position: absolute;
  top: 90px;
  right: 0px;
}

.right-position {
  position: absolute;
  left: 0;
  top: 90px;
}

.non-admitted-view-more-wrapper{
 .non-admitted-view-more-title {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    font-size: 20px;
    font-weight: 700;
    font-family: 'Manrope', sans-serif;

    @media (max-width: 599px) {
      font-size: 18px;
    }
  }

  .non-admitted-view-more-sub-title {
    font-size: 15px;
    font-family: 'Manrope', sans-serif;
  }

  .non-admitted-details-list {
    list-style-type: circle;
    .list-items {
      font-size: 14px;
      color: #3a4b5e;
      font-family: 'Manrope', sans-serif;
    }
  }
}
.coming-soon-wrap {
  flex-direction: column;
}
.coming-soon-bridg-carrier-btn {
  a {
    flex-direction: column;
    &:hover {
      text-decoration: none;
    }
  }
}
.upcoming-carrier-wrapper {
  background: #eaf5ff;
  border-radius: 10px;
  .comming-soon {
    position: absolute;
    left: -30px;
    top: -17px;
  }
  .carrier-logo-div {
    img {
      height: 100px;
    }
  }
  .bridge-to-carrier-link {
    padding-bottom: 10px;
    gap: 6px;
    padding-top: 6px;
    img {
      width: 32px;
    }
    p {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #3a4b5e;
    }
  }
  .status-text {
    font-size: 18px;
  }
}

.coming-soon-wrap {
  .coming-soon-text {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #f25d0a;
  }
}
.purchase-btn-wrapper {
  .purchase-btn-link {
    &:hover {
      text-decoration: none;
    }
  }
  .coming-soon-carrier-purchase-type {
    width: 200px;
  }
}

@media (min-width: 310px) and (max-width: 991px) {
  .rates-wrapper {

    .rates-block,
    .rli-rates-block {
      .mob-margin-top {
        margin-top: 20px;
      }

      .rates-block-inner {
        padding: 20px 0px 0px 0px;

        .product-logo-div {
          border: none;
        }

        .mb-20 {
          margin-bottom: 20px;
        }
      }

      .select-wrap {
        margin-top: 7px;
      }
    }
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mobbottom-4 {
    margin-bottom: 20px;
  }
  .coming-soon-wrap,
  .coming-soon-bridg-carrier-btn {
   margin-bottom: 20px;
  }
  .coming-soon-wrap {
    .coming-soon-text {
      line-height: 28px;
    }
  }
}

@media (max-width: 599px) {

  .rates-block,
  .rli-rates-block {
    .insurence-logo-div {
      padding: 20px 0px !important;
    }
  }

  .error-text {
    width: 100%;
    word-break: break-word;
  }
}

@media (max-width: 767px) {
  .rates-wrapper {
    .incomplete-quote {

      h1,
      p {
        text-align: center !important;
      }
    }

    .rates-block,
    .rli-rates-block {
      .form-control-price {
        width: 100% !important;
        margin-bottom: 15px !important;
        margin-right: 0px !important;
      }

      .view-more-button-div {
        .width-50 {
          width: 100%;
        }

        .plan-details-label {
          text-align: center;
          width: 100%;
        }
      }
    }
        .rates-left-section {
          .rates-block {
            .cancel-icon,
            .help-icon
            .application-error,
            .addressable-carrier-error,
            .non-addressable-carrier-error,
            .underwriting-exposure-management,
            .underwriting-property-risk, 
            .agent-addressable,
            .agent-addressable-property-data,
            .agent-addressable-coverages
            .application-error-external,
            .application-error-internal{
              top: 2px;
              right: 0;
            }
          }
        }
  }

  .center-div {
    display: flex;
    justify-content: flex-start;
  }

  .business-options {
    .btn-green {
      max-width: 100% !important;
      margin-bottom: 20px;
    }
  }

  .m-display {
    display: none;
  }

  .blnk-select-view-more {
    flex-direction: column;
    padding: 15px;

    .rates-wrapper {

      .rates-block,
      .rli-rates-block {
        .label-view-more {
          text-align: center;
        }
      }
    }

    .blnk-select-field {
      width: 100%;
    }
  }

  .rates-wrapper {
    .rates-block,
    .rli-rates-block {
      .checkbox-wrap-radio {
        right: -10px;
        top: -10px;
      }
    }
  }

  .padding-left-0 {
    padding-left: 15px;
  }
  
  .carrier-error {
    .carrier-error-block-inner {
      padding-bottom: 20px;
      .product-logo-div {
        border-right: none;
      }
    }
  }

}

@media (min-width: 767px) and (max-width: 991px) {
  .rates-wrapper {
    .mob-width-50 {
      width: 50%;
    }

    .mob-margin-top {
      margin-top: 0px !important;
    }
  }

  .nav {
    .business-options {
      .btn-green {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 20px;
      }
    }
  }

  .resposive-mt-2 {
    margin-top: 20px;
  }

  .blnk-select-view-more {
    .blnk-select-field select {
      width: 100%;
    }

    .blnk-select-field {
      padding: 0px 15px;
      margin-top: 15px;
    }
  }

}

@media (min-width: 768px) and (max-width: 1200px) {
  .rates-wrapper {
    .nav {
      .business-options {
        .btn-green {
          padding: 10px !important;

          img {
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .rates-wrapper {

    .rates-block,
    .rli-rates-block {
      .purchase-online-label {
        font-size: 13px;
      }
    }
  }

}

@media (min-width: 1280px) and (max-width: 1480px) {
  .flood-quake-rates-blocks {

    .rates-block-inner,
    .view-more-button-div {
      .col-lg-4 {
        -ms-flex: 0 0 28%;
        flex: 0 0 28%;
        max-width: 28%;
      }

      .col-lg-8 {
        -ms-flex: 0 0 72%;
        flex: 0 0 72%;
        max-width: 72%;
      }
    }
  }
}

@media (max-width: 992px) {
  .lob-name-show {
    display: block;
  }
  .padding-left-0 {
    padding-left: 15px !important;
  }

  .padding-right-0 {
    padding-right: 15px !important;
  }

  .incomplete-quote-card,
  .quote-loader-card {
    .padding-left-0 {
      padding-left: 15px !important;
    }
  }

  .logo-wrap {
    .padding-left-0 {
      padding-left: 15px !important;
    }
  }

  .deductible {
    width: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1120px) {
  .rates-wrapper {
    .padding-left-0 {
      padding-left: 15px !important;
    }

    .select-carrier-row {
      .center-div {
        justify-content: flex-start !important;
      }

      .label {
        margin-bottom: 7px;
      }

      .col-lg-4,
      .col-lg-7 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    .checkbox-wrap-radio {
      top: 0px !important;
    }

    .deductible {
      width: 100% !important;
    }
  }
}