@import './stylesheets/common/mixins';
@import './stylesheets/theme/theme-variables.scss';

@include font-face('MarkOT-Bold', 'mark-ot-bold');
@include font-face('MarkOT', 'mark-ot');

@include font-face('Nitti Grotesk Medium', 'nitti-grotesk-medium');
@include font-face('Nitti Grotesk SemiLight', 'nitti-grotesk-semilight');
@include font-face('Nitti Grotesk Bold', 'nitti-grotesk-bold');

@include hippo-font-face('Akzidenz-Medium', 'akzidenz-grotesk-be-medium');
@include hippo-font-face('Akzidenz-Light', 'akzidenz-grotesk-be-light');

@include font-face('Roboto-Bold', 'roboto-bold');
@include font-face('Roboto-Light', 'roboto-light');
@include font-face('Roboto-LightItalic', 'roboto-light-italic');
@include font-face('Roboto-Medium', 'roboto-medium');

// BlockUi overriedes
#root {
  height: 100vh;
  .background-grey {
    background-color: #f6f6f6;
  }
  .main-wrap-height {
    height: auto;
    min-height: 100vh;
    .MuiInputBase-input,
    select,
    option,
    .MuiFormControlLabel-label {
      font-weight: 600;
      font-family: 'Manrope', sans-serif;
    }
  }
  .background-white {
    background-color: #fff;
  }
  .App {
    min-height: 100vh;
  }
  p,
  .MuiInputBase-root,
  .MuiTypography-root {
    font-family: 'Manrope', sans-serif;
  }
  .policy-bound-ribbon-auto {
    position: absolute;
    left: -11px;
    top: -11px;
  }

  .policy-bound-ribbon-home {
    position: absolute;
    left: -12px;
    top: -14px;
  }

  .flood-bound-ribbon {
    position: absolute;
    left: -12px;
    top: -17px;
  }

  .block-ui {
    height: 100vh;
    width: 100%;
  }

  .block-ui-overlay {
    opacity: 0 !important;
  }

  .hippo-anim-loader {
    background-image: url('./assets/images/hippo-loader.gif');
    width: 100px;
    height: 100px;
    background-size: 100%;
    border: none;
    margin: 0 auto;
  }

  .loader-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .quote-loader {
    height: 40vh;
    width: 100%;
  }

  .block-ui-message {
    font-size: 28px;
    font-family: 'Manrope', sans-serif;
    font-weight: 100;
    color: $gray-600;
  }

  .loader-inner {
    left: 49%;
    top: -85px;
  }
}

.tooltip-icon {
  cursor: pointer;
}

.popover {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);

  .popover-header {
    background-color: white;
    border-bottom: 0px solid #ebebeb;
    color: $black;

    .close {
      font-size: 18px;
      float: right;
      color: black;
      cursor: pointer;
    }
  }

  .popover-body {
    color: black;
    font-weight: 300;

    h1 {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
    }
  }
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #ccc #fff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #fff;
}
@media (max-width: 767px) {
  #root {
    height: 100vh;

    .block-ui {
      height: 100vh;
      width: 100%;
    }

    .block-ui-message {
      font-size: 18px;
      font-family: 'Manrope', sans-serif;
      font-weight: 100;
      color: $gray-600;
    }

    .loader-inner {
      left: 49%;
      top: -75px;
    }
  }
}

@media (max-width: 375px) {
  #root {
    height: 100vh;

    .block-ui {
      height: 100vh;
      width: 100%;
    }

    .block-ui-message {
      font-size: 14px;
      font-family: 'Manrope', sans-serif;
      font-weight: 100;
      color: $gray-600;
    }

    .loader-inner {
      left: 49%;
      top: -75px;
    }
  }
}

.tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    z-index: 10;
    font-family: 'Manrope', sans-serif;
    font-weight: 100;
    left: -16px;
    bottom: 25px;
    border-radius: 5px;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    padding: 15px;
    text-align: left;
    color: #000;
    background-color: $white;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
    min-width: 265px;

    h1 {
      font-size: 14px;
    }

    p {
      margin-bottom: 0px;
    }
  }
}

.bs-tooltip-top {
  .arrow {
    &:before {
      border-top-color: $white;
    }
  }
}

.bs-tooltip-bottom {
  .arrow {
    &:before {
      border-bottom-color: $white;
    }
  }
}

.tooltip {
  cursor: pointer;
}
