@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.create-policy-header-wrap {
  .resp-mb-20 {
    margin-bottom: 20px;
  }
}
.ams-modal {
  .MuiInputBase-input.Mui-disabled {
    opacity: 1;
    color: #afaeae !important;
  }
  .MuiTypography-h6,
  button {
    font-family: 'Manrope', sans-serif !important;
  }
  .custom-id-res {
    margin-top: 1.5rem;
  }
}

.ams-dialog-container {
  #ams-dialog-title {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  label {
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .ams-dropdown {
    padding-left: 3px;
  }
  .navigation-btn,
  .navigation-next-btn {
    box-shadow: 0px 3px 6px #a2a0a099;
    border-radius: 25px;
    color: $white;
    width: 100px;
    text-transform: capitalize;
    @include themify($themes) {
      background-color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor');
    }
    &:disabled {
      color: $white;
      opacity: 0.7;
    }
  }
  .navigation-back-btn {
    padding-right: 10px;
    border-radius: 16px;
    text-transform: capitalize;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
    }
    margin-right: 20px;
    width: 100px;
    height: 35px;
  }
  .effective-date-control {
    .DatePickerComponent {
      label {
        font-weight: normal !important;
      }
      margin: 0px;
      svg {
        @include themify($themes) {
          color: themed('combination-theme-color') !important;
        }
      }
    }
  }
  .addInsured {
    .btnAddInsured,
    .btnClearInsured {
      @include themify($themes) {
        border: 1.5px solid themed('backgroundColor') !important;
      }
      background: #fff;
      @include themify($themes) {
        color: themed('backgroundColor') !important;
      }
      display: inline-block;
      box-shadow: none;
      margin-left: 15px;
      padding: 4px 12px 4px 12px;
      z-index: 1;
      &:disabled {
        color: $white;
        opacity: 0.7;
      }
      .add-icon-border {
        width: 16px;
        height: 16px;
      }
    }
  }
  .textAlignCenter {
    text-align: center;
  }
  .table-wrapper {
    max-height: 270px;
    overflow: auto;
  }
  .labelDisabled {
    label,
    div {
      color: rgba(0, 0, 0, 0.38);
    }
  }
  .street {
    div {
      div {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  .DatePickerComponent {
    width: 100% !important;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .searchDiv {
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }
    .search-submit-btn {
      cursor: pointer;
    }
  }
  .SearchIconclr {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
  .insured-backdrop {
    z-index: 0 !important;
    background-color: rgba(0, 0, 0, 0);
  }
  .action-button {
    justify-content: inherit;
  }
  .line-height-16{
    line-height: 16px;
  }
  .modal-description {
    text-align: center;
    &__emphasis {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }
  .color-red {
    color: #ff0000;
    svg {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .publish-icon {
    cursor: pointer;
    @include themify($themes) {
      color: themed('backgroundColor');
    }
    &:hover {
      cursor: pointer;
    }
  }
  .MuiSelect-select:not([multiple]) option,
  .MuiSelect-select:not([multiple]) optgroup,
  .MuiSelect-select.MuiSelect-select,
  .MuiInputBase-input {
    color: #3a4b5e;
    font-weight: 600;
    font-family: manrope, sans-serif;
  }
  .carrier-risk-consent-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6)
  }
}

.table-wrapper {
  .ams-radio-btn {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}

.eoi-file-name-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  @include themify($themes) {
    color: themed('backgroundColor');
  }
}

@media (max-width: 767px) {
  .ams-modal {
    .resp-mb-20 {
      margin-bottom: 20px;
    }
    .mb-4 {
      margin-bottom: 0px !important;
    }
    .searchDiv {
      margin-bottom: 20px;
    }
    .address-wrap {
      .mb-20 {
        margin-bottom: 20px;
      }
    }
    .resp-mt-4 {
      margin-top: 20px;
    }
    .Res-display {
      display: flex;
    }
    .custom-id-res {
      margin-top: 0px;
    }
  } 
}
@media (max-width: 576px) {
  .ams-modal {
    .Res-display {
      display: block;
    }
  }
}
