
@import "../../../stylesheets/theme/theme.scss";
@import "../../../stylesheets/theme/theme-variables";
.product-btn-icon.active {
  @include themify($themes) {
    box-shadow: 0 0 0px 1px themed("backgroundColor");
  }
}
.product-btn-icon.Mui-disabled {
    color: #000 !important;
}
.adhoc-org-modal {
  .MuiDialog-paperWidthMd {
    width: 744px;
  }
  .heading {
    font-size: 18px;
    font-family: "Manrope", sans-serif;
    font-weight: 500;
  }
  .padding-16-24 {
    padding: 16px 24px;
  }
  .red-color {
    color: red;
  }
  .adhoc-dialogContentWrap {
    .adhoc-org-item {
      border: 1px solid #dbdbdb;
      border-radius: 6px;
      text-align: center;
      cursor: pointer;
      position: relative;
      height: 88px;
      padding: 15px 20px 10px 10px;
      img {
        max-width: 110px;
        height: 61px;
        object-fit: scale-down;
      }
    }
    .adhoc-org-item.active {
      @include themify($themes) {
        border: 1px solid themed("backgroundColor");
        box-shadow: 0px 0px 10px themed("content-box-color");
      }
    }
    .product-item {
      min-height: 120px;
      color: #00cf83;
      width: 100%;
      .product-label {
        @include themify($themes) {
          color: themed("combination-theme-color");
          font-weight: bold;
        }
        margin-top: 6.2px;
      }
      div {
        display: grid;
      }
    }
    .product-item.active {
      @include themify($themes) {
        box-shadow: 0 0 0px 1px themed("backgroundColor");
      }
    }

    .product-item.disabled {
      @include themify($themes) {
        border-color: themed("backgroundColor");
        box-shadow: 0px 0px 10px themed("content-box-color");
      }
      border: 1px solid;
      cursor: default;
      background-color: transparent;
    }
  }

  .active-marker {
    position: absolute;
    top: 3px;
    right: 3px;
    @include themify($themes) {
      color: themed("backgroundColor");
    }
  }
  .btn-continue {
    margin: 10px 0 20px;
    color: #ffffff;
    border-radius: 25px;
    width: 100%;
    max-width: 151px;
    height: 45px;
    box-shadow: none !important;
    @include themify($themes) {
      background-color: themed("backgroundColor");
    }
    color: #ffffff;
  }
  .org-mdl {
    width: 100%;
    margin: 0 auto;
    max-width: 560px;
    display: flex;
    justify-content: center;
    .prod-div {
      width: 29%;
      margin: 10px;
    }
    .prod-div-4 {
      width: 30%;
      margin: 10px;
    }
  }
}
@media (max-width: 576px) {
  .organizationModalWrap {
    .adhoc-dialogContentWrap {
      .product-item {
        min-height: auto;
      }
    }
    .org-mdl {
      display: block;
      .prod-div {
        width: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .organizationModalWrap {
    .adhoc-dialogContentWrap {
      .adhoc-org-item {
        img {
          max-width: -webkit-fill-available;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .organizationModalWrap {
    .MuiDialog-paperWidthSm {
      width: 100%;
      max-width: 600px;
    }
  }
}
