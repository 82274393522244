.show-image-wrapper {
  .image-logo {
    width: 60px;
    height: 40px;
    object-fit: contain;
  }
  .skeleton-css {
    margin-top: -1.4em;
    margin-bottom: 0px;
  }
  .image-rendering-logo {
    width: 60px;
    height: 40px;
    object-fit: contain;
  }
}
