@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme';

.quote-list-wrapper {
  .quote-list-container {
    .quote-card {
      margin-bottom: 3rem;
      margin-top: 2rem;
      &:last-of-type {
        .green-div {
          & :after {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
        }
      }
    }
  }
  .btnWrap {
    justify-content: center;
    display: flex;
    margin-top: 20px;

    button {
      background: transparent;
      @include themify($themes) {
        border: 1px solid themed('backgroundColor');
        color: themed('backgroundColor');
      }
      box-shadow: none;
      border-radius: 25px;
      width: auto;
      font-size: 0.875rem;
      font-weight: 500;
      max-width: 230px;
      padding: 3px 14px;
      margin-right: 10px;

      .dashboard-icon,
      img {
        width: 34px;
        height: 28px;
        padding-right: 10px;
      }

      .iconCar {
        width: 40px;

        background-size: contain;
        height: 20px;
        background-repeat: no-repeat;
      }

      .iconFlood {
        width: 40px;
        background-image: url('../../../assets/images/flood-green.svg');
        background-size: contain;
        height: 20px;
        background-repeat: no-repeat;
      }
    }
  }

  .expandCollapseBtn {
    display: block;
    position: relative;
    margin: 0 auto;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: bold;
    outline: none;
    user-select: none;

    span {
      @include themify($themes) {
        color: themed('backgroundColor');
        border-bottom: 2px solid themed('purchaseByPhone') !important;
      }
      padding-bottom: 2px;
    }
  }

  .quote-list-collapsed {
    display: none !important;
  }

  .quote-list-expanded {
    display: block !important;
  }

  .quote-card {
    svg {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
    .mx-w {
      max-width: 1250px;
    }

    .speech-bubble {
      position: absolute;
      @include themify($themes) {
        background: themed('lowestPriceBgColor');
      }
      border-radius: 0.4em;
      top: -10px;
      padding: 5px;
      width: 140px;
      height: 36px;

      color: $white;

      p {
        margin-bottom: 5px;

        span {
          vertical-align: text-bottom;
        }
      }

      .tick-circle {
        margin-right: 5px;
        vertical-align: text-bottom;
        font-size: 24px;
        color: $white;
      }
    }
    .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 55%;
      width: 0;
      height: 0;
      border: 15px solid transparent;
      @include themify($themes) {
        border-top-color: themed('lowestPriceBgColor');
      }
      border-bottom: 0;
      margin-left: -22px;
      margin-bottom: -14px;
    }
    .qoute-tooltip {
      top: 0;
      display: inline-block;
      right: 50px;
      position: inherit;
      .tooltip-icon {
        width: 20px;
        background-position: 1px 4px;
        background-size: contain;
        height: 20px;
        background-repeat: no-repeat;
        cursor: pointer;

        @include themify($themes) {
          @if (themed('buttonBgColor') ==$hippo-green) {
            background-image: url('../../../assets/images/tooltip-opendoor.svg');
          } @else {
            background-image: url('../../../assets/images/tooltip-better.svg');
          }
        }
      }
    }

    .hide-lowest-price {
      display: none !important;
    }

    .card-img-left {
      width: 142px;
      height: 142px;
    }

    .btn {
      border: none;
      border-radius: 25px;
      font-size: 15px;
      font-weight: 400;
      padding: 15px 25px;
      min-width: 215px;
      width: 35%;
      line-height: 20px;
      @include themify($themes) {
        background-color: themed('purchaseButtonBgColor');
      }
      letter-spacing: 1px;
    }

    .purchase-by-phone {
      @include themify($themes) {
        background-color: themed('purchaseByPhone');
      }
      .purchase-by-phone-white-btn {
        color: #757ea3;
        background-color: #eff2f1;
      }
    }

    .col-img {
      object-fit: cover;
      text-align: center;
      padding: 10px 40px 10px 40px;
    }
    .ml-30 {
      margin-left: 12px;
    }

    .button-wrap {
      margin: 0 auto;
    }
    .result-col {
      vertical-align: middle;
      color: rgb(150, 150, 150);
      min-width: 150px;
      font-family: 'Manrope', sans-serif;
      .w-13 {
        width: 13%;
        flex: 0 0 13.666667%;
      }
      .row {
        margin-left: 0px;
        margin-right: 0px;
      }

      &.row {
        margin-left: 0px;
        margin-right: 0px;
      }

      .card-font {
        float: inherit;
        /* letter-spacing: -1px; */
        font-size: 27px;
        font-weight: 100;
        color: $black;
      }

      .price-font {
        font-size: 27px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 48px;
        padding: 5px 0px;
        color: $white;
      }

      .green-div {
        background: $hippo-green;
        color: $white;
        @include themify($themes) {
          background-color: themed('otherPriceBoxColor');
        }
        padding-right: 0px;
        padding-left: 0px;
        & :after {
          content: '';
          width: 100%;
          position: absolute;
          @include themify($themes) {
            background-color: themed('backgroundColor');
            opacity: 0.2;
          }
          height: auto;
          min-height: 150px;
          top: 123px;
          left: 0;
        }

        p {
          margin-bottom: 5px;
          padding-right: 10px;
        }
      }

      .quote-lowest-price {
        @include themify($themes) {
          background-color: themed('lowestPriceBoxColor');
        }
      }

      .coverage-label {
        font-size: 14px;
        font-weight: 100;
      }
    }

    .container-fluid {
      padding-left: 80px;
      padding-right: 80px;
    }
    .fa-info-circle {
      margin-left: 5px;
      @include themify($themes) {
        color: themed('buttonBgColor') !important;
      }
    }
    .sec-insurance-list {
      .row {
        margin-left: 0px;
        margin-right: 0px;
      }
      .bg-blue-div {
        padding: 0px;

        .bg-blue {
          position: absolute;
          background: #c6f5ff;
          padding: 5px 10px;
          width: auto;
          z-index: 1;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          color: #3a96ed;
          min-width: 210px;
          padding-left: 20px;
          font-weight: 500;
          -moz-user-select: none;
          -khtml-user-select: none;
          -webkit-user-select: none;
          -ms-user-select: none;
          user-select: none;

          .exceptions-container div:not(:first-child) {
            display: none;
          }
          .exception-expand {
            position: absolute;
            top: 6px;
            font-size: 13px;
            right: 26px;
            cursor: pointer;
            letter-spacing: 1px;
          }

          .expand-icon {
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 15px;
            top: 9px;
            right: 6px;
            cursor: pointer;
            background-image: url('../../../assets/images/expand-icon.png');
            background-position: initial;
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
        .exception-hand {
          cursor: pointer;
          text-align: center;
        }
        .expand {
          .exceptions-container div:not(:first-child) {
            display: block;
          }
          .expand-icon {
            position: absolute;
            display: inline-block;
            width: 15px;
            height: 15px;
            top: 9px;
            right: 6px;
            cursor: pointer;
            background-image: url('../../../assets/images/collapse-icon.png');
            background-position: initial;
            background-repeat: no-repeat;
            background-size: 15px 15px;
          }
        }
      }
    }
  }

  .collapsed-quote-list {
    .quote-card:first-of-type {
      .green-div {
        & ::before {
          content: '';
          width: 100%;
          z-index: -1;
          position: absolute;
          @include themify($themes) {
            background-color: themed('backgroundColor');
            opacity: 0.2;
          }
          height: auto;
          min-height: 49px;
          top: -65px;
          left: 0;
        }
      }
    }
  }

  .speech-bubble {
    p {
      margin-bottom: 5px;
    }
  }

  .lowest-price-icon {
    background-image: url('../../../assets/images/lowest-price-white.png');
    height: 30px;
    background-position: initial;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    span {
      margin-left: 27px;
      font-size: 13px;
    }
  }
  .mob-price-div {
    display: none;
  }

  .no-quote-available-container {
    font-size: 25px;
    padding: 30px;
    text-align: center;
    color: $gray-700;
  }
}

@media (max-width: 1000px) {
  .quote-list-wrapper {
    .quote-card {
      .sec-insurance-list {
        .bg-blue-div {
          .bg-blue {
            .exceptions-container div:not(:first-child) {
              display: none;
            }
            .exception-expand {
              display: none;
            }
            .expand-icon {
              position: absolute;
              display: inline-block;
              width: 15px;
              height: 15px;
              top: 9px;
              right: 6px;
              cursor: pointer;
              background-image: url('../../../assets/images/expand-icon.png');
              background-position: initial;
              background-repeat: no-repeat;
              background-size: 15px 15px;
            }
          }
          .expand {
            .exceptions-container div:not(:first-child) {
              display: block;
            }
            .expand-icon {
              position: absolute;
              display: inline-block;
              width: 15px;
              height: 15px;
              top: 9px;
              right: 6px;
              cursor: pointer;
              background-image: url('../../../assets/images/collapse-icon.png');
              background-position: initial;
              background-repeat: no-repeat;
              background-size: 15px 15px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1180px) {
  .quote-list-wrapper {
    .quote-card {
      .speech-bubble {
        top: -16px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1250px) {
  .quote-list-wrapper {
    .quote-card {
      margin-bottom: 2rem;
      margin-top: 2rem;
      .green-div {
        background: $white !important;
        padding: 0px;
        & :after {
          content: '';
          width: 100%;
          position: absolute;
          background: none !important;
          height: auto;
          min-height: 81px;
          top: 126px;
          left: 0;
        }
        & :before {
          content: '';
          width: 100%;
          position: absolute;
          background: none !important;
          height: auto;
          min-height: 81px;
          top: 126px;
          left: 0;
        }
        .col-md-12 {
          padding-left: 11px;
          padding-right: 11px;
        }
      }
      .col-img {
        padding: 0px;
        flex: 0 0 100%;
        max-width: 100%;
        img {
          position: relative;
          overflow: hidden;
          object-fit: cover;
          float: left;
          max-width: 140px;
        }
      }
      .qoute-tooltip {
        .tooltip-icon {
          width: 20px;
          background-size: contain;
          height: 20px;
          vertical-align: middle;
          background-position: 1px 4px;
          background-repeat: no-repeat;
          margin-top: 7px;
        }
      }
      .sec-insurance-list {
        .offset-lg-2 {
          margin-left: 0%;
        }

        .col-lg-5 {
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .speech-bubble {
          display: none;
        }
        .bg-blue-div {
          padding: 0px;
          .bg-blue {
            margin: 0 auto;
            width: 80%;
            max-width: 80%;
            position: relative;
          }
        }

        .fm {
          flex: 100%;
          max-width: 100%;
          border-bottom: 1px solid #efefef;
        }
        .table-row {
          position: relative;
          border-radius: 15px;
          margin: 15px 20px 40px;
          padding: 25px;
          background-color: $white;
        }

        .coverage-label {
          color: $gray-600;
          font-size: 14px;
          min-width: 150px;
          text-align: left;
          float: left;
          line-height: 40px;
        }
        .coverage-label span {
          float: right;
          line-height: 40px;
        }
        .content-box {
          .col-img {
            border-right: 0px;
            text-align: center;
          }
        }
      }
      .btn-success {
        height: auto;
      }
      .btn {
        font-size: 13px;
        font-weight: 300;
        padding: 15px 25px;
        min-width: 215px;
        width: 35%;
        line-height: 20px;
      }
      .speech-bubble {
        position: absolute;
        top: 50%;
        padding: 5px;
        width: 140px;
        right: 0;
        height: 36px;
        color: $white;
        p {
          color: $white;
        }
      }
      .speech-bubble:after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 55%;
        width: 0;
        height: 0;
        border: 15px solid transparent;
        @include themify($themes) {
          border-top-color: themed('lowestPriceBgColor');
        }
        border-bottom: 0;
        margin-left: -22px;
        margin-bottom: -14px;
      }
      .result-col {
        padding: 25px;
        .green-div {
          background-color: $white !important;
          margin: 0px;
          .align-self-center {
            padding-right: 16px;
            padding-left: 16px;
          }
          .coverage-label {
            font-weight: 500;
            color: #000000;
            font-size: 15px;
            min-width: 0px;
          }
        }
        .price-font {
          float: right;
          font-size: 18px;
          color: $black;
          font-weight: 500;
          line-height: 40px;
        }
        .card-font {
          float: right;
          color: $black;
          font-size: 15px;
          font-weight: 300;
          line-height: 40px;
        }
      }

      .mob-price-div {
        position: relative;
        float: right;
        padding: 6px 11px 6px 38px;
        border-radius: 10px;
        font-weight: 400;

        @include themify($themes) {
          color: themed('lowestPriceBgColor');
          border: 1px solid themed('lowestPriceBgColor');

          @if (themed('buttonBgColor') == $hippo-green) {
            background: url('../../../assets/images/lowestprice-mobile-purple.png')
              no-repeat
              5px
              center;
          } @else if (themed('buttonBgColor') == $hippo-blue) {
            background: url('../../../assets/images/lowestprice-mobile-firstamerican.png')
              no-repeat
              5px
              center;
          } @else {
            background: url('../../../assets/images/lowestprice-mobile-purple.png')
              no-repeat
              5px
              center;
          }
        }
        text-transform: uppercase;
        font-size: 12px;
        line-height: 12px;
        margin-top: 47px;
        span {
          display: block;
          padding-top: 5px;
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .main {
    .header-addressbar {
      .table-right-wrapper {
        padding: 20px;
      }
    }
  }

  .result-col .col-md-2 {
    padding-left: 11px;
    padding-right: 11px;
  }

  .quote-list-wrapper {
    .quote-card {
      margin-bottom: 2rem;
      margin-top: 2rem;
      .ml-30 {
        margin-left: 0px;
      }
      .result-col {
        .green-div {
          background: $white !important;
          background-color: $white !important;
          padding: 0px;
          flex: 100%;
          max-width: 90%;
          margin: 0 auto;
          & :after {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
          & :before {
            content: '';
            width: 100%;
            position: absolute;
            @include themify($themes) {
              background-color: themed('backgroundColor');
              opacity: 0 !important;
            }
            height: auto;
            min-height: 150px;
            top: 123px;
            left: 0;
          }
          .col-md-12 {
            padding-left: 13px;
            padding-right: 11px;
          }
        }
        padding: 25px;
        .green-div,
        .quote-lowest-price {
          background-color: $white !important;
          width: 50%;
          text-align: center;
          margin: 0 auto;
          margin-bottom: 19px;

          .coverage-label {
            font-weight: 500;
            color: #000000;
            font-size: 15px;
            min-width: 0px;
          }
        }
        .price-font {
          float: right;
          font-size: 18px;
          color: $black;
          font-weight: 500;
          line-height: 40px;
        }
        .card-font {
          float: right;
          color: $black;
          font-size: 15px;
          font-weight: 300;
          line-height: 40px;
        }
      }
      .btn {
        font-size: 13px;
        font-weight: 300;
        padding: 15px 25px;
        min-width: 215px;
        width: 35%;
        line-height: 20px;
      }
      .mx-w {
        max-width: 720px;
      }

      .col-img {
        padding: 0px;
        img {
          position: relative;
          overflow: hidden;
          object-fit: cover;
          float: left;
          max-width: 99px;
          margin-left: 10px;
        }
      }
      .qoute-tooltip {
        .tooltip-icon {
          width: 20px;
          background-size: contain;
          height: 20px;
          vertical-align: middle;
          background-repeat: no-repeat;
          background-position: 1px 4px;
          margin-top: 7px;
        }
      }
      .sec-insurance-list {
        .bg-blue-div {
          padding: 0px;
          .bg-blue {
            margin: 0 auto;
            width: 80%;
            font-size: 12px;
            position: relative;
            .expand-icon {
              top: 6px;
            }
          }

          .expand {
            .expand-icon {
              top: 6px;
            }
          }
        }

        .fm {
          flex: 100%;
          max-width: 100%;
          border-bottom: 1px solid #efefef;
        }
        .table-row {
          position: relative;
          border-radius: 15px;
          margin: 15px 20px 40px;
          padding: 25px;
          background-color: $white;
        }

        .coverage-label {
          color: $gray-600;
          font-size: 14px;
          text-align: left;
          float: left;
          font-weight: 100;
          line-height: 40px;
        }
        .coverage-label span {
          float: right;
          line-height: 40px;
        }
        .content-box {
          .col-img {
            border-right: 0px;
            text-align: center;
          }
        }
      }

      .speech-bubble {
        display: none;
        p {
          color: $white;
        }
      }
    }
    .mob-price-div {
      position: relative;
      float: right;
      background-position: 7px center;
      background-repeat: no-repeat;

      @include themify($themes) {
        color: themed('lowestPriceBgColor');
        border: 1px solid themed('lowestPriceBgColor');
        @if (themed('buttonBgColor') == $hippo-green) {
          background: url('../../../assets/images/lowestprice-mobile-purple.png')
            no-repeat
            5px
            center;
        } @else if (themed('buttonBgColor') == $hippo-blue) {
          background: url('../../../assets/images/lowestprice-mobile-firstamerican.png')
            no-repeat
            5px
            center;
        } @else {
          background: url('../../../assets/images/lowestprice-mobile-purple.png')
            no-repeat
            5px
            center;
        }
      }

      padding: 6px 13px 6px 42px;
      border-radius: 10px;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 12px;
      margin-top: 26px;
      span {
        display: block;
        padding-top: 5px;
      }
    }
    .result-model {
      .modal-body {
        .modal-title {
          font-family: 'Manrope', sans-serif;
          font-size: 32px;
          font-weight: 400;
          margin-top: 0.5rem !important;
        }
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .quote-list-wrapper {
    .quote-card {
      .result-col {
        .w-13 {
          width: 10%;
          flex: 0 0 10.666667%;
        }
      }
      .sec-insurance-list {
        .bg-blue-div {
          .bg-blue {
            max-width: 480px;
          }
        }
      }
    }
  }
}
