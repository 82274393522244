@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.rw-mb-res-4 {
  margin-bottom: 1.5rem;
}

.sfdc-ams-details-wrapper {
  .ams-form {
    .MuiInputBase-input.Mui-disabled {
      opacity: 1;
      color: #afaeae !important;
    }
    .MuiTypography-h6,
    button {
      font-family: 'Manrope', sans-serif !important;
    }
    .carrier-risk-consent-label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
    }
    .custom-id-res {
      margin-top: 1.5rem;
    }
    .rw-mb-res-4 {
      margin-bottom: 1.5rem;
    }
  }

  .sfdc-form-container {
    #ams-dialog-title {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }

    label {
      color: rgba(0, 0, 0, 0.6);
    }

    .ams-dropdown {
      padding-left: 3px;
    }
    .navigation-btn,
    .navigation-next-btn {
      box-shadow: 0px 3px 6px #a2a0a099;
      border-radius: 25px;
      color: $white;
      width: 100px;
      text-transform: capitalize;

      @include themify($themes) {
        background-color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor');
      }

      &:disabled {
        color: $white;
        opacity: 0.7;
      }
    }

    .navigation-back-btn {
      padding-right: 10px;
      border-radius: 16px;
      text-transform: capitalize;

      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
      }

      margin-right: 20px;
      width: 100px;
      height: 35px;
    }

    .DatePickerComponent {
      margin: 0px;

      svg {
        @include themify($themes) {
          color: themed('combination-theme-color');
        }
      }
    }

    .effective-date-control {
      .DatePickerComponent {
        @extend .DatePickerComponent;
      }
    }

    .textAlignCenter {
      text-align: center;
    }

    .table-wrapper {
      max-height: 270px;
      overflow: hidden;
    }

    .labelDisabled {
      label,
      div {
        color: rgba(0, 0, 0, 0.38);
      }
    }

    .street {
      div {
        div {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }

    .searchDiv {
      input[type='search']::-webkit-search-decoration,
      input[type='search']::-webkit-search-cancel-button,
      input[type='search']::-webkit-search-results-button,
      input[type='search']::-webkit-search-results-decoration {
        -webkit-appearance: none;
      }

      .search-submit-btn {
        cursor: pointer;
      }
    }

    .SearchIconclr {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }

    .insured-backdrop {
      z-index: 0 !important;
      background-color: rgba(0, 0, 0, 0);
    }

    .line-height-16 {
      line-height: 16px;
    }

    .modal-description {
      text-align: center;

      &__emphasis {
        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
    }

    .color-red {
      color: #ff0000;

      svg {
        font-size: 16px;
        cursor: pointer;
      }
    }

    .publish-icon {
      cursor: pointer;

      @include themify($themes) {
        color: themed('backgroundColor');
      }

      &:hover {
        cursor: pointer;
      }
    }

    .MuiSelect-select:not([multiple]) option,
    .MuiSelect-select:not([multiple]) optgroup,
    .MuiSelect-select.MuiSelect-select,
    .MuiInputBase-input {
      color: #3a4b5e;
      font-weight: 600;
      font-family: manrope, sans-serif;
    }
  }

  .table-wrapper {
    .ams-radio-btn {
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
  }

  .download-icon {
    cursor: pointer;

    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }

  .eoi-file-name-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100% !important;
    display: flex;

    @include themify($themes) {
      color: themed('backgroundColor');
    }

    .eoi-upload-close-icon {
      position: absolute;
      right: 0;
    }
  }
  .alignLeft {
    display: flex;
    align-items: center;
  }

  .amsContainer {
    padding: 24px;
  }
}
@media (min-width: 1200px) {
  .sfdc-form-container {
    max-width: 750px;
  }
}
@media (max-width: 767px) {
  .sfdc-ams-details-wrapper {
    .resp-mb-20 {
      margin-bottom: 20px;
    }

    .rw-mb-res-4 {
      margin-bottom: 0px !important;
    }
    form .col-md-6 .row {
      margin: 0px;
    }

    .resp-mt-4 {
      margin-top: 20px;
    }

    .Res-display {
      display: flex;
    }
    .custom-id-res {
      margin-top: 0px;
    }

    .ams-form {
      .searchDiv {
        margin-bottom: 20px;
      }

      .address-wrap {
        .mb-20 {
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .ams-modal {
    .Res-display {
      display: block;
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .w-100 {
    width: 100%;
  }
}
