@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
//feedback button
.feedback-wrap {
  padding: 0px 0px 0px 0px;
  text-align: center;
  margin: 0px;
  width: 160px;
  height: 40px;
  border-radius: 5px 0px 0px 5px;
  z-index: 15;
  border-radius: 5px 5px 0px 0px;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  transform-origin: bottom right;
  position: fixed;
  right: 0px;
  top: 35%;
  @include themify($themes) {
    background: themed('backgroundColor');
  }

  cursor: pointer;
  p {
    color: #fff;
    display: inline-block;
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    img {
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      margin-right: 20px;
      vertical-align: middle;
      width: 25px;
      height: 25px;
    }
  }
}
@media(max-width:600px){
  .feedback-wrap {
    top: 40%;
  }
}