@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.carrier-error-message {
  text-align: left !important;
  padding-top: 0px;
  padding-left: 3px;
}

.select-all-container {
  text-align: right;
  padding-right: 3px;
}

.select-all-label {
  margin-bottom: 0px;
}

.remove-padding {
  padding: 0px;
}

.carrier-grid-wrapper {
  min-height: 155px;
  max-height: 415px;
  overflow-y: auto;
  padding: 15px 10px 8px 8px;

  &.grid-inner-shadow {
    -moz-box-shadow: 0 10px 10px -14px rgba(0, 0, 0, 0.8) inset,
      0 -10px 10px -14px rgba(0, 0, 0, 0.8) inset;
    -webkit-box-shadow: 0 10px 10px -14px rgba(0, 0, 0, 0.8) inset,
      0 -10px 10px -14px rgba(0, 0, 0, 0.8) inset;
    box-shadow: 0 10px 10px -14px rgba(0, 0, 0, 0.8) inset,
      0 -10px 10px -14px rgba(0, 0, 0, 0.8) inset;
  }

  .empty-carrier-list-message {
    position: relative;
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
    padding: 42px;
  }

  .carrier-grid-column {
    padding-right: 0;
    padding-bottom: 25px;
  }

  .carrier-tooltip-wrapper {
    z-index: 5000 !important;
    display: inline-block;
    text-align: left;
    vertical-align: top;
    border: 1px solid;
    border-color: #bdbdbd;
    border-radius: 5px;
    user-select: none;
    padding: 0;
    width: 94%;
    height: 100px !important;
    &:hover,
    &:focus {
      cursor: pointer;
      -moz-box-shadow: 0 0 4px $gray-700;
      -webkit-box-shadow: 0 0 4px $gray-600;
      box-shadow: 0 0 4px $gray-600;
      outline: 0;
    }

    .card-container {
      vertical-align: initial;
      height: 100%;
      .check-mark {
        position: absolute;
        right: 5px;
        top: 5px;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: 1px solid #bdbdbd;
      }

      .uncheck-mark {
        @extend .check-mark;
        background-image: url('../../../assets/images/unchecked.svg') !important;
        border: none !important;
      }

      .highlight-text-color {
        color: #00cf83;
        font-weight: 500;
      }

      .custom-tooltip-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .tooltip-text {
          display: none;
          position: absolute;
          top: -12%;
          z-index: 8008 !important;
          overflow: visible;
          width: max-content;
          min-width: 215px;
          max-width: 300px;
          max-height: 215px;
          padding: 12px 15px 10px 25px;
          margin-top: 10px;
          border: 0.5px solid;
          border-color: #bdbdbd;
          border-radius: 6px;
          text-align: left;
          color: #000;
          background: #f6f6f6;
          outline: 0;
          pointer-events: none;
          cursor: default;
          opacity: 0;
          transition: opacity 0.5s;
          animation-timing-function: ease;
        }

        .multiple-row-details {
          width: 240px !important;
        }

        .tooltip-text-before {
          display: inline;
          top: 34px;
          content: '';
          position: absolute;
          border: solid;
          border-color: #f6f6f6 transparent;
          border-width: 0 0.5em 0.5em 0.5em;
          z-index: 6000;
        }

        .tooltip-text-after {
          top: -20px;
          content: ' ';
          display: block;
          height: 20px;
          position: absolute;
          left: 20px;
        }

        .leftside-tooltip {
          position: absolute;
          right: 4px;
          width: calc(100% + 23px);
          height: 100px;
          z-index: -1;
          .lastRow {
            top: -119% !important;
            &:before {
              top: 77% !important;
            }
            &:after {
              top: 75% !important;
              right: -8px !important;
            }
          }
          .lastRowSingleData {
            top: -10% !important;
            &:before {
              top: 45.5% !important;
              right: -11px !important;
            }
            &:after {
              top: 41.5% !important;
              right: -8px !important;
            }
          }
          .tooltip-text-box {
            @extend .tooltip-text;
            z-index: 8000;
            right: 93%;
            box-shadow: -2px 3px 5px #bdbdbd;
            &:before {
              @extend .tooltip-text-before;
              transform: rotate(90deg);
              -webkit-transform: rotate(90deg);
              right: -11px;
              top: 42px;
            }
            &:after {
              @extend .tooltip-text-before;
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              border-left: 0px solid #bdbdbd !important;
              border-right: 8px solid #bdbdbd;
              border-top: 8px solid transparent !important;
              border-bottom: 8px solid transparent !important;
              z-index: 1 !important;
              top: 38px;
              right: -8px;
            }
          }
        }

        .rightside-tooltip {
          position: absolute;
          left: 4px;
          width: calc(100% + 23px);
          height: 100px;
          z-index: -1;
          .lastRow {
            top: -100% !important;
            &:before {
              top: 74% !important;
              left: -11px !important;
            }
            &:after {
              top: 72% !important;
              left: -9px !important;
            }
          }
          .lastRowSingleData {
            top: -10% !important;
            &:before {
              top: 45.5% !important;
              left: -11px !important;
            }
            &:after {
              top: 41.5% !important;
              left: -8px !important;
            }
          }
          .tooltip-text-box {
            @extend .tooltip-text;
            z-index: 9999;
            left: 93%;
            box-shadow: 2px 3px 5px #bdbdbd;
            &:before {
              @extend .tooltip-text-before;
              transform: rotate(-90deg);
              -webkit-transform: rotate(-90deg);
              left: -11px;
              top: 42px;
            }
            &:after {
              @extend .tooltip-text-before;
              border-left: 0px solid #bdbdbd !important;
              border-right: 8px solid #bdbdbd;
              border-top: 8px solid transparent !important;
              border-bottom: 8px solid transparent !important;
              z-index: 1 !important;
              top: 38px;
              left: -8px;
            }
          }
        }

        .tooltip-scroll {
          overflow-y: auto;
          width: auto;
          max-height: 185px;
          z-index: 8009;
          padding: 5px 5px 0px 0px;
          .tooltip-detail {
            font-size: 14px;
            ul {
              word-break: break-all;
              list-style-type: none;
              padding-left: 0;
              line-height: 20px;
              margin-bottom: 0.5rem;
            }
            &:last-child {
              .hr-divider {
                display: none;
              }
            }
            .hr-divider {
              margin: 1rem 0;
              width: 95%;
            }
          }
        }
        &:hover {
          .tooltip-text-box {
            display: block;
            pointer-events: auto;
            opacity: 1;
          }
          .rightside-tooltip,
          .leftside-tooltip {
            z-index: 1;
          }
        }

        .carrier-logo {
          max-height: 100px;
          min-width: 100px;
        }
      }
    }
  }

  .active.carrier-tooltip-wrapper {
    @include themify($themes) {
      box-shadow: 0 0 0 0.5px themed('buttonBgColor'), 0 0 0 1px $white inset, 0 0 4px #868e96;
      border: 1px solid themed('buttonBgColor');
    }
    .check-mark {
      background-size: 21px;
      @include themify($themes) {
        background-image: url('../../../assets/images/checked.svg') !important;
      }
      border: none !important;
    }
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .carrier-grid-wrapper {
    .rightside-tooltip,
    .leftside-tooltip {
      display: none !important;
      opacity: 0;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .rightside-tooltip,
  .leftside-tooltip {
    top: -2% !important;
    .lastRowSingleData {
      top: -10% !important;
    }
  }
}
