.sec-insurance-list {
  .result-col {
    min-height: 130px;
  }

  .width-col-md-10 {
    padding: 20px;
  }
  .container {
    .content-box {
      .error-status-text {
        cursor: pointer;
        z-index: 0;
        font-size: 16px;
        color: blue;
        text-decoration: underline;
      }
    }
  }
}
