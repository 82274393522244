@import '../../stylesheets/theme/theme';
@import '../../stylesheets/theme/theme-variables';

.logout-wrapper {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .center-div {
    display: table;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    padding: 20px;
    border-radius: 15px;
    .logo-img {
      margin-top: 20px;
    }
    .login {
      text-align: center;
      .login-btn {
        font-size: 15px;
        width: 100%;
        max-width: 160px;
        height: 40px;
        margin-bottom: 30px;
        border-radius: 25px;
        border: none;
        box-shadow: 0 4px 0 0 var(--button-box-side-color),
          0 calc(4px * 2) calc(4px / 2) 0 rgba(0, 0, 0, 0.08) !important;
        font-family: 'Manrope', sans-serif;
        letter-spacing: 1.65px;

        @include themify($themes) {
          background-color: themed('backgroundColor') !important;
        }
      }
    }
  }
  .report-logo{
    max-width: 200px;
    height: 90px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logout-wrapper {
    display: run-in;
    .center-div {
      display: run-in;
    }
  }
}
