@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.detialsDiv {
  .time-adess-rw {
    .lbl-select-div {
      line-height: 36px;
    }
    .input-lbl {
      font-size: 12px;
      color: rgb(130, 127, 127);
      padding-top: 12px;
    }

    .time-address-year {
      min-width: 100% !important;
      margin-top: 0px;
    }
    .padd-left-0 {
      padding-left: 0px;
      .year-span {
        font-size: 12px;
        color: #827f7f;
      }
    }

    .time-address-month {
      min-width: 100% !important;
      margin-top: 0px;
    }

    .time-address {
      position: absolute;
      right: -49px;
      bottom: 0;
    }
  }
  .pt-20 {
    padding-top: 20px;
  }
  .expiration_date,
  .policy_start_date {
    .DatePickerComponent {
      width: 100% !important;
      margin-bottom: 0px;
      margin-top: 0px;
      p {
        display: none;
      }
      .MuiInputAdornment-root{
        .MuiIconButton-root{
          @include themify($themes) {
            color: themed('combination-theme-color');
          }
          &.Mui-disabled{
            color: rgba(0, 0, 0, 0.38);
          }
        }
      }
    }
    .redBorder div::after {
      border-bottom: 2px solid red;
    }
  }
  .labelError {
    label {
      color: rgb(150, 150, 150) !important;
    }
  }
}
@media (max-width: 479px) {
  .detialsDiv {
    .time-adess-rw {
      .yr-select-div {
        width: 85%;

        margin-bottom: 20px;
      }
      .lbl-select-div {
        width: 15%;
        /*  margin-top: 2em; */
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .detialsDiv {
    .time-adess-rw {
      .year-span {
        font-size: 9px;
      }
      .yr-select-div {
        width: 90%;

        margin-bottom: 20px;
      }
      .lbl-select-div {
        width: 10%;
        /*  margin-top: 2em; */
      }
    }
  }
}
