@import '../../stylesheets/theme/theme';
@import '../../stylesheets/theme/theme-variables';

.bulk-quote-dialog-container {
  .success-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    svg {
      color: #868e96;
      font-size: 18px;
    }
  }
}

.bulk-quote-wrapper {
  min-height: calc(100vh - 225px);

  .bulkquote-table-wrapper {
    .file-link {
      color: $blue;
      cursor: pointer;
      margin: 0 auto;
      width: 25px;
      height: 25px;
    }

    .icon {
      width: 22px;
      vertical-align: bottom;
      height: 25px;
      margin-right: 8px;
    }
    .CardWrapper {
      box-shadow: 0px 0px 5px 0px #9a9a9a99;
      border-radius: 5px;
      .lbl {
        padding-top: 1%;
        @include themify($themes) {
          color: themed('backgroundColor');
        }
        span {
          font-weight: 500;
        }
      }
      .new-bulk-quote {
        justify-content: flex-end;
        padding-right: 25px;
      }
      .btnOutlined {
        background: 0% 0% no-repeat padding-box;
        border-radius: 25px;
        color: $white;
        margin-left: 11px;
        width: 172px;
        @include themify($themes) {
          border: 1px solid themed('backgroundColor');
        }
        span {
          @include themify($themes) {
            color: themed('backgroundColor');
          }
          text-transform: capitalize;
        }
      }
    }
  }

  .searchDiv {
    float: right;
    width: 70%;

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      -webkit-appearance: none;
    }

    .search-submit-btn {
      cursor: pointer;
    }
  }

  .SearchIconclr {
    @include themify($themes) {
      color: themed('backgroundColor');
    }
  }
}

#bulk-quote-dialog-title {
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    svg {
      color: #868e96;
      font-size: 18px;
    }
  }
  .MuiStepLabel-label.MuiStepLabel-active,
  .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: 300 !important;
  }
}

.bulk-quote-dialog-wrap {
  font-family: 'Roboto', sans-serif;

  .navigation-wrapper {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .navigation-btn,
  .navigation-next-btn {
    background: 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #a2a0a099;
    border-radius: 25px;
    color: $white;
    width: 100px;
    @include themify($themes) {
      background-color: themed('backgroundColor');
      border: 1px solid themed('backgroundColor');
    }

    &:disabled {
      color: $white;
    }
  }

  .navigation-back-btn {
    padding-right: 20px;
    border-radius: 16px;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
      border: 1px solid themed('backgroundColor') !important;
    }
    margin-right: 20px;
    width: 100px;
    height: 35px;
  }

  .org-divider {
    font-size: 18px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .carrier-org-title {
    padding-top: 12px;
  }

  .org-container {
    border: 1px solid #dbdbdb;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    position: relative;
    height: 88px;
    padding: 15px 20px 10px 10px;
    img{
      max-width: 100%;
      height: 61px;
      object-fit: scale-down;
    }
  }

  .org-code-input {
    margin: 0 auto;
    min-width: 390px;

    input {
      padding: 14px 14px;
    }

    :focus,
    :hover {
      outline: none;
    }
  }

  .selected-org {
    .org-container {
      @include themify($themes) {
        -webkit-box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset,
          0 0 4px #868e96;
        -moz-box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset,
          0 0 4px #868e96;
        box-shadow: 0 0 0 0.5px themed('backgroundColor'), 0 0 0 1px $white inset, 0 0 4px #868e96;
        border: 1px solid themed('backgroundColor');
      }
    }
  }

  .disabled-org {
    cursor: default;
  }

  .error-row {
    min-height: 24px;
    padding: 8px 20px 0px 20px;
    .error-message {
      width: 100%;
      height: auto;
      color: $red;
      font-size: 12px;
      word-break: break-word;
      text-align: center;
    }
    .org-error {
      width: 390px;
      margin: 0 auto;
      text-align: left;
    }
  }

  &.dialog-final-wrap {
    text-align: center;
    .upload-success-icon {
      font-size: 100px;
      @include themify($themes) {
        color: themed('backgroundColor');
      }
    }
    .upload-message {
      font-size: 20px;
      font-weight: 500;
    }
    .modal-close-btn {
      margin: 30px 0px 20px 0px;
      width: 175px;
    }
  }
  .error-lbl{
    color: #dc3545;
    font-size: 12px;
  }
}

@media (min-width: 320px) and (max-width: 768px) {
  .bulkquote-table-wrapper {
    .CardWrapper {
      .lbl {
        padding-top: 2%;
      }
      .bulk-quote-button {
        text-align: center;
        padding-bottom: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .bulkquote-table-wrapper {
    .CardWrapper {
      .lbl {
        padding-top: 2%;
      }
    }
    .searchDiv {
      float: left;
      width: 100%;
      div {
        margin-left: 0px;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #bulk-quote-dialog-title {
    .close-icon {
      padding-left: 5px;
    }
  }
}
