@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.number-stepper-wrapper {
  .fab-btn {
    width: 23px;
    display: flex;
    border-radius: 25px;
    height: 23px;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    color: #1f1f21;
    background: none;
    .svg-icon {
      font-size: 18px;
    }

    @include themify($themes) {
      border: 1px solid themed('backgroundColor') !important;
    }
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      background: none;
    }
  }

  .number-input {
    max-width: 120px;
    margin: 0 10px;
    input {
      padding: 0px;
    }
  }
}
