@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.MuiFormHelperText-root {
  line-height: 15px !important;
  font-size: 12px !important;
}
.property-info-form {
  .mb-30 {
    margin-bottom: 30px;
  }

  .main-heading {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 42px;
  }
  .sub-heading {
    text-align: left;
  }
  .sub-heading-text {
    font-size: 20px;
    font-weight: 600;
    @include themify($themes) {
      color: themed('backgroundColor') !important;
    }
  }
  .error-color {
    color: #f44336 !important;
  }

  .form-label {
    float: left;
    font-size: 12px;
    padding-bottom: 4px;
  }

  .prop-info-text-filled {
    width: 100%;
    margin-bottom: 30px;
    text-align: left;
  }

  .btnwrap {
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;

    .btnBackLink {
      padding-right: 20px;
      border-radius: 25px;
      width: 150px;
      height: 45px;
      text-transform: capitalize;

      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
        box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
      }

      svg {
        font-size: 14px;
      }

      &:hover {
        background: transparent;
      }

      .link-label {
        font-size: 0.875rem;

        @include themify($themes) {
          color: themed('backgroundColor');
        }
      }
    }

    .btnContinue {
      border-radius: 25px;
      width: 150px;
      height: 45px;
      color: #fff;
      box-shadow: none;
      text-transform: capitalize;
      cursor: pointer;

      @include themify($themes) {
        background-color: themed('backgroundColor') !important;
        box-shadow: 0px 0px 1px 0.2px themed('backgroundColor') !important;
      }
    }

    .btnGetQuoteLink {
      padding-right: 20px;
      border-radius: 16px;
      text-transform: capitalize;
      cursor: pointer;

      @include themify($themes) {
        color: themed('backgroundColor') !important;
        border: 1px solid themed('backgroundColor') !important;
      }

      margin-left: 20px;
    }
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .property-info-form {
    .main-heading {
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      color: #000000;
      line-height: normal;
      margin-bottom: 22px;
    }

    .btnwrap {
      display: block;

      .btnBackLink {
        margin-bottom: 20px;
      }
    }
  }
}
