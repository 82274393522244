@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.auto-prefill-modal{
    .auto-prefill-content{
        padding: 3em 4em 2em 4em !important;
       @include themify($themes){
            border: 3px solid themed('backgroundColor');
        }
    }
    .circle-div{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        position: relative;
        @include themify($themes){
            background-color: themed('vehicle-selection-border');
        }
        .icon-done{
            font-size: 6em;
            position: absolute;
            right: -18px;
            top: 0px;
            @include themify($themes) {
                color: themed("backgroundColor");
            }
        }
    }
     p{
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        color: #3A4B5E;
        font-family: "Manrope",sans-serif;
    }
    .text-2{
        line-height: 30px;
        text-align: left;
    }
    .btn-yes {
        @include themify($themes) {
          background-color: themed('backgroundColor');
          color: #fff;
          border-radius: 18px;
          margin: 10px;
        }
      }
      .btn-no {
        @include themify($themes) {
          color: themed('backgroundColor');
          border: 1px solid themed('backgroundColor');
          border-radius: 18px;
          margin: 10px;
        }
      }
}
@media (max-width:768px){
    .auto-prefill-modal{
        .auto-prefill-content{
            padding:30px !important;
        }
         p{
            line-height:normal;
        }
        
    }
}