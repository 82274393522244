@import '../../../stylesheets/theme/theme-variables.scss';
@import '../../../stylesheets/theme/theme';

.request-bind-wrap {
    text-align: center;
    overflow: hidden;

    .MuiDialog-paperWidthSm {
        max-width: 760px;
    }

    .request-bind-success {
        font-family: 'Manrope';
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #000000;
        padding-top: 3em
    }
    .request-bind-success_msg {
        font-size: 18px;
        font-family: 'Manrope';
        font-weight: 500;
        line-height: 25px;
        padding-bottom: 3em;
        color: #000000;
    }

    .content {
        padding-bottom: 0px;

        .request-bind-heading {
            font-family: 'Manrope';
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            color: #31355C;
            margin-bottom: 30px;
        }

        .request-bind-para {
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            color: #000000;
            margin-bottom: 20px;
            margin-top: 30px
        }

        .request-bind-para-que {
            @extend .request-bind-para;
            margin-bottom: 20px;
        }
    }

    .icon-request-bind {
        @include themify($themes) {
            color: themed('backgroundColor');
        }
    }

    .request-btn-wrap {
        .req-bind-btn-cancel {
            width: 151px;
            height: 45px;
            background: #FFFFFF;
            border-radius: 50px;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            text-transform: capitalize;
            margin: 10px;

            @include themify($themes) {
                border: 1px solid themed('backgroundColor');
                color: themed('backgroundColor');
            }
        }

        .req-bind-btn-continue {
            width: 151px;
            height: 45px;
            border-radius: 50px;
            font-weight: 700;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            text-transform: capitalize;
            margin: 10px;

            @include themify($themes) {
                background: themed('backgroundColor');
            }

        }
    }

    .prefill-information-form-wrapper {

        .form-title {
            font-size: 20px;
            font-weight: bold;
            text-align: left;
            font-family: "Manrope", sans-serif;
            letter-spacing: 0.04em;
        }

        .form-heading {
            font-size: 14px;
            text-align: left;
            margin: 0px;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            font-family: "Manrope", sans-serif;
            letter-spacing: 0.04em;
        }

        .prop-info-text-filled {
            width: 100%;
            margin-bottom: 30px;
            text-align: left;

            label {
                font-size: 16px;
                font-weight: bold;
                padding-bottom: 4px;
                font-family: "Manrope", sans-serif;
                letter-spacing: 0.04em;
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .form-label {
            float: left;
            font-size: 12px;
            font-weight: bold;
            padding-bottom: 4px;
            font-family: "Manrope", sans-serif;
            letter-spacing: 0.04em;
            color: rgba(0, 0, 0, 0.6);
        }

        .error-color {
            color: #f44336;
        }
    }
}

@media (max-width:575px) {
    .request-bind-wrap {
        .content {
            padding: 16px;
        }
    }
}
