@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';
.feedback-success-modal {
  .lbl-thank-u {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: #3a4b5e;
    margin: 15px 0px;
    font-family: 'Manrope', sans-serif;
  }
  .success-msg-lbl {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    color: #3a4b5e;
  }
  .feedback-check-icon{
    @include themify($themes) {
      background: themed('backgroundColor');
    }
    border-radius: 50px;
    border: 2px solid #000;
    font-size: 40px;
    color: #fff;
    padding: 3px;
  }
}
