@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.ams-button {
  background-color: $white;
  border-radius: 5px;
  @include themify($themes) {
    border-color: themed('backgroundColor');
  }
  border-style: solid;
  padding: 5px 0px;
  margin: 10px 20px 0px 20px;
  width: 85%;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.3);
  -moz-box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.3);
  box-shadow: 0px 2px 5px 0px rgba(50, 50, 50, 0.3);
}

.ams-div {
  padding: 10px;
  padding-top: 20px;
  display: flex;
  margin: 0px 17px;
  justify-content: center;
  .ams-button-text {
    cursor: pointer;
  }
}

/* .ams-button-text {
  width: 116px;
  height: 32px;
} */
