@import '../../../stylesheets/theme/theme-variables';
@import '../../../stylesheets/theme/theme.scss';

.radio-group-field-wrapper {
    .MuiFormControlLabel-root {
        .MuiIconButton-label {
            .MuiSvgIcon-root {
                @include themify($themes) {
                    color: themed('backgroundColor');
                }
            }
        }

    }
}
