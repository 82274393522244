@import '../theme/theme-variables';

/*
 * Theme definitions
 * 1. opendoor - hippo-green
 * 2. better - insureAlwaysTheme
 */
$themes: (
  hippo-green: (
    backgroundColor: $hippo-green,
    buttonBgColor: $hippo-green,
    buttonBgDarkColor: $hippo-green-dark,
    lightbg: $hippo-green,
    buttonHoverBorder: $hippo-green,
    selectTextcolor: $hippo-text-color,
    h1color: green,
    h2color: $hippo-green,
    iconcolor: $hippo-green,
    borderColor: 5px solid $white,
    lowestPriceBgColor: $hippo-lowerprice-bg,
    purchaseButtonBgColor: $hippo-purchase-btn-bg,
    lowestPriceBoxColor: $lowestprice-box-bg,
    otherPriceBoxColor: $otherprice-box-bg,
    purchaseByPhone: $hippo-purchase-by-phone-green,
    headerBorderColor: 1px solid $hippo-headerTable-borderColor,
    floodDotColor: $property-type-home-title,
    stepLabelColor: $black,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    content-box-color: $hippo-green-light,
    table-row-color: $hippo-table-row-color,
    car-Icon: $fc-blue-home,
    quote-bg-color: $hippo-green-deep-light,
    combination-theme-color: $hippo-black-color,
    vehicle-selection-border: $hippo-light-border,
    step-bg-color: $white,
    step-deactive-bg: $white,
    step-border-color: $hippostep-border,
    step-border-active: $black,
    form-text-color: $darkGrey,
    form-heading: $black,
    step-icon-color: $hippo-green,
    highlight-text-color: $hippo-green,
    border-step: $white,
    vehicle-selection-color: $darkGrey,
    rates-block-price-color: $darkGrey,
    icon-button-color: $hippo-green,
    step-border-bg: $white,
    step-border: $black,
    select-step-bg-color: $hippo-green,
    add-icon-border: $black,
    theme-color: $hippo-green,
    discounts-applied-text-color: $darkGrey,
    business-button-color: $hippo-black-color,
    tooltipBgColor:$hippoTooltip,
    tooltip-text-color:$black,
    member-border-bottom:$hippo-green-light,
  ),
  insureAlwaysTheme: (
    backgroundColor: $hippo-purple,
    buttonBgColor: $hippo-yellow,
    buttonBgDarkColor: $hippo-yellow-dark,
    lightbg: $hippo-drkpurple,
    buttonHoverBorder: $hippo-purple,
    selectTextcolor: $hippo-text-color,
    h1color: blue,
    h2color: $hippo-drkpurple,
    borderColor: 5px solid $hippo-yellow,
    lowestPriceBgColor: $hippo-purple,
    purchaseButtonBgColor: $hippo-purple,
    lowestPriceBoxColor: $hippo-purple,
    otherPriceBoxColor: $hippo-purple,
    purchaseByPhone: $hippo-purchase-by-phone-purple,
    headerBorderColor: 1px solid $hippo-yellow,
    floodDotColor: $property-type-home-title,
    stepLabelColor: $black,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    content-box-color: $hippo-purple,
    table-row-color: $hippo-table-row-color,
  ),
  firstAmericanTheme: (
    backgroundColor: $hippo-blue,
    buttonBgColor: $hippo-blue,
    buttonBgDarkColor: $hippo-blue-dark,
    buttonHoverBorder: $hippo-blue,
    lightbg: $hippo-blue,
    selectTextcolor: $hippo-text-color,
    h1color: blue,
    h2color: $hippo-blue,
    borderColor: 5px solid $white,
    lowestPriceBgColor: $hippo-lowerprice-blue-bg,
    purchaseButtonBgColor: $hippo-purchase-blue-btn-bg,
    lowestPriceBoxColor: $lowestprice-box-blue-bg,
    otherPriceBoxColor: $otherprice-box-blue-bg,
    purchaseByPhone: $hippo-purchase-by-phone-blue,
    headerBorderColor: 1px solid $hippo-headerTable-blue-borderColor,
    floodDotColor: $property-type-home-title,
    stepLabelColor: $black,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    content-box-color: $hippo-blue,
    table-row-color: $hippo-table-row-color,
    step-border-color: $hippostep-border,
    step-border-active: $black,
  ),
  sky-blue: (
    backgroundColor: $firstConnect-blue,
    buttonBgColor: $firstConnect-blue,
    stepLabelColor: $firstConnect-blue,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    buttonHoverBorder: $firstConnect-blue,
    selectTextcolor: $firstConnect-blue,
    content-box-color: $firstConnect-light,
    buttonBgDarkColor: $blue,
    lightbg: $firstConnect-blue,
    iconcolor: $firstConnect-blue,
    table-row-color: $firstConnect-light,
    quote-bg-color: $firstConnect-disable-step,
    h1color: blue,
    h2color: $hippo-blue,
    borderColor: 5px solid $white,
    lowestPriceBgColor: $hippo-lowerprice-blue-bg,
    purchaseButtonBgColor: $hippo-purchase-blue-btn-bg,
    lowestPriceBoxColor: $firstConnect-blue,
    otherPriceBoxColor: $firstConnect-blue,
    purchaseByPhone: $firstConnect-blue,
    headerBorderColor: 1px solid $hippo-headerTable-blue-borderColor,
    floodDotColor: $property-type-home-title,
    combination-theme-color: $firstConnect-blue,
    vehicle-selection-border: $light-border-color,
    step-bg-color: $firstConnect-blue,
    step-deactive-bg: $stepbg-fc-pennymac,
    step-border-color: $white,
    step-border-active: $firstConnect-blue,
    form-text-color: $darkGrey,
    form-heading: $black,
    step-icon-color: $white,
    highlight-text-color: $firstConnect-blue,
    border-step: $step-active-white-bg,
    vehicle-selection-color: $firstConnect-blue,
    rates-block-price-color: $darkGrey,
    icon-button-color: $white,
    step-border-bg: $firstConnect-blue,
    step-border: $firstConnect-blue,
    select-step-bg-color: $firstConnect-blue,
    add-icon-border: $firstConnect-blue,
    theme-color: $firstConnect-blue,
    discounts-applied-text-color: $darkGrey,
    business-button-color: $firstConnect-blue, 
    tooltipBgColor:$tootltip-color-light, 
    tooltip-text-color:$black,
    member-border-bottom:$blue-100,
  ),
  navy-blue: (
    backgroundColor: $pennymac-navyblue,
    buttonBgColor: $pennymac-navyblue,
    stepLabelColor: $pennymac-navyblue,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    buttonHoverBorder: $pennymac-navyblue,
    selectTextcolor: $pennymac-navyblue,
    content-box-color: $pennymac-navyblue-light,
    buttonBgDarkColor: $pennymac-button-focus,
    lightbg: $pennymac-navyblue,
    iconcolor: $pennymac-navyblue,
    table-row-color: $pennymac-navyblue-light,
    quote-bg-color: $firstConnect-disable-step,
    // h1color: blue,
    // h2color: $hippo-blue,
    borderColor: 5px solid $white,
    lowestPriceBgColor: $hippo-lowerprice-blue-bg,
    purchaseButtonBgColor: $hippo-purchase-blue-btn-bg,
    lowestPriceBoxColor: $pennymac-navyblue,
    otherPriceBoxColor: $pennymac-navyblue,
    purchaseByPhone: $pennymac-navyblue,
    combination-theme-color: $pennymac-navyblue,
    vehicle-selection-border: $light-sky-blue,
    step-bg-color: $pennymac-navyblue,
    step-deactive-bg: $stepbg-fc-pennymac,
    step-border-color: $white,
    step-border-active: $pennymac-border-step,
    form-text-color: $pennymac-navyblue,
    form-heading: $darkGrey,
    step-icon-color: $pennymac-navyblue,
    border-step: $step-active-white-bg,
    highlight-text-color: $pennymac-navyblue,
    vehicle-selection-color: $darkGrey,
    rates-block-price-color: $darkGrey,
    icon-button-color: $white,
    step-border-bg: $pennymac-navyblue,
    step-border: $pennymac-navyblue,
    select-step-bg-color: $pennymac-navyblue,
    add-icon-border: $pennymac-navyblue,
    theme-color: $pennymac-navyblue,
    discounts-applied-text-color: $darkGrey,
    business-button-color: $pennymac-navyblue,
    tooltipBgColor:$tootltip-color-light,
    tooltip-text-color:$pennymac-navyblue,
  ),
  purplish-blue: (
    backgroundColor: $doma-purplish-blue,
    buttonBgColor: $doma-purplish-blue,
    stepLabelColor: $doma-purplish-blue,
    StepLabelDisableColor: $firstConnect-disable-step-label,
    buttonHoverBorder: $doma-purplish-blue,
    selectTextcolor: $doma-purplish-blue,
    content-box-color: $doma-purplish-blue-light,
    buttonBgDarkColor: $pennymac-button-focus,
    lightbg: $doma-purplish-blue,
    iconcolor: $doma-purplish-blue,
    table-row-color: $doma-purplish-blue-light,
    quote-bg-color: $firstConnect-disable-step,
    borderColor: 5px solid $white,
    lowestPriceBgColor: $hippo-lowerprice-blue-bg,
    purchaseButtonBgColor: $hippo-purchase-blue-btn-bg,
    lowestPriceBoxColor: $doma-purplish-blue,
    otherPriceBoxColor: $doma-purplish-blue,
    purchaseByPhone: $doma-purplish-blue,
    combination-theme-color: $doma-purplish-blue,
    vehicle-selection-border: $light-sky-blue,
    step-bg-color: $doma-purplish-blue,
    step-deactive-bg: $stepbg-doma,
    step-border-color: $white,
    step-border-active: $pennymac-border-step,
    form-text-color: $doma-purplish-blue,
    form-heading: $darkGrey,
    step-icon-color: $doma-purplish-blue,
    border-step: $step-active-white-bg,
    highlight-text-color: $doma-purplish-blue,
    vehicle-selection-color: $darkGrey,
    rates-block-price-color: $darkGrey,
    icon-button-color: $white,
    step-border-bg: $doma-purplish-blue,
    step-border: $doma-purplish-blue,
    select-step-bg-color: $doma-purplish-blue,
    add-icon-border: $doma-purplish-blue,
    theme-color: $doma-purplish-blue,
    discounts-applied-text-color: $darkGrey,
    business-button-color: $doma-purplish-blue,
    tooltipBgColor:$tootltip-color-light,
    tooltip-text-color:$doma-purplish-blue,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
