@import '../../../stylesheets/theme/theme';
@import '../../../stylesheets/theme/theme-variables';

.lifeDetails-wrapper {
    .MuiTypography-colorTextSecondary {
        color: #3A4B5E;
    }
    .MuiRadio-colorSecondary.Mui-checked {
        @include themify($themes) {
            color: themed('backgroundColor');
        }

        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.38);
            cursor: default;
            pointer-events: unset;
        }
    }

    .mb-6 {
        margin-bottom: 3em;
    }

    .delete-icon {
        cursor: pointer;

        @include themify($themes) {
            color: themed('backgroundColor') !important;
        }
    }

    .radio-wrap {
        legend {
            font-size: 12px;
            text-align: left;
        }

        .life-radio {

            label {
                margin-left: 0px;
                padding: 0px;

                .MuiRadio-root {
                    padding: 0px;

                }

                .MuiIconButton-colorSecondary:hover {
                    background-color: transparent;
                    padding: 0px;
                }

                .MuiFormControlLabel-label {
                    margin-left: 10px;
                }
            }
        }
    }

    .MuiFormLabel-root.Mui-focused {
        color: rgba(0, 0, 0, 0.6);
    }

    .MuiFormControl-root {
        .MuiFormHelperText-root {
            color: #f44336;
            font-size: 12px;
            text-align: left;
        }
    }

    .red-star-20 {
        font-size: 20px;
        vertical-align: sub;
        color: #f44336;
    }

    .height-textfield {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        .ft-lbl {
            width: 40%;
            margin-right: 20px;
        }

        .in-lbl {
            width: 40%;
            margin-top: 16px;
        }
        .error {
            position: absolute;
            bottom: -18px;
            left: -10px;
        }
    }

    .wt-lbl {
        width: 100%;
    }
    .form-submit-action {
        margin-top: 3em;
    }
    .MuiFormLabel-root {
        overflow: inherit;
    }
    .houshold-field {
        display: flex;
        flex-direction: column;
        .lbl-household {
           text-align: left; 
        }
        .MuiInput-root {
            height: 27px;
        }
    }
}

@media (min-width: 320px) and (max-width: 991px) {
    .lifeDetails-wrapper {
        .radio-wrap {
            .life-radio {
                flex-wrap: inherit;
                label {
                    width: 50%;
                }
            }

            .radio-label {
                padding-left: 0px;
            }
        }
          /*  .height-textfield {
               .ft-lbl {
                   width: 100%;
                   margin-bottom: 2em;
               }
               .in-lbl {
                   width: 100%;
                   margin-bottom: 1em;
               }
           } */
    }
}

@media (max-width: 360px) {
    .lifeDetails-wrapper {
        .Add-another-btn {
            font-size: 14px;

            svg {
                display: none;
            }
        }
    }
}

@media (max-width: 576px) {
    .lifeDetails-wrapper {
        .position-sm {
            position: absolute;
        }
    }
}
